import React from "react";
import Color from "color";

import { isClient } from "@kikoff/utils/src/general";
import { clamp } from "@kikoff/utils/src/number";
import { pick, setAll } from "@kikoff/utils/src/object";

export { Color };
/**
 * @file Contains DOM related utility functions
 */

/**
 * Sets caret position in text input
 *
 * @function
 * @param {Element} element - Input element where the caret will be set
 * @param {Number} position - Position of caret
 * @param {Boolean} [condition] - Set caret position to [position] if this is true
 */
export const setCaret = (element, position, condition = true) => {
  if (document.activeElement === element) {
    if (!condition) return;
    if (element.setSelectionRange)
      element.setSelectionRange(position, position);
    else if (element.createTextRange) {
      const range = element.createTextRange();
      range.move("character", position);
      range.select();
    }
  }
};

export const getStyleConstant = (constant) =>
  getComputedStyle(document.documentElement).getPropertyValue(`--${constant}`);

export const isIOS =
  isClient &&
  navigator &&
  (/iPad|iPhone|iPod/.test(navigator.platform || "") ||
    // iPad on iOS 13 detection
    (/Mac/.test(navigator.userAgent || "") && "ontouchend" in document));

export const isAndroid =
  isClient && navigator.userAgent.toLowerCase().includes("android");

export const isFirefox =
  isClient && navigator.userAgent.toLowerCase().includes("firefox");

export const fitContent = isFirefox ? "-moz-fit-content" : "fit-content";

interface createDragHandlerPos {
  x: number;
  y: number;
  t: number;
  dx: number;
  dy: number;
  dt: number;
  ddx: number;
  ddy: number;
  ddt: number;
}

export interface createDragHandlerCallbackContext {
  e: TouchEvent | MouseEvent;
  pos: createDragHandlerPos;
  element: HTMLElement;
  type: "start" | "drag" | "drop";
}

type createDragHandlerCallback = (
  context: createDragHandlerCallbackContext
) => void;

export type createDragHandlerProps = {
  onStart?: createDragHandlerCallback;
  onDrag?: createDragHandlerCallback;
  onDrop?: createDragHandlerCallback;
  clamp?: { [key in "x" | "y"]?: { min?: number; max?: number } };
  touch?: boolean;
  mouse?: boolean;
  container?: HTMLElement | Document;
  allowDefault?: boolean;
} & (
  | {
      ref: React.MutableRefObject<HTMLElement>;
    }
  | { element: HTMLElement }
);

interface createDragHandlerRes {
  dragHandler: (e: TouchEvent | MouseEvent) => void;
  cleanup: () => void;
}

export function createDragHandler({
  onStart,
  onDrag: dragCallback,
  onDrop: dropCallback,
  clamp: _clamp = {},
  touch = true,
  mouse = true,
  container = document,
  allowDefault = false,
  ...props
}: createDragHandlerProps): createDragHandlerRes {
  const element = "element" in props ? props.element : props.ref.current;

  if (touch) element.addEventListener("touchstart", dragHandler);
  if (mouse) element.addEventListener("mousedown", dragHandler);

  return {
    dragHandler,
    cleanup: () => {
      if (touch) element.removeEventListener("touchstart", dragHandler);
      if (mouse) element.removeEventListener("mousedown", dragHandler);
    },
  };
  function dragHandler(e: any /* TouchEvent | MouseEvent */) {
    const touching = !!e.targetTouches;
    const point = touching ? e.targetTouches[0] : e;
    const pos: createDragHandlerPos = {
      ...setAll(["dx", "dy", "dt", "ddx", "ddy", "ddt"] as const, 0),
      x: point.clientX,
      y: point.clientY,
      t: e.timeStamp,
    };

    if (touch) {
      container.addEventListener("touchmove", onMove, {
        passive: false,
      });
      container.addEventListener("touchend", onDrop, {
        passive: false,
      });
    }
    if (mouse) {
      container.addEventListener("mousemove", onMove);
      container.addEventListener("mouseup", onDrop);
    }

    onStart?.({ pos, e, element, type: "start" });
    if (!allowDefault && !touching) {
      e.preventDefault();
      e.stopPropagation();
    }

    function onMove(moveEvent: any /* TouchEvent | MouseEvent */) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const touching = !!moveEvent.targetTouches;
      if (!allowDefault && !touching) {
        moveEvent.preventDefault();
        moveEvent.stopPropagation();
      }
      const { dx, dy, dt } = pos;

      const movePoint = touching ? moveEvent.targetTouches[0] : moveEvent;

      [pos.dx, pos.dy] = [
        clamp(movePoint.clientX - pos.x, _clamp.x),
        clamp(movePoint.clientY - pos.y, _clamp.y),
      ];
      pos.dt = moveEvent.timeStamp - pos.t;

      pos.ddt = pos.dt - dt;
      [pos.ddx, pos.ddy] = [(pos.dx - dx) / pos.ddt, (pos.dy - dy) / pos.ddt];
      dragCallback?.({ pos, e: moveEvent, element, type: "drag" });
    }

    function onDrop(dropEvent: any /* TouchEvent | MouseEvent */) {
      if (touch) {
        container.removeEventListener("touchmove", onMove);
        container.removeEventListener("touchend", onDrop);
      }
      container.removeEventListener("mousemove", onMove);
      container.removeEventListener("mouseup", onDrop);
      dropCallback?.({ pos, e: dropEvent, element, type: "drop" });
    }
  }
}

export const absoluteOffset = (
  el: HTMLElement,
  { fixed = false } = {}
): {
  top: number;
  bottom: number;
  left: number;
  right: number;
  height: number;
  width: number;
} => {
  if (
    !el ||
    // https://sentry.io/organizations/kikoff/issues/2736371798/?project=5063905&query=is%3Aunresolved&statsPeriod=14d
    !document.body
  )
    return setAll(
      ["top", "left", "height", "width", "right", "bottom"],
      0
    ) as ReturnType<typeof absoluteOffset>;
  const offset = {
    top: 0,
    left: 0,
    height: el.offsetHeight,
    width: el.offsetWidth,
  };
  if (fixed)
    Object.assign(offset, pick(el.getBoundingClientRect(), ["top", "left"]));
  else
    while (el) {
      offset.top += el.offsetTop || 0;
      offset.left += el.offsetLeft || 0;
      // eslint-disable-next-line no-param-reassign
      el = el.offsetParent as HTMLElement;
    }
  return Object.assign(offset, {
    right:
      Math.max(window.innerWidth, fixed ? 0 : document.body.clientWidth) -
      offset.left -
      offset.width,
    bottom:
      Math.max(window.innerHeight, fixed ? 0 : document.body.clientHeight) -
      offset.top -
      offset.height,
  });
};

export const getScript = (source: string) =>
  new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.defer = true;

    script.onload = (e) => {
      script.onload = null;
      resolve(e);
    };
    script.onerror = (e) => {
      script.onerror = null;
      reject(e);
    };

    script.src = source;
    document.head.appendChild(script);
  });

export function setNativeValue(element, value) {
  const valueSetter = Object.getOwnPropertyDescriptor(element, "value").set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    "value"
  ).set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
}

export function onRadioChange({
  selector = "input[type=radio]",
  container = document,
  handler,
}) {
  for (const input of container.querySelectorAll(selector)) {
    input.addEventListener("change", change);
  }
  return {
    cleanup: () => {
      for (const input of container.querySelectorAll(selector)) {
        input.removeEventListener("change", change);
      }
    },
  };
  function change(this: any, e) {
    handler.call(this, Object.assign(e, { value: e.target.value }));
  }
}

export const NativeMatrix =
  isClient && ("DOMMatrix" in window ? DOMMatrix : window.WebKitCSSMatrix);

if (isClient) Object.assign(window, { getScript });

export enum KeyCode {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  PauseBreak = 19,
  CapsLock = 20,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,

  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,

  Insert = 45,
  Delete = 46,

  Zero = 48,
  ClosedParen = Zero,
  One = 49,
  ExclamationMark = One,
  Two = 50,
  AtSign = Two,
  Three = 51,
  PoundSign = Three,
  Hash = PoundSign,
  Four = 52,
  DollarSign = Four,
  Five = 53,
  PercentSign = Five,
  Six = 54,
  Caret = Six,
  Hat = Caret,
  Seven = 55,
  Ampersand = Seven,
  Eight = 56,
  Star = Eight,
  Asterik = Star,
  Nine = 57,
  OpenParen = Nine,

  A = 65,
  B = 66,
  C = 67,
  D = 68,
  E = 69,
  F = 70,
  G = 71,
  H = 72,
  I = 73,
  J = 74,
  K = 75,
  L = 76,
  M = 77,
  N = 78,
  O = 79,
  P = 80,
  Q = 81,
  R = 82,
  S = 83,
  T = 84,
  U = 85,
  V = 86,
  W = 87,
  X = 88,
  Y = 89,
  Z = 90,

  LeftWindowKey = 91,
  RightWindowKey = 92,
  SelectKey = 93,

  Numpad0 = 96,
  Numpad1 = 97,
  Numpad2 = 98,
  Numpad3 = 99,
  Numpad4 = 100,
  Numpad5 = 101,
  Numpad6 = 102,
  Numpad7 = 103,
  Numpad8 = 104,
  Numpad9 = 105,

  Multiply = 106,
  Add = 107,
  Subtract = 109,
  DecimalPoint = 110,
  Divide = 111,

  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,

  NumLock = 144,
  ScrollLock = 145,

  SemiColon = 186,
  Equals = 187,
  Comma = 188,
  Dash = 189,
  Period = 190,
  UnderScore = Dash,
  PlusSign = Equals,
  ForwardSlash = 191,
  Tilde = 192,
  GraveAccent = Tilde,

  OpenBracket = 219,
  ClosedBracket = 221,
  Quote = 222,
}

export class Selector {
  constructor(
    public selector: string,
    public container: HTMLElement = document.documentElement
  ) {}

  get additionPromise() {
    return new Promise<HTMLElement>((resolve) => {
      new MutationObserver((_, self) => {
        const element = this.container.querySelector(
          this.selector
        ) as HTMLElement;
        if (element) {
          resolve(element);
          self.disconnect();
        }
      }).observe(this.container, {
        childList: true,
        subtree: true,
        attributes: true,
      });
    });
  }

  get removalPromise() {
    return new Promise<void>((resolve) => {
      new MutationObserver((_, self) => {
        const element = this.container.querySelector(
          this.selector
        ) as HTMLElement;
        if (!element) {
          resolve();
          self.disconnect();
        }
      }).observe(this.container, { childList: true, subtree: true });
    });
  }
}

export function getQueryParam(key: string) {
  return new URLSearchParams(window.location.search).get(key);
}

getQueryParam.presence = (key: string) =>
  typeof getQueryParam(key) === "string";

export function textColorForBackground(
  backgroundColorHex: string,
  lightColor = "white",
  darkColor = "black"
) {
  const color = backgroundColorHex.slice(1);

  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 200 ? darkColor : lightColor;
}

// We don't want to prevent banzai from loading again since it doesn't listen
// for new elements with Banzai tags, which means the script needs to execute
// again if the user visits another module
export function loadBanzai() {
  // prettier-ignore
  // @ts-expect-error
  // eslint-disable-next-line
  !function(e,t){var s="script",a=e.getElementsByTagName(s)[0],n=e.createElement(s);n.async=!0,n.src="https://teachbanzai.com/coach/styles.js?subdomain=kikoff",a.parentNode.insertBefore(n,a)}(document);
}

export const nextRender = (callback?: () => void) =>
  new Promise<void>((resolve) =>
    requestAnimationFrame(() =>
      requestAnimationFrame(() => {
        resolve();
        callback?.();
      })
    )
  );

export const getScrollParents = (element: HTMLElement) => {
  const parents: HTMLElement[] = [];

  (function getParents(el) {
    if (!el) return;

    if (el.scrollHeight > el.clientHeight) parents.push(el);

    getParents(el.parentElement);
  })(element);

  return parents;
};

export const awaitElement = (
  getElementOrSelector: (() => HTMLElement) | string,
  {
    container = document,
    timeout = -1,
  }: { container?: ParentNode; timeout?: number } = {}
) =>
  new Promise<HTMLElement>((resolve, reject) => {
    const getElement = () =>
      typeof getElementOrSelector === "function"
        ? getElementOrSelector()
        : (container.querySelector(getElementOrSelector) as HTMLElement);

    const initialResult = getElement();
    if (initialResult) return resolve(initialResult);

    const t =
      timeout >= 0
        ? setTimeout(() => {
            cleanup();
            reject(new Error("awaitElement timed out"));
          }, timeout)
        : null;

    const observer = new MutationObserver(() => {
      const result = getElement();

      if (result) {
        cleanup();
        resolve(result);
      }
    });

    observer.observe(container, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    function cleanup() {
      clearTimeout(t);
      observer.disconnect();
    }
  });

export function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}
