import React from "react";

import { web } from "@kikoff/proto/src/protos";

import facebookLogo from "@asset/icons/facebook.svg";
import twitterLogo from "@asset/icons/twitter.svg";
import { useBackendExperiment } from "@src/experiments/context";
import { getLocaleCookie } from "@util/l10n";

const kikoffCreditAccountMentalModelName = {
  control: "Kikoff Credit Account",
  variant_1: "Kikoff Store Card",
  variant_2: "Kikoff Credit Builder",
  variant_3: "Kikoff Credit",
};

const kikoffCreditAccountMentalModelNameEs = {
  control: "Cuenta de Crédito Kikoff",
  variant_1: "Tarjeta Tienda Kikoff",
  variant_2: "Creador de Crédito Kikoff",
  variant_3: "Crédito Kikoff",
};

export function getKikoffCreditAccountMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return kikoffCreditAccountMentalModelNameEs[mentalModelVariant];
  }

  return kikoffCreditAccountMentalModelName[mentalModelVariant];
}

const kikoffStoreMentalModelName = {
  control: "Kikoff Store",
  variant_1: "Kikoff Store",
  variant_2: "Learning Center",
  variant_3: "Learning Center",
};

const kikoffStoreMentalModelNameEs = {
  control: "Tienda Kikoff",
  variant_1: "Tienda Kikoff",
  variant_2: "Centro de Aprendizaje",
  variant_3: "Centro de Aprendizaje",
};

export function getKikoffStoreMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return kikoffStoreMentalModelNameEs[mentalModelVariant];
  }

  return kikoffStoreMentalModelName[mentalModelVariant];
}

const kikoffStoreSentenceCaseMentalModelName = {
  control: "Kikoff store",
  variant_1: "Kikoff store",
  variant_2: "Learning Center",
  variant_3: "Learning Center",
};

const kikoffStoreSentenceCaseMentalModelNameEs = {
  control: "tienda Kikoff",
  variant_1: "tienda Kikoff",
  variant_2: "Centro de Aprendizaje",
  variant_3: "Centro de Aprendizaje",
};

export function getKikoffStoreSentenceCaseMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return kikoffStoreSentenceCaseMentalModelNameEs[mentalModelVariant];
  }

  return kikoffStoreSentenceCaseMentalModelName[mentalModelVariant];
}

const creditAccountMentalModelName = {
  control: "Credit Account",
  variant_1: "Store Card",
  variant_2: "Credit Builder",
  variant_3: "Kikoff Credit",
};

const creditAccountMentalModelNameEs = {
  control: "Cuenta de Crédito",
  variant_1: "Tarjeta de Compras",
  variant_2: "Creador de Crédito",
  variant_3: "Crédito Kikoff",
};

export function getCreditAccountMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return creditAccountMentalModelNameEs[mentalModelVariant];
  }

  return creditAccountMentalModelName[mentalModelVariant];
}

const creditAccountLowercaseMentalModelName = {
  control: "credit account",
  variant_1: "Store Card",
  variant_2: "Credit Builder",
  variant_3: "Kikoff credit",
};

const creditAccountLowercaseMentalModelNameEs = {
  control: "cuenta de crédito",
  variant_1: "Tarjeta de Compras",
  variant_2: "Creador de Crédito",
  variant_3: "crédito Kikoff",
};

export function getCreditAccountLowercaseMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return creditAccountLowercaseMentalModelNameEs[mentalModelVariant];
  }

  return creditAccountLowercaseMentalModelName[mentalModelVariant];
}

const creditServicePremiumLongMentalModelName = {
  control: "Credit Service Premium",
  variant_1: "Premium",
  variant_2: "Premium",
  variant_3: "Premium",
};

const creditServicePremiumLongMentalModelNameEs = {
  control: "Credit Service Premium",
  variant_1: "Premium",
  variant_2: "Premium",
  variant_3: "Premium",
};

export function getCreditServicePremiumLongMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return creditServicePremiumLongMentalModelNameEs[mentalModelVariant];
  }

  return creditServicePremiumLongMentalModelName[mentalModelVariant];
}

const creditServiceBasicLongMentalModelName = {
  control: "Credit Service Basic",
  variant_1: "Basic",
  variant_2: "Basic",
  variant_3: "Basic",
};

const creditServiceBasicLongMentalModelNameEs = {
  control: "Credit Service Basic",
  variant_1: "Basic",
  variant_2: "Basic",
  variant_3: "Basic",
};

export function getCreditServiceBasicLongMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return creditServiceBasicLongMentalModelNameEs[mentalModelVariant];
  }

  return creditServiceBasicLongMentalModelName[mentalModelVariant];
}

const storeLowercaseMentalModelName = {
  control: "store",
  variant_1: "store",
  variant_2: "Learning Center",
  variant_3: "Learning Center",
};

const storeLowercaseMentalModelNameEs = {
  control: "tienda",
  variant_1: "tienda",
  variant_2: "Centro de Aprendizaje",
  variant_3: "Centro de Aprendizaje",
};

export function getStoreLowercaseMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return storeLowercaseMentalModelNameEs[mentalModelVariant];
  }

  return storeLowercaseMentalModelName[mentalModelVariant];
}

const kikoffCreditServiceGenericMentalModelName = {
  control: "Kikoff Credit Service",
  variant_1: "Kikoff Basic",
  variant_2: "Kikoff Basic",
  variant_3: "Kikoff Basic",
};

const kikoffCreditServiceGenericMentalModelNameEs = {
  control: "Servicio de Credito Kikoff",
  variant_1: "Kikoff Basic",
  variant_2: "Kikoff Basic",
  variant_3: "Kikoff Basic",
};

export function getKikoffCreditServiceGenericMentalModelName(
  isPremium: boolean
) {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (isPremium) {
    if (locale === "es") {
      return kikoffCreditServicePremiumMentalModelNameEs[mentalModelVariant];
    }

    return kikoffCreditServicePremiumMentalModelName[mentalModelVariant];
  }

  if (locale === "es") {
    return kikoffCreditServiceGenericMentalModelNameEs[mentalModelVariant];
  }

  return kikoffCreditServiceGenericMentalModelName[mentalModelVariant];
}

const creditServiceGenericMentalModelName = {
  control: "Credit Service",
  variant_1: "Basic",
  variant_2: "Basic",
  variant_3: "Basic",
};

const creditServiceGenericMentalModelNameEs = {
  control: "Servicio de Credito",
  variant_1: "Basic",
  variant_2: "Basic",
  variant_3: "Basic",
};

export function getCreditServiceGenericMentalModelName(isPremium: boolean) {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (isPremium) {
    if (locale === "es") {
      return creditServicePremiumMentalModelNameEs[mentalModelVariant];
    }

    return creditServicePremiumMentalModelName[mentalModelVariant];
  }

  if (locale === "es") {
    return creditServiceGenericMentalModelNameEs[mentalModelVariant];
  }

  return creditServiceGenericMentalModelName[mentalModelVariant];
}

const kikoffCreditServicePremiumMentalModelName = {
  control: "Kikoff Credit Service",
  variant_1: "Kikoff Premium",
  variant_2: "Kikoff Premium",
  variant_3: "Kikoff Premium",
};

const kikoffCreditServicePremiumMentalModelNameEs = {
  control: "Servicio de Credito Kikoff",
  variant_1: "Kikoff Premium",
  variant_2: "Kikoff Premium",
  variant_3: "Kikoff Premium",
};

export function getKikoffCreditServicePremiumMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return kikoffCreditServicePremiumMentalModelNameEs[mentalModelVariant];
  }

  return kikoffCreditServicePremiumMentalModelName[mentalModelVariant];
}

const creditServicePremiumMentalModelName = {
  control: "Credit Service",
  variant_1: "Premium",
  variant_2: "Premium",
  variant_3: "Premium",
};

const creditServicePremiumMentalModelNameEs = {
  control: "Servicio de Credito",
  variant_1: "Premium",
  variant_2: "Premium",
  variant_3: "Premium",
};

export function getCreditServicePremiumMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return creditServicePremiumMentalModelNameEs[mentalModelVariant];
  }

  return creditServicePremiumMentalModelName[mentalModelVariant];
}

const kikoffCreditServiceGenericBothMentalModelName = {
  control: "Kikoff Credit Service",
  variant_1: "Kikoff Basic and Kikoff Premium",
  variant_2: "Kikoff Basic and Kikoff Premium",
  variant_3: "Kikoff Basic and Kikoff Premium",
};

const kikoffCreditServiceGenericBothMentalModelNameEs = {
  control: "Servicio de Credito Kikoff",
  variant_1: "Kikoff Basic y Kikoff Premium",
  variant_2: "Kikoff Basic y Kikoff Premium",
  variant_3: "Kikoff Basic y Kikoff Premium",
};

export function getKikoffCreditServiceGenericBothMentalModelName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return kikoffCreditServiceGenericBothMentalModelNameEs[mentalModelVariant];
  }

  return kikoffCreditServiceGenericBothMentalModelName[mentalModelVariant];
}

const creditServiceMembership = {
  control: "Credit Service Membership",
  variant_1: "Basic",
  variant_2: "Basic",
  variant_3: "Basic",
};

const creditServiceMembershipEs = {
  control: "Credit Service Membership",
  variant_1: "Basic",
  variant_2: "Basic",
  variant_3: "Basic",
};

export function getCreditServiceMembershipName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return creditServiceMembershipEs[mentalModelVariant];
  }

  return creditServiceMembership[mentalModelVariant];
}

const creditBuildingServiceBasic = {
  control: "Credit Building Service",
  variant_1: "Basic",
  variant_2: "Basic",
  variant_3: "Basic",
};

const creditBuildingServiceBasicEs = {
  control: "Credit Building Service",
  variant_1: "Basic",
  variant_2: "Basic",
  variant_3: "Basic",
};

export function getCreditBuildingServiceBasicName() {
  const locale = getLocaleCookie();
  const mentalModelVariant = useBackendExperiment("mentalModel");
  if (locale === "es") {
    return creditBuildingServiceBasicEs[mentalModelVariant];
  }

  return creditBuildingServiceBasic[mentalModelVariant];
}

export const productData = {
  [web.public_.Product.Type.BOOK]: {
    label: "E-BOOK",
    deliveryInstructions: ({ email }) => `Product will be emailed to ${email}`,
  },
  [web.public_.Product.Type.SUBSCRIPTION]: {
    label: "SERVICE PLAN",
    deliveryInstructions: ({ email }) =>
      `Details regarding your service plan will be emailed to ${email}`,
  },
  [web.public_.Product.Type.COURSE]: {
    label: "VIDEO COURSE",
    deliveryInstructions: ({ email }) => `Product will be emailed to ${email}`,
  },
  [web.public_.Product.Type.BANZAI_GOAL_PACKAGE]: {
    label: "#GOALS PACK",
    deliveryInstructions: ({ email }) => "",
  },
  [web.public_.Product.Type.BOOKS_BUNDLE]: {
    label: "BUNDLE",
    deliveryInstructions: ({ email }) => `Product will be emailed to ${email}`,
  },
};

const { LoanType } = web.public_.Loan;

export const loansInfo = {
  [LoanType.MICRO]: {
    title: "Kikoff Loan",
  },
  [LoanType.BOTTLE]: {
    title: "Bottle Loan",
  },
  [LoanType.CREDIT_BUILDER_SAVING]: {
    title: "Credit Builder Loan",
  },
  [LoanType.PREMIUM]: {
    title: "Credit Builder Loan",
  },
  [LoanType.UNSECURED_PERSONAL]: {
    title: "Personal Loan",
  },
};

export const cblName = loansInfo[LoanType.CREDIT_BUILDER_SAVING].title;

export const states = {
  AK: "Alaska",
  AL: "Alabama",
  AR: "Arkansas",
  AS: "American Samoa",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VI: "Virgin Islands",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming",
};

export const logos = {
  facebook: facebookLogo,
  twitter: twitterLogo,
};

export const persona = {
  personaVerify: "https://withpersona.com/verify",
  personaVerifyTemplateId: "tmpl_W3LuvDMUnBq69fJ19M6xPHK7",
};

export const makeDisputesFaqs = () => [
  {
    title: "Here’s a quick way to address your concerns",
    body: (
      <>
        <ol>
          <li>Review details of the transaction in question.</li>
          <li>
            Contact the merchant to resolve your issue.
            <ol type="a">
              <li>
                Merchants can often resolve issues much faster than banks. If
                you speak with a merchant, it is a good idea to keep track of
                the contact date, who you speak with, and their response to your
                inquiry. In case you open a dispute, we may ask for all this
                information later.
              </li>
            </ol>
          </li>
          <li>
            If they cannot help you, we can look into it.
            <ol type="a">
              <li>Call us at 866-331-1256.</li>
            </ol>
          </li>
        </ol>
      </>
    ),
  },
  {
    title: "Charge errors vs fraudulent activity",
    body: (
      <>
        <div>
          <strong>Charge errors</strong>
          <p>
            These are charges that you recognize but appear to be incorrect:
            <dl>
              <dd>
                - A recurring transaction or subscription that was previously
                canceled
              </dd>
              <dd>
                - A product or service you never received or received late
              </dd>
              <dd>
                - A one-time purchase that was canceled, and you still haven’t
                gotten credit
              </dd>
              <dd>
                - A charge for a higher amount than reflected on the receipt
              </dd>
              <dd>- A product or service that you are not satisfied with</dd>
              <dd>
                - A charge appearing on your credit card even though you used
                cash or a different payment method
              </dd>
              <dd>- Duplicate charges for the same transaction</dd>
            </dl>
          </p>
        </div>
        <br />
        <div>
          <strong>Fraudulent Activity</strong>
          <p>
            These are charges that were not made by you or anyone you authorized
            to spend on the account. You have confirmed the following:
            <dl>
              <dd>
                - The charge was not made by you or anyone authorized to use the
                account
              </dd>
              <dd>- You have not let any third party user your card</dd>
              <dd>
                - A one-time purchase that was canceled, and you still haven’t
                gotten credit
              </dd>
              <dd>
                - A charge for a higher amount than reflected on the receipt
              </dd>
              <dd>- A product or service that you are not satisfied with</dd>
              <dd>
                - A charge appearing on your credit card even though you used
                cash or a different payment method
              </dd>
              <dd>- Duplicate charges for the same transaction</dd>
            </dl>
            <p>
              If your card is lost or stolen, or you think your account is in
              any way compromised, please call us immediately at 866-331-1256.
            </p>
          </p>
        </div>
      </>
    ),
  },
];
