import { web } from "../../../protos";

const { Status, Reason, Category } = web.public_.CreditDisputeItem;

export default function createCreditDisputeItem(
  itemId: string,
  status: keyof typeof Status,
  category: keyof typeof Category,
  {
    description = "Disputed item description",
    details = [
      { label: "Account Number", value: itemId },
      { label: "Detail", value: "Value" },
    ],
    title = "Credit Dispute Item Title",
    value = "$500",
    selectedReason = status === "READY_TO_DISPUTE"
      ? Reason.UNKNOWN
      : reasonsByCategory[Category[category]][0],
    possibleReasons = status === "READY_TO_DISPUTE"
      ? reasonsByCategory[Category[category]]
      : null,
  }: Omit<web.public_.ICreditDisputeItem, "itemId" | "status"> = {}
) {
  return web.public_.CreditDisputeItem.create({
    itemId,
    category: Category[category],
    description,
    details,
    status: Status[status],
    title,
    value,
    selectedReason,
    possibleReasons,
  });
}

export const disputeReasonData = {
  [Category.COLLECTION]: {
    [Reason.DO_NOT_RECOGNIZE]: {
      text: "I don't recognize this account",
      explanation: "I don’t recognize this lender and account.",
    },
    [Reason.IDENTITY_THEFT]: {
      text: "Victim of identity theft",
      explanation:
        "I am a victim of identity theft and I never opened this account.",
    },
    [Reason.INACCURATE_INFORMATION]: {
      text: "Account information is inaccurate",
      explanation:
        "The account information is being reported inaccurately on my report.",
    },
    [Reason.UNVERIFIED_ACCOUNT]: {
      text: "Unverified account",
      explanation:
        "I never had a contract with this debt collector for any debt.",
    },
    [Reason.INCORRECT_DATE]: {
      text: "The dates on this account are incorrect",
      explanation:
        "The dates listed for this account on my credit report are wrong.",
    },
    [Reason.BALANCE_ERROR]: {
      text: "Account balance error",
      explanation:
        "This account is incorrectly being reported as a collection account with a balance due.",
    },
    [Reason.CUSTOM]: {
      text: "Custom reason",
      explanation: "",
    },
  },
  [Category.CHARGE_OFF]: {
    [Reason.DO_NOT_RECOGNIZE]: {
      text: "I don't recognize this account",
      explanation: "I don’t recognize this lender and account.",
    },
    [Reason.IDENTITY_THEFT]: {
      text: "Victim of identity theft",
      explanation:
        "I am a victim of identity theft and I never opened this account.",
    },
    [Reason.INACCURATE_INFORMATION]: {
      text: "Account information is inaccurate",
      explanation:
        "The account information is being reported inaccurately on my report.",
    },
    [Reason.CUSTOM]: {
      text: "Custom reason",
      explanation: "",
    },
  },
  [Category.INQUIRY]: {
    [Reason.DO_NOT_RECOGNIZE]: {
      text: "I don't recognize this inquiry",
      explanation:
        "I don’t recognize this lender and I don’t remember authorizing them to perform a hard inquiry on my credit report.",
    },
    [Reason.IDENTITY_THEFT]: {
      text: "Victim of identity theft",
      explanation:
        "I am a victim of identity theft and I never authorized this inquiry.",
    },
    [Reason.CUSTOM]: {
      text: "Custom reason",
      explanation: "",
    },
  },
  [Category.LATE_PAYMENT]: {
    [Reason.DO_NOT_REMEMBER]: {
      text: "Don't remember being late",
      explanation:
        "I don’t remember ever being late with a payment on this account.",
    },
    [Reason.CUSTOM]: {
      text: "Custom reason",
      explanation: "",
    },
  },
};

const reasonsByCategory = Object.fromEntries(
  Object.entries(disputeReasonData).map(([key, value]) => [
    key,
    Object.keys(value).map((key) => +key),
  ])
);
