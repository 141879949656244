import { useMemo } from "react";
import { useSelector } from "react-redux";
import { FluentResource } from "@fluent/bundle";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfirmCardPaymentData, Stripe } from "@stripe/stripe-js";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { deepEqual } from "@kikoff/utils/src/object";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { AppThunk } from "@store";
import analytics from "@util/analytics";
import { newReactLocalization } from "@util/l10n";
import { nativeDispatch } from "@util/mobile";

import { RootState } from "../store";
import { createLoadableSelector, thunk } from "../utils";

import { initUser } from "./user";

const RESOURCES = {
  en: new FluentResource(`initiating-payment-method = Initiating payment method...
adding-payment-method = Adding payment method...
confirming-payment-method = Confirming payment method...
failed-to-add-payment-method = Failed to add payment method
failed-to-add-payment-method-and-confirm-setup = Failed to add payment method, failed to confirm setup
`),
  es: new FluentResource(`initiating-payment-method = Iniciando método de pago...
adding-payment-method = Agregando método de pago...
confirming-payment-method = Confirmando forma de pago...
failed-to-add-payment-method = No se pudo agregar el método de pago
failed-to-add-payment-method-and-confirm-setup = No se pudo agregar el método de pago, no se pudo confirmar la configuración
`),
};

type IPaymentMethod = web.public_.IPaymentMethod;

const initialState = {
  wallet: null as web.public_.IWallet,
  paymentMethods: null as IPaymentMethod[],
  canLinkBankAccount: false,
};

export type FundsState = typeof initialState;

const fundsSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    initWallet(state, { payload }: PayloadAction<FundsState["wallet"]>) {
      state.wallet = payload;
    },
    setPaymentMethods(
      state,
      { payload }: PayloadAction<FundsState["paymentMethods"]>
    ) {
      state.paymentMethods = payload;
    },
    setCanLinkBankAccount(
      state,
      { payload }: PayloadAction<FundsState["canLinkBankAccount"]>
    ) {
      state.canLinkBankAccount = payload;
    },
  },
});

const { actions } = fundsSlice;
export const { initWallet } = actions;
export default fundsSlice.reducer;

export enum paymentTypeMap {
  card = web.public_.PaymentType.CARD,
  bank = web.public_.PaymentType.ACH_BANK_ACCOUNT,
  wallet = web.public_.PaymentType.WALLET_PAYMENT_TYPE,
  cashappStripe = web.public_.PaymentType.CASHAPP_STRIPE,
}

export type PaymentMethodType = keyof typeof paymentTypeMap;

export const selectPaymentMethods = () => (state: RootState) =>
  state.funds.paymentMethods;

export const selectPaymentMethodBy = <Prop extends keyof IPaymentMethod>(
  field: Prop,
  match: IPaymentMethod[Prop]
) => (state: RootState) =>
  selectPaymentMethods()(state)?.find(
    (paymentMethod) => paymentMethod[field] === match
  );

export const selectHasPaymentMethod = Object.assign(
  createLoadableSelector(
    (type?: web.public_.PaymentType | keyof typeof web.public_.PaymentType) => (
      state: RootState
    ) =>
      type
        ? !!selectPaymentMethodBy(
            "type",
            typeof type === "string" ? web.public_.PaymentType[type] : type
          )(state)
        : selectPaymentMethods()(state)?.length > 0,
    {
      selectLoaded: () => (state) => state.funds.paymentMethods,
      loadAction: () => fetchPaymentMethods(),
    }
  ),
  {
    retain: (() => {
      const withRefresh = (refreshKey: any) => (
        type?: web.public_.PaymentType
      ) => (state: RootState) => {
        const paymentMethodsLoaded = !!useSelector(selectPaymentMethods());

        return useMemo(() => selectHasPaymentMethod(type)(state), [
          type,
          paymentMethodsLoaded,
          refreshKey,
        ]);
      };

      return Object.assign(withRefresh(null), {
        withRefresh,
      });
    })(),
  }
);

export const selectPaymentMethodsBy = <Prop extends keyof IPaymentMethod>(
  field: Prop,
  match: IPaymentMethod[Prop]
) => (state: RootState) =>
  selectPaymentMethods()(state)?.filter(
    (paymentMethod) => paymentMethod[field] === match
  );

export const selectHasVerifiedBank = () => (state: RootState) =>
  selectPaymentMethods()(state)?.some(({ externalBankAccount }) =>
    [
      web.public_.ExternalBankAccount.Status.VERIFIED,
      web.public_.ExternalBankAccount.Status.MANUALLY_VERIFIED,
    ].includes(externalBankAccount?.status)
  );

export const selectPaymentMethodTypeGroups = (() => {
  type Groups = {
    [Key in web.public_.PaymentType]: web.public_.IPaymentMethod[];
  };

  const emptyGroups = Object.fromEntries(
    Object.values(web.public_.PaymentType).map((type) => [type, []])
  ) as Groups;
  const groupsMemo = {
    ref: null as web.public_.IPaymentMethod[],
    groups: emptyGroups,
    lastOptions: null as Options,
  };

  interface Options {
    excludeTokens?: string[];
  }

  return (options: Options = {}) => {
    const { excludeTokens } = options;
    return (state: RootState) => {
      const paymentMethods = selectPaymentMethods()(state);
      if (
        paymentMethods !== groupsMemo.ref ||
        deepEqual(options, groupsMemo.lastOptions)
      ) {
        groupsMemo.lastOptions = options;
        groupsMemo.groups = {
          ...emptyGroups,
          ...paymentMethods
            ?.filter(({ token }) => !excludeTokens?.includes(token))
            .groupBy(({ type }) => type),
        };
        groupsMemo.ref = paymentMethods;
      }
      return groupsMemo.groups;
    };
  };
})();

export const initDemoFunds = (data): AppThunk => (dispatch) => {
  dispatch(actions.setPaymentMethods(data.paymentMethods));
};

export const selectUpfrontPaymentWallet = () => (state: RootState) => {
  if (state.funds.wallet?.upfrontPayment) {
    return state.funds.wallet;
  }
};

export const fetchPaymentMethods = Object.assign(
  () =>
    thunk((dispatch) => {
      return webRPC.PaymentMethods.getPaymentMethods({}).then<
        web.public_.IPaymentMethod[]
      >(
        handleProtoStatus({
          SUCCESS(data) {
            dispatch(actions.setPaymentMethods(data.paymentMethods));
            dispatch(actions.setCanLinkBankAccount(data.canLinkBankAccount));
            return data.paymentMethods;
          },
          _DEFAULT: handleFailedStatus(
            "Failed to get payment method(s), please reload"
          ),
        })
      );
    }),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const { paymentMethods } = getState().funds;

        return paymentMethods
          ? Promise.resolve(paymentMethods)
          : dispatch(fetchPaymentMethods());
      }),
  }
);

const addPaymentMethodClientSecretCache: Record<
  string,
  | ReturnType<Stripe["confirmCardSetup"]>
  | ReturnType<Stripe["confirmCashappSetup"]>
> = {};

export const addPaymentMethod = ({
  paymentMethod,
  onUpdate,
  stripe,
  noRefresh = false,
  cashapp = false,
}: {
  stripe: Stripe;
  paymentMethod: ConfirmCardPaymentData["payment_method"];
  onUpdate?: (text: string) => void;
  noRefresh?: boolean;
  cashapp?: boolean;
}): AppThunk<Promise<string>> => async (dispatch) => {
  let clientSecret: string;
  const l10n = newReactLocalization(RESOURCES);

  onUpdate(l10n.getString("initiating-payment-method"));
  await webRPC.PaymentMethods.addPaymentMethod({}).then(
    handleProtoStatus({
      SUCCESS(data) {
        clientSecret = data.setupIntent.clientSecret;
        analytics.convert("Add Payment Method");
      },
      _DEFAULT: handleFailedStatus(
        l10n.getString("failed-to-add-payment-method")
      ),
    })
  );

  onUpdate(l10n.getString("adding-payment-method"));
  const { setupIntent, error } = await (addPaymentMethodClientSecretCache[
    clientSecret
  ] ||= cashapp
    ? stripe.confirmCashappSetup(clientSecret, {
        payment_method: {},
        return_url: window.location.href,
      })
    : stripe.confirmCardSetup(clientSecret, {
        payment_method: paymentMethod,
      }));

  if (error) throw error;
  if (setupIntent?.status !== "succeeded")
    throw Object.assign(
      new Error(`Stripe setup failed with status: ${setupIntent.status}`),
      { ignore: cashapp && setupIntent?.status === "requires_action" }
    );

  onUpdate(l10n.getString("confirming-payment-method"));

  return webRPC.PaymentMethods.triggerPostAddPaymentMethodHook({
    setupIntentId: setupIntent.id,
  }).then(
    handleProtoStatus({
      async SUCCESS({ paymentMethodToken }) {
        if (!noRefresh) await dispatch(fetchPaymentMethods());

        return paymentMethodToken;
      },
      _DEFAULT: handleFailedStatus(
        l10n.getString("failed-to-add-payment-method-and-confirm-setup")
      ),
    })
  );
};

export const outgoingTranferDurationByPaymentType: Record<
  keyof typeof web.public_.WithdrawVoucherFundsRequest.FundableResourceType,
  string
> = {
  CASH_CARD: "Available instantly",
  EXTERNAL_BANK_ACCOUNT: "Available in 3-5 days",
  PAPER_CHECK: "Mailed to your address within 14 days",
};

export const removePaymentMethod = (token: string) => (dispatch) =>
  webRPC.PaymentMethods.deactivatePaymentMethod({
    token,
  }).then(
    handleProtoStatus({
      SUCCESS() {
        dispatch(fetchPaymentMethods());
        // Update todos
        dispatch(initUser());
        nativeDispatch("invalidate");
      },
      _DEFAULT: handleFailedStatus("Failed to remove payment method"),
    })
  );

export type ExternalBankAccount = web.public_.IExternalBankAccount;

export const ExternalBankAccount = {
  isVerified(eba: ExternalBankAccount | undefined) {
    if (!eba) return false;

    const { Status } = web.public_.ExternalBankAccount;
    return (
      eba.status === Status.VERIFIED || eba.status === Status.MANUALLY_VERIFIED
    );
  },
  isUnverified(eba: ExternalBankAccount | undefined) {
    if (!eba) return false;
    return !ExternalBankAccount.isVerified(eba);
  },
};
