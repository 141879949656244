export const FREE_BASIC_DISCOUNTED_PREMIUM = "free_basic_discounted_premium";

export const FIRST_MONTH_DISCOUNT_PROMO =
  "dropoff_incentives_first_month_half_off";
export const FIRST_MONTH_DISCOUNT_PROMO_PREMIUM =
  "dropoff_incentives_first_month_half_off_premium";

export const LAST_MONTH_FREE_PROMO = "last_month_free";
export const LAST_MONTH_FREE_PROMO_PREMIUM = "last_month_free_premium";

export const INTRO_TEST_OFFER = "intro_test_offer";
export const INTRO_TEST_OFFER_PREMIUM = "intro_test_offer_premium";

export const CANCELATION_ONE_MONTH_OFFER = "cancelation_one_month_offer";
export const CANCELATION_ONE_MONTH_OFFER_PREMIUM =
  "cancelation_one_month_offer_premium";

export const SUBSCRIPTION_RENEWAL = "subscription_renewal_v1";

export type PromoName =
  | typeof FREE_BASIC_DISCOUNTED_PREMIUM
  | typeof SUBSCRIPTION_RENEWAL
  | typeof FIRST_MONTH_DISCOUNT_PROMO
  | typeof FIRST_MONTH_DISCOUNT_PROMO_PREMIUM
  | typeof LAST_MONTH_FREE_PROMO
  | typeof LAST_MONTH_FREE_PROMO_PREMIUM
  | typeof INTRO_TEST_OFFER
  | typeof INTRO_TEST_OFFER_PREMIUM
  | typeof CANCELATION_ONE_MONTH_OFFER
  | typeof CANCELATION_ONE_MONTH_OFFER_PREMIUM;

export type IntroPromoName = typeof FREE_BASIC_DISCOUNTED_PREMIUM;

export const INFLUENCER_UTM_CAMPAIGN = "1stcollab";
