import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { requestLogger } from "axios-logger";

import { camelToKebab } from "./string";

export const http = axios.create({
  baseURL: process.env.API_ORIGIN,
  withCredentials: true,
});
http.interceptors.request.use(requestLogger);

export type RequestOptions = AxiosRequestConfig & {
  transformUrl?(url: string): string;
};
export type Response<T> = AxiosResponse<T>;

export type Get<Req, Res> = {
  GET: (req?: Req, options?: RequestOptions) => Promise<Response<Res>>;
};
export type Delete<Req = {}, Res = {}> = {
  DELETE: (req?: Req, options?: RequestOptions) => Promise<Response<Res>>;
};
export type Put<Req, Res = {}> = {
  PUT: (req: Req, options?: RequestOptions) => Promise<Response<Res>>;
};
export type Post<Req, Res = {}> = {
  POST: (req: Req, options?: RequestOptions) => Promise<Response<Res>>;
};

const methods = ["GET", "POST", "PUT", "DELETE"] as const;

export const createApi = <Api extends Record<string, any>>(
  baseUrl: string
): Api =>
  new Proxy(
    {},
    {
      get(_, property: typeof methods[number]) {
        if (["GET", "DELETE"].includes(property))
          return (params, { transformUrl = (s) => s, ...config } = {}) =>
            http[property.toLowerCase()](
              transformUrl(
                params
                  ? `${baseUrl}?${new URLSearchParams(params).toString()}`
                  : baseUrl
              ),
              config
            );
        if (["PUT", "POST"].includes(property))
          return (body, config) =>
            http[property.toLowerCase()](baseUrl, body, config);

        return createApi(
          `${baseUrl}/${
            // TODO(kcirtaptrick): transition all routes to consistent casing
            property.includes("_") || property.match(/^[A-Z_0-9]*$/)
              ? property
              : camelToKebab(property)
          }`
        );
      },
    }
  ) as Api;
