// DO NOT EDIT: This file is generated
import { OverlaysSchema } from "@kikoff/overlays/src/types";

export default (<T extends OverlaysSchema>(map: T): T => map)({
    "buckets/delete_confirmation": () => import("@src/overlays/buckets/delete_confirmation"),
    "buckets/external_bank_transfer": () => import("@src/overlays/buckets/external_bank_transfer.overlay"),
    "buckets/link_funding": () => import("@src/overlays/buckets/link_funding.overlay"),
    "buckets/one_time_transfer": () => import("@src/overlays/buckets/one_time_transfer"),
    "buckets/select_transfer_date": () => import("@src/overlays/buckets/select_transfer_date.overlay"),
    "buckets/settings": () => import("@src/overlays/buckets/settings"),
    "cash_card/direct_deposit": () => import("@src/overlays/cash_card/direct_deposit"),
    "cash_card/drawer": () => import("@src/overlays/cash_card/drawer"),
    "cash_card/intro": () => import("@src/overlays/cash_card/intro"),
    "cash_card/signup": () => import("@src/overlays/cash_card/signup"),
    "cash_card/update_address": () => import("@src/overlays/cash_card/update_address"),
    "cash_card/upsell_from_virtual_card": () => import("@src/overlays/cash_card/upsell_from_virtual_card"),
    "chat": () => import("@src/overlays/chat"),
    "credit_builder_savings/card": () => import("@src/overlays/credit_builder_savings/card"),
    "credit_builder_savings/make_payment": () => import("@src/overlays/credit_builder_savings/make_payment"),
    "credit_line/autopay": () => import("@src/overlays/credit_line/autopay"),
    "credit_line/card": () => import("@src/overlays/credit_line/card"),
    "credit_line/change_autopay_date": () => import("@src/overlays/credit_line/change_autopay_date"),
    "credit_line/make_payment": () => import("@src/overlays/credit_line/make_payment"),
    "credit_line/pause/pause_account": () => import("@src/overlays/credit_line/pause/pause_account"),
    "credit_line/pause/pause_confirmation": () => import("@src/overlays/credit_line/pause/pause_confirmation"),
    "credit_line/pause/pause_terms": () => import("@src/overlays/credit_line/pause/pause_terms"),
    "credit_line/pay_in_full": () => import("@src/overlays/credit_line/pay_in_full"),
    "credit_line/premium_downgrade": () => import("@src/overlays/credit_line/premium_downgrade"),
    "credit_line/premium_downgrade/not_available_date": () => import("@src/overlays/credit_line/premium_downgrade/not_available_date"),
    "credit_line/premium_downgrade/not_current_account": () => import("@src/overlays/credit_line/premium_downgrade/not_current_account"),
    "credit_line/premium_upsell/checkout": () => import("@src/overlays/credit_line/premium_upsell/checkout"),
    "credit_line/premium_upsell/promo": () => import("@src/overlays/credit_line/premium_upsell/promo"),
    "credit_line/report_unavailable": () => import("@src/overlays/credit_line/report_unavailable"),
    "credit_line/statement": () => import("@src/overlays/credit_line/statement"),
    "credit_line/update_payment": () => import("@src/overlays/credit_line/update_payment"),
    "debt_settlement/accepted_offer": () => import("@src/overlays/debt_settlement/accepted_offer"),
    "debt_settlement/account_details": () => import("@src/overlays/debt_settlement/account_details"),
    "debt_settlement/account_status": () => import("@src/overlays/debt_settlement/account_status"),
    "debt_settlement/cancel_offer": () => import("@src/overlays/debt_settlement/cancel_offer"),
    "debt_settlement/configure_payment_plan": () => import("@src/overlays/debt_settlement/configure_payment_plan"),
    "debt_settlement/debt_removed": () => import("@src/overlays/debt_settlement/debt_removed"),
    "debt_settlement/decline": () => import("@src/overlays/debt_settlement/decline/(decline)"),
    "debt_settlement/decline/freeform": () => import("@src/overlays/debt_settlement/decline/freeform"),
    "debt_settlement/decline/reason": () => import("@src/overlays/debt_settlement/decline/reason"),
    "debt_settlement/decline/thanks": () => import("@src/overlays/debt_settlement/decline/thanks"),
    "debt_settlement/estimated_payment_plan": () => import("@src/overlays/debt_settlement/estimated_payment_plan.overlay"),
    "debt_settlement/miss_payment_info": () => import("@src/overlays/debt_settlement/miss_payment_info"),
    "debt_settlement/monetization_details": () => import("@src/overlays/debt_settlement/monetization_details"),
    "debt_settlement/payment_progress": () => import("@src/overlays/debt_settlement/payment_progress"),
    "debt_settlement/payment_schedule": () => import("@src/overlays/debt_settlement/payment_schedule"),
    "debt_settlement/what_if_payment_missed": () => import("@src/overlays/debt_settlement/what_if_payment_missed.overlay"),
    "debt_settlement/why_cant_request_another_offer": () => import("@src/overlays/debt_settlement/why_cant_request_another_offer.overlay"),
    "debt_settlement/withdrawal_warning": () => import("@src/overlays/debt_settlement/withdrawal_warning"),
    "disputes/dispute_item": () => import("@src/overlays/disputes/dispute_item"),
    "disputes/dispute_letters": () => import("@src/overlays/disputes/dispute_letters"),
    "disputes/potential_score": () => import("@src/overlays/disputes/potential_score"),
    "disputes/premium_upsell": () => import("@src/overlays/disputes/premium_upsell/(premium_upsell).overlay"),
    "disputes/premium_upsell/checkout": () => import("@src/overlays/disputes/premium_upsell/checkout"),
    "disputes/premium_upsell/intro": () => import("@src/overlays/disputes/premium_upsell/intro"),
    "disputes/skip_id_upload_warning": () => import("@src/overlays/disputes/skip_id_upload_warning"),
    "external_bank_account/blocked_institution": () => import("@src/overlays/external_bank_account/blocked_institution"),
    "general/typeform_survey": () => import("@src/overlays/general/typeform_survey"),
    "identity_verification/document_upload_success": () => import("@src/overlays/identity_verification/document_upload_success"),
    "identity_verification/mfa": () => import("@src/overlays/identity_verification/mfa"),
    "identity_verification/persona_upload": () => import("@src/overlays/identity_verification/persona_upload"),
    "identity_verification/retry": () => import("@src/overlays/identity_verification/retry"),
    "kikoff_banking_service/cash_account_carousel": () => import("@src/overlays/kikoff_banking_service/cash_account_carousel"),
    "kikoff_banking_service/onboarding_upsell": () => import("@src/overlays/kikoff_banking_service/onboarding_upsell"),
    "landing/freemium_popup": () => import("@src/overlays/landing/freemium_popup"),
    "landing/waitlist": () => import("@src/overlays/landing/waitlist"),
    "lending/agreement": () => import("@src/overlays/lending/agreement"),
    "lending/autopay": () => import("@src/overlays/lending/autopay"),
    "mobile_app/download": () => import("@src/overlays/mobile_app/download"),
    "payments/add_payment_method": () => import("@src/overlays/payments/add_payment_method"),
    "payments/bank_verification_required": () => import("@src/overlays/payments/bank_verification_required"),
    "payments/display_frozen_method": () => import("@src/overlays/payments/display_frozen_method"),
    "payments/fix_payment": () => import("@src/overlays/payments/fix_payment"),
    "payments/post_fix_payment": () => import("@src/overlays/payments/post_fix_payment"),
    "payments/unfreeze_payment_method": () => import("@src/overlays/payments/unfreeze_payment_method"),
    "personal_loan/payoff_early": () => import("@src/overlays/personal_loan/payoff_early"),
    "product_accounts/autopay_schedule_confirmation": () => import("@src/overlays/product_accounts/autopay_schedule_confirmation"),
    "rent_reporting/backreporting_complete": () => import("@src/overlays/rent_reporting/backreporting_complete"),
    "rent_reporting/confirm_backreporting": () => import("@src/overlays/rent_reporting/confirm_backreporting"),
    "rent_reporting/dropoff_survey": () => import("@src/overlays/rent_reporting/dropoff_survey/(dropoff_survey).overlay"),
    "rent_reporting/dropoff_survey/complete": () => import("@src/overlays/rent_reporting/dropoff_survey/complete"),
    "rent_reporting/dropoff_survey/describe_issue": () => import("@src/overlays/rent_reporting/dropoff_survey/describe_issue"),
    "rent_reporting/dropoff_survey/select_issue": () => import("@src/overlays/rent_reporting/dropoff_survey/select_issue"),
    "rent_reporting/transaction_details": () => import("@src/overlays/rent_reporting/transaction_details"),
    "rent_reporting/update_address": () => import("@src/overlays/rent_reporting/update_address"),
    "rent_reporting/upsell": () => import("@src/overlays/rent_reporting/upsell"),
    "shopping/credit_line_increase": () => import("@src/overlays/shopping/credit_line_increase"),
    "shopping/post_purchase_survey/default": () => import("@src/overlays/shopping/post_purchase_survey/default"),
    "shopping/product": () => import("@src/overlays/shopping/product"),
    "shopping/rate": () => import("@src/overlays/shopping/rate"),
    "shopping/toggle_autorenew": () => import("@src/overlays/shopping/toggle_autorenew"),
    "shopping/utilization_warning": () => import("@src/overlays/shopping/utilization_warning"),
    "subscription/benefits": () => import("@src/overlays/subscription/benefits"),
    "subscription/discount": () => import("@src/overlays/subscription/discount"),
    "user/change_address": () => import("@src/overlays/user/change_address"),
    "user/change_email": () => import("@src/overlays/user/change_email"),
    "user/change_password": () => import("@src/overlays/user/change_password"),
    "user/change_phone": () => import("@src/overlays/user/change_phone"),
    "user/united_account_closure/account_closure_overlay": () => import("@src/overlays/user/united_account_closure/account_closure_overlay"),
    "user/united_account_closure/ca/closure-reason": () => import("@src/overlays/user/united_account_closure/ca/closure-reason"),
    "user/united_account_closure/ca/survey": () => import("@src/overlays/user/united_account_closure/ca/survey"),
    "user/united_account_closure/ca/terms": () => import("@src/overlays/user/united_account_closure/ca/terms"),
    "user/united_account_closure/cbl/closure-reason": () => import("@src/overlays/user/united_account_closure/cbl/closure-reason"),
    "user/united_account_closure/cbl/terms": () => import("@src/overlays/user/united_account_closure/cbl/terms"),
    "user/united_account_closure/cbl/withdraw": () => import("@src/overlays/user/united_account_closure/cbl/withdraw"),
    "user/united_account_closure/cbl/withdraw-confirm": () => import("@src/overlays/user/united_account_closure/cbl/withdraw-confirm"),
    "user/united_account_closure/cbl/withdraw-success": () => import("@src/overlays/user/united_account_closure/cbl/withdraw-success"),
    "user/united_account_closure/confirmation": () => import("@src/overlays/user/united_account_closure/confirmation"),
    "user/united_account_closure/reopen_survey": () => import("@src/overlays/user/united_account_closure/reopen_survey"),
    "user/united_account_closure/sc/ach-final-success": () => import("@src/overlays/user/united_account_closure/sc/ach-final-success"),
    "user/united_account_closure/sc/ach-final-withdrawal": () => import("@src/overlays/user/united_account_closure/sc/ach-final-withdrawal"),
    "user/united_account_closure/sc/close-account-failure": () => import("@src/overlays/user/united_account_closure/sc/close-account-failure"),
    "user/united_account_closure/sc/close-checkbox": () => import("@src/overlays/user/united_account_closure/sc/close-checkbox"),
    "user/united_account_closure/sc/close-info": () => import("@src/overlays/user/united_account_closure/sc/close-info"),
    "user/united_account_closure/sc/closure-completed": () => import("@src/overlays/user/united_account_closure/sc/closure-completed"),
    "user/united_account_closure/sc/closure-reason": () => import("@src/overlays/user/united_account_closure/sc/closure-reason"),
    "src/components/dashboard/account/details/ach_agreement": () => import("@src/components/dashboard/account/details/ach_agreement.overlay"),
    "src/components/dashboard/account/payment_methods/autopay_new_card": () => import("@src/components/dashboard/account/payment_methods/autopay_new_card.overlay"),
    "src/components/dashboard/account/payment_methods/card_options": () => import("@src/components/dashboard/account/payment_methods/card_options.overlay"),
    "src/components/dashboard/account/payment_methods/confirm_removal": () => import("@src/components/dashboard/account/payment_methods/confirm_removal.overlay"),
    "src/components/dashboard/account/payment_methods/confirm_removal_old": () => import("@src/components/dashboard/account/payment_methods/confirm_removal_old.overlay"),
    "src/components/dashboard/credit_account/achievements/section/kikup_tooltip": () => import("@src/components/dashboard/credit_account/achievements/section/kikup_tooltip.overlay"),
    "src/components/dashboard/credit_account/achievements/unlocked_celebration": () => import("@src/components/dashboard/credit_account/achievements/unlocked_celebration/index.overlay"),
    "src/components/dashboard/credit_account/details/info": () => import("@src/components/dashboard/credit_account/details/info.overlay"),
    "src/components/dashboard/referral/confirmation": () => import("@src/components/dashboard/referral/confirmation.overlay"),
    "src/components/dashboard/v2/one_time_payment": () => import("@src/components/dashboard/v2/one_time_payment.overlay"),
    "src/components/dashboard/v2/payment_failed": () => import("@src/components/dashboard/v2/payment_failed.overlay"),
    "src/components/debt_settlement/check_sued": () => import("@src/components/debt_settlement/check_sued.overlay"),
    "src/components/debt_settlement/sued_offer_unavailable": () => import("@src/components/debt_settlement/sued_offer_unavailable.overlay"),
    "src/components/modals/confirm_disable": () => import("@src/components/modals/confirm_disable.overlay"),
    "src/components/onboarding/steps/suggestion_card": () => import("@src/components/onboarding/steps/suggestion_card.overlay"),
    "src/components/payment/payment_confirmation": () => import("@src/components/payment/payment_confirmation.overlay"),
    "src/pages/dashboard/buckets/_views/all_transactions": () => import("@src/pages/dashboard/buckets/_views/all_transactions.overlay"),
    "src/pages/dashboard/buckets/_views/auto_deposit_settings": () => import("@src/pages/dashboard/buckets/_views/auto_deposit_settings.overlay"),
    "src/pages/dashboard/buckets/_views/disable_auto_deposit_confirmation": () => import("@src/pages/dashboard/buckets/_views/disable_auto_deposit_confirmation.overlay"),
    "src/pages/dashboard/cash-card/_views/ach_transfer_confirm": () => import("@src/pages/dashboard/cash-card/_views/ach_transfer_confirm.overlay"),
    "src/pages/dashboard/cash-card/_views/command": () => import("@src/pages/dashboard/cash-card/_views/command.overlay"),
    "src/pages/dashboard/cash-card/_views/confirm_transfer_schedule": () => import("@src/pages/dashboard/cash-card/_views/confirm_transfer_schedule.overlay"),
    "src/pages/dashboard/cash-card/_views/generic_error": () => import("@src/pages/dashboard/cash-card/_views/generic_error.overlay"),
    "src/pages/dashboard/cash-card/_views/instant_transfer_confirm": () => import("@src/pages/dashboard/cash-card/_views/instant_transfer_confirm.overlay"),
    "src/pages/dashboard/cash-card/_views/rating": () => import("@src/pages/dashboard/cash-card/_views/rating.overlay"),
    "src/pages/dashboard/credit-builder-savings/voucher/how_to_unlock": () => import("@src/pages/dashboard/credit-builder-savings/voucher/how_to_unlock.overlay"),
    "src/pages/dashboard/credit-builder-savings/withdraw-voucher-funds/_views/confirm_withdrawal": () => import("@src/pages/dashboard/credit-builder-savings/withdraw-voucher-funds/_views/confirm_withdrawal.overlay"),
    "src/pages/dashboard/credit-score/_views/continue_dispute": () => import("@src/pages/dashboard/credit-score/_views/continue_dispute.overlay"),
    "src/pages/dashboard/credit-score/_views/dispute_actions": () => import("@src/pages/dashboard/credit-score/_views/dispute_actions.overlay"),
    "src/pages/dashboard/credit-score/_views/dispute_custom_reason": () => import("@src/pages/dashboard/credit-score/_views/dispute_custom_reason.overlay"),
    "src/pages/dashboard/credit-score/_views/dispute_response": () => import("@src/pages/dashboard/credit-score/_views/dispute_response.overlay"),
    "src/pages/dashboard/credit-score/_views/factors/freemium": () => import("@src/pages/dashboard/credit-score/_views/factors/freemium.overlay"),
    "src/pages/dashboard/credit-score/_views/failed_item_remain": () => import("@src/pages/dashboard/credit-score/_views/failed_item_remain.overlay"),
    "src/pages/dashboard/credit-score/_views/full-report/feedback": () => import("@src/pages/dashboard/credit-score/_views/full-report/feedback.overlay"),
    "src/pages/dashboard/credit-score/_views/full-report/premium_upsell": () => import("@src/pages/dashboard/credit-score/_views/full-report/premium_upsell.overlay"),
    "src/pages/dashboard/credit-score/_views/intro": () => import("@src/pages/dashboard/credit-score/_views/intro.overlay"),
    "src/pages/dashboard/credit-score/_views/item_deleted": () => import("@src/pages/dashboard/credit-score/_views/item_deleted.overlay"),
    "src/pages/dashboard/credit-score/_views/items_remain": () => import("@src/pages/dashboard/credit-score/_views/items_remain.overlay"),
    "src/pages/dashboard/credit-score/_views/mark_as_sent": () => import("@src/pages/dashboard/credit-score/_views/mark_as_sent.overlay"),
    "src/pages/dashboard/credit-score/_views/tooltip": () => import("@src/pages/dashboard/credit-score/_views/tooltip.overlay"),
    "src/pages/dashboard/credit-score/dispute/_views/credit_account_upsell": () => import("@src/pages/dashboard/credit-score/dispute/_views/credit_account_upsell.overlay"),
    "src/pages/dashboard/credit-score/dispute/_views/dispute_account_details": () => import("@src/pages/dashboard/credit-score/dispute/_views/dispute_account_details.overlay"),
    "src/pages/dashboard/credit-score/dispute/_views/dispute_push": () => import("@src/pages/dashboard/credit-score/dispute/_views/dispute_push.overlay"),
    "src/pages/dashboard/credit-score/dispute/_views/dispute_survey_closing": () => import("@src/pages/dashboard/credit-score/dispute/_views/dispute_survey_closing.overlay"),
    "src/pages/dashboard/credit-score/dispute/_views/dispute_survey_custom_response": () => import("@src/pages/dashboard/credit-score/dispute/_views/dispute_survey_custom_response.overlay"),
    "src/pages/dashboard/credit-score/dispute/_views/dispute_survey_letter_dates": () => import("@src/pages/dashboard/credit-score/dispute/_views/dispute_survey_letter_dates.overlay"),
    "src/pages/dashboard/credit-score/dispute/_views/dispute_survey_options": () => import("@src/pages/dashboard/credit-score/dispute/_views/dispute_survey_options.overlay"),
    "src/pages/dashboard/credit-score/dispute/_views/saved": () => import("@src/pages/dashboard/credit-score/dispute/_views/saved.overlay"),
    "src/pages/dashboard/credit-score/dispute/_views/why_documents": () => import("@src/pages/dashboard/credit-score/dispute/_views/why_documents.overlay"),
    "src/pages/dashboard/credit-score/dispute/tip": () => import("@src/pages/dashboard/credit-score/dispute/tip.overlay"),
    "src/pages/dashboard/hello-privacy/confirmation": () => import("@src/pages/dashboard/hello-privacy/confirmation.overlay"),
    "src/pages/dashboard/hello-privacy/signup": () => import("@src/pages/dashboard/hello-privacy/signup.overlay"),
    "src/pages/dashboard/rent-reporting/start/_views/duplicate_file": () => import("@src/pages/dashboard/rent-reporting/start/_views/duplicate_file.overlay"),
    "src/pages/demo/demo-overlay/demo": () => import("@src/pages/demo/demo-overlay/demo.overlay"),
    "src/pages/onboarding/v2/_views/ssn/learn_more": () => import("@src/pages/onboarding/v2/_views/ssn/learn_more.overlay"),
  });
