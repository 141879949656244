import { FluentResource } from "@fluent/bundle";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CardElement } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";

import Strike from "@kikoff/components/src/v1/text/Strike";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { serverNow } from "@kikoff/utils/src/number";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoTime,
} from "@kikoff/utils/src/proto";
import { format } from "@kikoff/utils/src/string";

import { selectDismissable } from "@feature/page";
import { AppThunk, RootState } from "@store";
import analytics, { sputter } from "@util/analytics";
import { newReactLocalization } from "@util/l10n";
import { autoPayState } from "@util/payments";

import { addPaymentMethod, fetchPaymentMethods } from "./funds";
import { updateOrders } from "./shopping";
import { openSubscriptionAndCreditLine } from "./subscription";
import { fetchUser } from "./user";

const RESOURCES = {
  en: new FluentResource(`payment-submit-adding-payment-method = Adding payment method...
payment-submit-paying-credit-line = Paying credit line...
payment-submit-enabling-autopay = Enabling autopay...
payment-submit-scheduling-payment = Scheduling payment...
`),
  es: new FluentResource(`payment-submit-adding-payment-method = Agregar método de pago...
payment-submit-paying-credit-line = Pagando la línea de crédito...
payment-submit-enabling-autopay = Habilitando autopay...
payment-submit-scheduling-payment = Programación del pago...
`),
};

const initialState = {
  account: null as web.public_.ICreditLineAccount,
};

export type CreditLineState = typeof initialState;

const creditLineSlice = createSlice({
  name: "creditLine",
  initialState,
  reducers: {
    setCreditLineAccount(
      state,
      { payload }: PayloadAction<CreditLineState["account"]>
    ) {
      state.account = payload;
    },
  },
});

export const { setCreditLineAccount } = creditLineSlice.actions;
export default creditLineSlice.reducer;

export const selectCreditAccount = () => (state: RootState) =>
  state.creditLine.account;

export const selectCreditAccountOverdue = () => (state: RootState) =>
  // paymentDueDate is 0 when balance is 0
  (protoTime(selectCreditAccount()(state)?.paymentDueDate) || Infinity) <
  serverNow();

export const selectDisplayCreditlineAchievement = () => (state: RootState) => {
  if (
    !state.creditLine.account ||
    !state.creditLine.account.creditLineIncreasePolicy
  ) {
    return false;
  }

  if (!selectDismissable("CL_PAYMENT_ACHIEVEMENT")(state)) {
    return false;
  }

  if (
    state.creditLine.account.creditLineIncreasePolicy?.paymentStreak > 0 &&
    state.creditLine.account.creditLimitCents -
      state.creditLine.account.creditLineAgreement.defaultCreditLimitCents >
      0
  ) {
    return true;
  }

  return false;
};

export const fetchCreditLineAccount = (): AppThunk<
  Promise<web.public_.CreditLineAccount>
> => async (dispatch) =>
  webRPC.CreditLine.listCreditLineDetails({}).then(
    handleProtoStatus({
      SUCCESS({ creditLine }) {
        dispatch(setCreditLineAccount(creditLine));
        return creditLine;
      },
      _DEFAULT: handleFailedStatus("Failed to get credit line info"),
    })
  );

export const closeCreditLine = ({
  creditLineToken,
  closureReason,
  closureNote,
}): AppThunk<Promise<void>> => async (dispatch) =>
  webRPC.CreditLine.closeCreditLine({
    closureReason,
    creditLineToken,
    closureNote,
  }).then(
    handleProtoStatus({
      SUCCESS() {
        dispatch(fetchUser.creditLine());
      },
      _DEFAULT: handleFailedStatus("Failed to close. Please contact support."),
    })
  );

export const pauseCreditLine = ({
  creditLineToken,
  months,
}): AppThunk<Promise<void>> => async (dispatch) =>
  webRPC.CreditLine.pauseCreditLine({
    creditLineToken,
    months,
  }).then(
    handleProtoStatus({
      SUCCESS() {
        dispatch(fetchUser.creditLine());
      },
      _DEFAULT: handleFailedStatus("Failed to pause. Please contact support."),
    })
  );

type PayCreditLineBalanceOptions = {
  amount?: number;
  paymentMethodToken: string;
};

export const payCreditLineBalance = ({
  amount,
  paymentMethodToken,
}: PayCreditLineBalanceOptions): AppThunk<Promise<void>> => (
  dispatch,
  getState
) => {
  const creditLine = getState().creditLine.account;

  return webRPC.CreditLine.makeCreditLinePayment({
    amountCents: amount || creditLine.outstandingMinimumPaymentCents,
    paymentMethodToken,
    creditLineToken: creditLine.token,
  })
    .then(
      handleProtoStatus({
        SUCCESS() {
          // if this is the first payment
          if (creditLine.numberOfPayments === 0) {
            analytics.convert("Make Credit Line Payment");
          }
        },
        _DEFAULT: handleFailedStatus("Failed to make credit line payment"),
      })
    )
    .catch((err) => {
      dispatch(fetchUser.creditLine());
      sputter("credit line: payment error", {
        "error message": err.message,
      });

      throw err;
    });
};

interface SetupCreditLinePaymentOptions {
  stripe: Stripe;
  elements: StripeElements;
  onUpdate(text: string): void;
  onNewPaymentMethodToken?(paymentMethodToken: string): void;
  paymentMethodToken: string;
  autopayEnabled: boolean;
  payCreditLine?: boolean;
  openCreditLine?: boolean;
  cashapp?: boolean;
}

export const setupCreditLineRepayment = ({
  stripe,
  elements,
  onUpdate,
  onNewPaymentMethodToken,
  paymentMethodToken,
  autopayEnabled,
  payCreditLine,
  openCreditLine,
  cashapp = false,
}: SetupCreditLinePaymentOptions): AppThunk<Promise<void>> => async (
  dispatch,
  getState
) => {
  const l10n = newReactLocalization(RESOURCES);
  const getProduct = () =>
    getState().shopping.checkoutPreview.order.orderItems[0].product;
  const shoppingBag = getState().shopping.bag;

  const methodToken =
    (!cashapp && paymentMethodToken) ||
    (onUpdate(l10n.getString("payment-submit-adding-payment-method")),
    await dispatch(
      addPaymentMethod({
        paymentMethod: cashapp
          ? null
          : {
              card: elements.getElement(CardElement),
            },
        stripe,
        onUpdate,
        noRefresh: true,
        cashapp,
      })
    )
      .then((token) => {
        onNewPaymentMethodToken?.(token);
        return token;
      })
      .catch((err) => {
        err.name = "PaymentMethodError";
        throw err;
      }));

  dispatch(fetchPaymentMethods());

  const autoPay = {
    paymentMethodToken: methodToken,
    state: autoPayState(autopayEnabled),
  };

  if (openCreditLine) {
    // Mobile listens for this event to trigger Firebase events
    sputter("credit line: clicked activate");

    await dispatch(
      openSubscriptionAndCreditLine(
        autopayEnabled,
        getProduct().plan,
        autoPay,
        getProduct().token,
        shoppingBag.firstPaymentDate
      )
    ).then(() => {
      sputter("streamlined payment view: shop checkout");
      if (autopayEnabled) {
        analytics.convert("Enable Autopay");
      }
    });
  }

  if (payCreditLine) {
    const creditLine = getState().creditLine.account;
    if (creditLine.outstandingMinimumPaymentCents === 0) return;
    onUpdate(l10n.getString("payment-submit-paying-credit-line"));
    await dispatch(payCreditLineBalance({ paymentMethodToken: methodToken }));
  }

  onUpdate(
    autopayEnabled
      ? l10n.getString("payment-submit-enabling-autopay")
      : l10n.getString("payment-submit-scheduling-payment")
  );

  await dispatch(updateOrders());
  const creditLine = (await dispatch(fetchUser.creditLine())).openCreditLine;
  if (
    creditLine.delayedPayment?.status ===
    web.public_.CreditLineAccount.DelayedPayment.Status.FAILED
  )
    throw new Error(`Payment failed with: ${creditLine.delayedPayment.reason}`);
};

export const getAmountText = (creditLine: web.public_.ICreditLineAccount) => {
  const [amount, discount] = creditLine.outstandingMinimumPaymentCents
    ? [
        creditLine.outstandingMinimumPaymentCents +
          creditLine.currentStatementCreditAppliedCents,
        creditLine.currentStatementCreditAppliedCents,
      ]
    : [creditLine.minimumPaymentDueCents, creditLine.nextStatementCreditCents];

  return discount ? (
    <span>
      {format.money(amount - discount)}{" "}
      {discount > 0 && (
        <Strike className="color:moderate-weak">{format.money(amount)}</Strike>
      )}
    </span>
  ) : (
    format.money(amount)
  );
};

export const limitCapInCents = 10000_00;
export const flatStrategyIncreaseCents = 500_00;
export const streakStrategyInitialIncreaseCents = 50_00;
