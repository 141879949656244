import styles from "./credit_change.module.scss";

interface CreditChangeProps {
  diff: number;
}

export default function CreditChange({ diff }: CreditChangeProps) {
  return (
    <span className={styles["credit-change"]}>
      {diff > 0 && <span className="color:primary"></span>}
      {diff}
    </span>
  );
}
