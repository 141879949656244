import React, { useEffect, useMemo, useRef } from "react";
import { batch, useSelector } from "react-redux";
import Router from "next/router";
import { addDays } from "date-fns";
import { maxBy } from "lodash-es";
import { Tuple } from "record-tuple";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import KButton from "@kikoff/components/src/v1/buttons/KButton";
import KLink from "@kikoff/components/src/v1/navigation/KLink";
import { DebtOfferType } from "@kikoff/proto/src/dev/factories/debt_settlement/DebtSettlementAccount";
import { SettlementSchedule } from "@kikoff/proto/src/dev/factories/debt_settlement/SettlementOffer";
import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { promiseDelay } from "@kikoff/utils/src/general";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import { format } from "@kikoff/utils/src/string";

import { useBackendExperiment } from "@src/experiments/context";
import { handleURL } from "@src/kikoff_url";
import { useOverlaysController } from "@src/overlay";
import { RootState } from "@store";
import { track } from "@util/analytics";
import { isRootWebview, nativeDispatch } from "@util/mobile";

import { createLoadableSelector, thunk } from "../utils";

import { Pocket, selectPocket, updatePockets } from "./banking";
import { fetchSiteVars, selectDismissable, selectFeatureFlag } from "./page";

// Prevent direct .pocket access in favor of selecting normalized pocket state
// in state.banking.pocket, pockets can update independently of their associated
// debt account
export type DebtSettlementAccount = Omit<
  web.public_.IDebtSettlementAccount,
  "pocket"
> & {
  pocket?: Pick<web.public_.IPocket, "token">;
};

const { SettlementStatus } = web.public_.DebtSettlementAccount;

const initialState = {
  accounts: null as DebtSettlementAccount[],
  firstTransferDate: {
    seconds: Date.now() / 1000,
  } as google.protobuf.ITimestamp,
};

export type DebtSettlementState = typeof initialState;

const debtSettlementSlice = createSlice({
  name: "debtSettlement",
  initialState,
  reducers: {
    setAccounts(
      state,
      { payload }: PayloadAction<DebtSettlementState["accounts"]>
    ) {
      state.accounts = payload;
    },
    updateFirstTransferDate(
      state,
      { payload }: PayloadAction<DebtSettlementState["firstTransferDate"]>
    ) {
      state.firstTransferDate = payload;
    },
  },
});

const { actions } = debtSettlementSlice;
export const {} = actions;

export default debtSettlementSlice.reducer;

export const selectDebtAccounts = () => (state: RootState) =>
  state.debtSettlement.accounts;

export const selectDebtAccount = createLoadableSelector(
  (token: string) => (state: RootState) =>
    state.debtSettlement.accounts?.find((account) => account.token === token),
  { loadAction: () => fetchDebtSettlementAccounts() }
);

export const selectDebtAccountsBy = (() => {
  const notStartedStatuses = new Set([
    SettlementStatus.UNPAID,
    SettlementStatus.UNKNOWN,
    SettlementStatus.BLOCKED,
  ]);

  return Object.assign(
    (
      predicate: (account: DebtSettlementState["accounts"][number]) => unknown
    ) => (state: RootState) =>
      state.debtSettlement.accounts?.filter(predicate) || [],
    {
      started: () =>
        selectDebtAccountsBy(({ status }) => !notStartedStatuses.has(status)),
      notStarted: () =>
        selectDebtAccountsBy(({ status }) => notStartedStatuses.has(status)),
      eligible: () => selectDebtAccountsBy(({ pocket }) => !pocket),
    }
  );
})();

export const selectDebtSettlementOfferBy = <
  Field extends keyof web.public_.ISettlementOffer
>(
  debtAccountToken: string,
  field: Field,
  match: web.public_.ISettlementOffer[Field] | boolean
) => (state: RootState) =>
  state.credit.settlementOffers[debtAccountToken]?.find((offer) =>
    typeof match === "boolean"
      ? !!offer[field] === match
      : offer[field] === match
  );

// Special cases that need independent tracking
export enum DebtSettlementStage {
  // Offer increased
  GOAL_INCREASED,
  OFFER_ACCEPTABLE,
}

// Used in user facing settlement timeline
export enum DebtSettlementTimelineEvents {
  NONE,
  CREATED_BUCKET,
  FUNDED_BUCKET,
  ACCEPTED_OFFER,
  MADE_PAYMENTS, // Only after user makes all payments
  DEBT_REMOVED,
}

export const selectDebtServicingState = (() => {
  let ref = null;
  let cache = null;

  return () => (state: RootState) => {
    const { accounts } = state.debtSettlement;

    if (!accounts) return null;

    const nextRef = Tuple(accounts, state.banking.pockets);
    if (ref === nextRef) return cache as never;
    ref = nextRef;

    enum Priority {
      NONE,
      INFO,
      ACTION_AVAILABLE,
      ACTION_REQUIRED,
    }

    type DebtServicingContext =
      | "dashboard"
      | "home"
      | "account"
      | "notification";

    type AccountServicingState = {
      priority: Priority;
      stage?: DebtSettlementStage;
      NextStep?: React.FC<{
        context: Exclude<DebtServicingContext, "dashboard">;
      }>;
      Cta?: React.FC<{ context: DebtServicingContext }>;
      statusText: React.ReactNode;
      latestEvent: DebtSettlementTimelineEvents;
    };

    const stateByAccountToken = Object.fromEntries(
      accounts
        .map(
          (debtAccount) =>
            [
              debtAccount.token,
              ((): AccountServicingState => {
                const {
                  token,
                  status,
                  balanceCents,
                  removedAt,
                  settlementLetterLink,
                  accountNumber,
                } = debtAccount;
                const offerType = DebtSettlementAccount.offer.type(debtAccount);
                const offer = DebtSettlementAccount.offer.any(debtAccount);
                const pocket = selectPocket(debtAccount.pocket?.token)(state);

                const defaultState: AccountServicingState = {
                  latestEvent: DebtSettlementTimelineEvents.NONE,
                  priority: Priority.NONE,
                  statusText: "Collection account",
                };

                if (status === SettlementStatus.UNPAID || !pocket)
                  return defaultState;

                // `status` will stay as REQUESTED until first payment is
                // successfully transfered
                if (status === SettlementStatus.ESTIMATE) {
                  if (pocket.balanceCents >= pocket.goalAmountCents)
                    return {
                      latestEvent: DebtSettlementTimelineEvents.FUNDED_BUCKET,
                      priority: Priority.ACTION_AVAILABLE,
                      NextStep() {
                        return (
                          <>
                            Your {format.money(pocket.balanceCents)} deposit has
                            arrived in your bucket. It’s time to request an
                            offer for your collection account!
                          </>
                        );
                      },
                      Cta() {
                        return (
                          <KLink
                            href={`/dashboard/debt-relief/request-offer/${token}`}
                          >
                            <KButton.RequireContext>
                              Request an offer
                            </KButton.RequireContext>
                          </KLink>
                        );
                      },
                      statusText: (
                        <div className="text:small+ color:success">
                          Request offer
                        </div>
                      ),
                    };

                  if (Pocket.willBeFunded(pocket))
                    return {
                      latestEvent: DebtSettlementTimelineEvents.CREATED_BUCKET,
                      priority: Priority.INFO,
                      NextStep() {
                        return (
                          <>
                            You’re all set for now! We’ll notify you when your
                            funds arrive so you can request your offer.
                          </>
                        );
                      },
                      statusText: (
                        <div className="text:small+ color:success">
                          Funding initiated
                        </div>
                      ),
                    };

                  return {
                    latestEvent: DebtSettlementTimelineEvents.CREATED_BUCKET,
                    priority: Priority.ACTION_AVAILABLE,
                    NextStep() {
                      return (
                        <>
                          Fund your Debt Relief Bucket to request discounted
                          offers for your collection account.
                        </>
                      );
                    },
                    Cta({ context }) {
                      // Fund bucket button appears in bucket component on
                      // account page
                      if (context === "account") return null;
                      return (
                        <KLink
                          href={`/dashboard/debt-relief/start/${token}/${DebtSettlementAccount.offer.type.selected.toUrlParam(
                            debtAccount
                          )}/bucket/funding`}
                        >
                          <KButton.RequireContext>
                            {
                              {
                                home: "Fund your bucket",
                                dashboard: "Add funds",
                              }[context]
                            }
                          </KButton.RequireContext>
                        </KLink>
                      );
                    },
                    statusText: (
                      <div className="text:small+ color:success">
                        Bucket created, add funds
                      </div>
                    ),
                  };
                }

                // If an account is not fully funded in any of these statuses, the user
                // will need to update their transfer schedule to add funds
                if (
                  status === SettlementStatus.OFFER_AVAILABLE &&
                  !Pocket.willBeFunded(pocket)
                )
                  return {
                    stage: DebtSettlementStage.GOAL_INCREASED,
                    latestEvent: DebtSettlementTimelineEvents.CREATED_BUCKET,
                    priority: Priority.ACTION_REQUIRED,
                    NextStep() {
                      return (
                        <>
                          In rare cases your collector will give you an offer
                          that is different from the estimate. Your offer ended
                          up being {format.money(offer.totalCostCents)} instead
                          of {format.money(offer.originalBalanceCents / 2)}, so
                          you need to update your auto-deposit amount in order
                          to accept your offer.
                        </>
                      );
                    },
                    Cta({ context }) {
                      const overlays = useOverlaysController();

                      if (context === "account") return null;

                      return (
                        <KButton.RequireContext
                          onClick={() => {
                            if (context === "dashboard")
                              return Router.push(`/dashboard/debt-relief`);
                            overlays.push(
                              "src/pages/dashboard/buckets/_views/auto_deposit_settings",
                              { pocketToken: pocket.token, enable: true }
                            );
                          }}
                        >
                          Update amount
                        </KButton.RequireContext>
                      );
                    },
                    statusText: (
                      <div className="text:small+ color:error">
                        Update transfer amount
                      </div>
                    ),
                  };

                if (
                  status === SettlementStatus.AWAITING_REQUEST ||
                  status === SettlementStatus.REQUESTED
                )
                  return {
                    latestEvent: DebtSettlementTimelineEvents.FUNDED_BUCKET,
                    priority: Priority.INFO,
                    NextStep() {
                      return (
                        <>
                          We’re requesting an offer for your{" "}
                          {format.money(balanceCents)} collection account! We’ll
                          notify you when we receive a response.
                        </>
                      );
                    },
                    statusText: (
                      <div className="text:small+ color:success">
                        Offer requested
                      </div>
                    ),
                  };

                const ArchiveCta = () => {
                  const overlays = useOverlaysController();

                  // Don't prompt for withdrawal without balance
                  if (pocket.balanceCents === 0) return null;

                  return (
                    <KButton.RequireContext
                      onClick={() => {
                        overlays.push("buckets/one_time_transfer", {
                          pocketToken: pocket.token,
                          direction: "withdraw",
                          defaultAmountCents: pocket.balanceCents,
                        });
                      }}
                    >
                      Withdraw funds
                    </KButton.RequireContext>
                  );
                };
                if (
                  status === SettlementStatus.OFFER_NOT_AVAILABLE ||
                  status === SettlementStatus.OFFER_REJECTED_BY_OWNER
                )
                  return {
                    latestEvent: DebtSettlementTimelineEvents.FUNDED_BUCKET,
                    priority: Priority.ACTION_AVAILABLE,
                    NextStep() {
                      return (
                        <>
                          We requested a money-saving offer for your debt, but
                          unfortunately no offers are available at this time for
                          this particular account. Click the button below to
                          close your debt bucket and transfer the balance of
                          your bucket to your Secured Credit Card account.
                        </>
                      );
                    },
                    Cta: ArchiveCta,
                    statusText: (
                      <div className="color:error">No offer available</div>
                    ),
                  };

                if (status === SettlementStatus.OFFER_AVAILABLE)
                  return {
                    stage: DebtSettlementStage.OFFER_ACCEPTABLE,
                    latestEvent: DebtSettlementTimelineEvents.FUNDED_BUCKET,
                    priority: Priority.ACTION_AVAILABLE,
                    NextStep() {
                      return (
                        <>
                          Congrats! We got you a settlement offer from your
                          collector. Click to check out your offer. The offer
                          expires on{" "}
                          <b>
                            {format.date(
                              addDays(offer.expiresAt?.seconds * 1000, -5),
                              "Mmmm d, yyyy"
                            )}
                          </b>
                          .
                        </>
                      );
                    },
                    Cta() {
                      return (
                        <KLink
                          href={`/dashboard/debt-relief/offer-ready/${token}`}
                        >
                          <KButton.RequireContext>
                            View my offer
                          </KButton.RequireContext>
                        </KLink>
                      );
                    },
                    statusText: (
                      <div className="text:small+ color:success">
                        View offer
                      </div>
                    ),
                  };

                if (status === SettlementStatus.OFFER_EXPIRED) {
                  return {
                    latestEvent: DebtSettlementTimelineEvents.FUNDED_BUCKET,
                    priority: Priority.ACTION_AVAILABLE,
                    NextStep({ context }) {
                      return (
                        <>
                          {
                            {
                              home: `Your original offer for this collection account ••••${accountNumber.slice(
                                -4
                              )} expired. Click the button below to request a new offer.`,
                              account: "You offer has expired.",
                            }[context]
                          }
                        </>
                      );
                    },
                    Cta() {
                      return (
                        <KLink
                          href={`/dashboard/debt-relief/request-offer/${token}`}
                        >
                          <KButton.RequireContext>
                            Request a new offer
                          </KButton.RequireContext>
                        </KLink>
                      );
                    },
                    statusText: (
                      <div className="text:small+ color:error">
                        Offer expired
                      </div>
                    ),
                  };
                }

                if (
                  status === SettlementStatus.OFFER_ACCEPTED ||
                  status === SettlementStatus.OFFER_ACCEPTANCE_SUBMITTED ||
                  status === SettlementStatus.OFFER_CONFIRMED
                )
                  return {
                    latestEvent: DebtSettlementTimelineEvents.ACCEPTED_OFFER,
                    priority: Priority.INFO,
                    NextStep() {
                      return (
                        <>
                          Congrats! You accepted a{" "}
                          <span>{format.money(offer.totalCostCents)}</span>{" "}
                          offer for your collection on{" "}
                          {format.date(offer.acceptedAt, "mm/dd/yyyy")}. We’ll
                          notify you when your Offer Confirmation is available.
                        </>
                      );
                    },
                    Cta({ context }) {
                      const overlays = useOverlaysController();

                      return (
                        <KButton.RequireContext
                          onClick={() => {
                            overlays.push("debt_settlement/accepted_offer", {
                              debtAccountToken: token,
                            });
                          }}
                        >
                          View {context !== "dashboard" && "accepted"} offer
                        </KButton.RequireContext>
                      );
                    },
                    statusText: (
                      <div className="text:small+ color:success">
                        Accepted a {format.money(offer.totalCostCents)} offer
                      </div>
                    ),
                  };

                const savings =
                  offer.originalBalanceCents - offer.totalCostCents;

                if (removedAt)
                  return {
                    latestEvent: DebtSettlementTimelineEvents.DEBT_REMOVED,
                    priority: Priority.INFO,
                    NextStep() {
                      return (
                        <>
                          Congrats! You successfully paid off your offer. Your
                          collector will delete this account from your credit
                          report within 60 days.
                        </>
                      );
                    },
                    Cta: ArchiveCta,
                    statusText: (
                      <div className="text:small+ color:success">
                        Paid off • Saved {format.money(savings)}
                      </div>
                    ),
                  };

                const { settlementSchedule } = offer;

                const nextPayment = SettlementSchedule.nextPayment(
                  settlementSchedule
                );
                const prevPayment = SettlementSchedule.prevPayment(
                  settlementSchedule
                );

                if (!nextPayment)
                  return {
                    latestEvent: DebtSettlementTimelineEvents.MADE_PAYMENTS,
                    priority: Priority.INFO,
                    NextStep() {
                      return (
                        <>
                          Congrats! You successfully paid off your offer. Your
                          collector will delete this account from your credit
                          report within 60 days.
                        </>
                      );
                    },
                    Cta: ArchiveCta,
                    statusText: (
                      <div className="text:small+ color:success">
                        Paid off • Saved {format.money(savings)}
                      </div>
                    ),
                  };

                if (status === SettlementStatus.PAYABLE) {
                  if (!Pocket.willBeFunded(pocket)) {
                    const amountStr = format.money(pocket.goalAmountCents);

                    return {
                      latestEvent: DebtSettlementTimelineEvents.ACCEPTED_OFFER,
                      priority: Priority.ACTION_REQUIRED,
                      NextStep() {
                        const overlays = useOverlaysController();

                        return (
                          <>
                            Your Debt Relief bucket balance is below {amountStr}
                            , please make sure you maintain a bucket balance of{" "}
                            <b>{amountStr}</b> by{" "}
                            <b>
                              {format.date(
                                nextPayment.scheduledDebitAt,
                                "Mmmm d, yyyy"
                              )}
                            </b>{" "}
                            to avoid your cancellation on your offer.{" "}
                            <KButton.WithoutContext
                              variant="text-underline"
                              size="hug"
                              onClick={() => {
                                overlays.push(
                                  "debt_settlement/miss_payment_info",
                                  {}
                                );
                              }}
                            >
                              Learn more
                            </KButton.WithoutContext>
                          </>
                        );
                      },
                      Cta() {
                        const overlays = useOverlaysController();

                        return (
                          <KButton.RequireContext
                            onClick={() => {
                              overlays.push("buckets/one_time_transfer", {
                                pocketToken: pocket.token,
                                direction: "deposit",
                              });
                            }}
                          >
                            Add funds now
                          </KButton.RequireContext>
                        );
                      },
                      statusText: (
                        <div className="text:small+ color:error">
                          Not enough funds
                        </div>
                      ),
                    };
                  }
                  return {
                    latestEvent: DebtSettlementTimelineEvents.ACCEPTED_OFFER,
                    // Not a real action, just prompting user to view their offer
                    priority: Priority.ACTION_AVAILABLE,
                    ...(nextPayment === settlementSchedule[0]
                      ? {
                          NextStep() {
                            return (
                              <>
                                Great news! Your offer confirmation from
                                Portfolio Recovery Associates is now available.
                                Your monthly payment will be debited from your
                                bucket starting on{" "}
                                {format.date(
                                  settlementSchedule[0].scheduledDebitAt,
                                  "mm/dd/yyyy"
                                )}
                                .
                              </>
                            );
                          },
                          Cta({ context }) {
                            return (
                              <KLink href={settlementLetterLink} type="pdf">
                                <KButton.RequireContext>
                                  {context === "dashboard"
                                    ? "View confirmation"
                                    : "View offer confirmation"}
                                </KButton.RequireContext>
                              </KLink>
                            );
                          },
                        }
                      : {
                          NextStep() {
                            return (
                              <>
                                Your{" "}
                                {format.date(prevPayment.debitedAt, "Mmmm")}{" "}
                                payment was made successfully! Your next monthly
                                payment will be debited from your bucket on{" "}
                                {format.date(
                                  nextPayment.scheduledDebitAt,
                                  "Mmmm d, yyyy"
                                )}
                                .
                              </>
                            );
                          },
                          Cta() {
                            return (
                              <KLink
                                href={`/dashboard/buckets/${pocket.token}`}
                              >
                                <KButton variant="text-underline" size="hug">
                                  Manage my bucket
                                </KButton>
                              </KLink>
                            );
                          },
                        }),
                    statusText: (
                      <div className="text:small+ color:success">
                        {(() => {
                          if (nextPayment)
                            return (
                              <>
                                {offerType === "paymentPlan" && "Next "}
                                {format.money(nextPayment.paymentCents)} payment
                                on{" "}
                                {format.date(
                                  nextPayment.scheduledDebitAt,
                                  "mm/dd/yyyy"
                                )}
                              </>
                            );
                          return {
                            paymentPlan: "All payments made",
                            lumpSum: "Payment sent to creditor",
                          }[offerType];
                        })()}
                      </div>
                    ),
                  };
                }

                if (status === SettlementStatus.DECLINED)
                  return {
                    latestEvent: DebtSettlementTimelineEvents.FUNDED_BUCKET,
                    priority: Priority.INFO,
                    NextStep() {
                      return <>You declined your offer.</>;
                    },
                    Cta: ArchiveCta,
                    statusText: (
                      <div className="text:small+ color:error">
                        Offer declined
                      </div>
                    ),
                  };
                if (status === SettlementStatus.CANCELLED_BY_USER) {
                  return {
                    latestEvent: DebtSettlementTimelineEvents.ACCEPTED_OFFER,
                    priority: Priority.ACTION_AVAILABLE,
                    NextStep() {
                      return (
                        <>
                          You’ve cancelled an offer for your collection account
                          ••••{accountNumber.slice(-4)}.{" "}
                          {pocket.balanceCents > 0
                            ? "You can choose to request a new offer or withdraw your funds from your bucket."
                            : "You can request a new offer."}
                        </>
                      );
                    },
                    Cta({ context }) {
                      const overlays = useOverlaysController();

                      return (
                        <>
                          <KLink
                            href={`/dashboard/debt-relief/start/${token}/estimate`}
                          >
                            <KButton.RequireContext>
                              Request a new offer
                            </KButton.RequireContext>
                          </KLink>
                          {["account", "home"].includes(context) &&
                            pocket.balanceCents > 0 && (
                              <KButton.RequireContext
                                size="hug"
                                variant="text-underline"
                                onClick={() => {
                                  overlays.push("buckets/one_time_transfer", {
                                    pocketToken: pocket.token,
                                    direction: "withdraw",
                                    defaultAmountCents: pocket.balanceCents,
                                  });
                                }}
                              >
                                Withdraw funds from bucket
                              </KButton.RequireContext>
                            )}
                        </>
                      );
                    },
                    statusText: (
                      <div className="text:small+ color:error">
                        Offer cancelled
                      </div>
                    ),
                  };
                }
                if (status === SettlementStatus.CANCELLED_BY_OWNER)
                  return {
                    latestEvent: DebtSettlementTimelineEvents.ACCEPTED_OFFER,
                    priority: Priority.INFO,
                    statusText: (
                      <div className="text:small+ color:error">
                        Offer cancelled
                      </div>
                    ),
                    NextStep() {
                      return (
                        <>
                          Your collector cancelled the offer due to missing
                          payment.
                        </>
                      );
                    },
                    Cta: ArchiveCta,
                  };

                return defaultState;
              })(),
            ] as const
        )
        .map(([token, state]) => {
          const { NextStep, Cta } = state;

          const wrapWithCommon = <C extends React.FC<any>>(Component: C) =>
            Component &&
            (((props) => (
              <KLink.PropsProvider
                variant="container"
                fit
                replace={false}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Component {...props} />
              </KLink.PropsProvider>
            )) as C);

          return [
            token,
            Object.assign(state, {
              actionAvailable: state.priority >= Priority.ACTION_AVAILABLE,
              NextStep: wrapWithCommon(NextStep),
              Cta: wrapWithCommon(Cta),
            }),
          ] as const;
        })
    );

    return (cache = {
      accounts: stateByAccountToken,
      primary: maxBy(Object.values(stateByAccountToken), "priority"),
    });
  };
})();

type AccountServicingState = ReturnType<
  ReturnType<typeof selectDebtServicingState>
>["accounts"][string];

export const useDebtSettlementEnabled = () => {
  const automatedDebt = useSelector(
    selectFeatureFlag("automated_debt_settlement")
  );
  const lumpSumPilot = useSelector(
    selectFeatureFlag("debt_settlement_lump_sum_pilot")
  );
  const experiment = [
    "payment_plan_only",
    "lump_sum",
    "lump_sum_without_bucket",
  ].includes(useBackendExperiment("debtSettlement"));
  return automatedDebt || lumpSumPilot || experiment;
};

export const useDebtCustomRepayment = (debtAccount: DebtSettlementAccount) => {
  const overlays = useOverlaysController();
  const customRepayment = useSelector(
    selectFeatureFlag("debt_custom_repayment")
  );

  const offer = debtAccount.paymentPlanOffer;
  // HACK: Need to prevent stale offer, requestNumberOfPayments is used in PageFlow
  // buttons that aren't aware of the state this requires
  const offerRef = useRef(offer);
  offerRef.current = offer;

  return {
    async requestNumberOfPayments() {
      const offer = offerRef.current;

      return offer.numberOfPayments <= 2 || !customRepayment
        ? offer.numberOfPayments
        : (
            await overlays.push("debt_settlement/configure_payment_plan", {
              token: debtAccount.token,
            })
          )?.numberOfPayments;
    },
  };
};

export const useDebtIsWithoutBucket = (
  offerType: DebtOfferType = "lumpSum"
) => {
  const isVariant =
    useBackendExperiment("debtSettlement") === "lump_sum_without_bucket";
  const isPilot = useSelector(
    selectFeatureFlag("debt_settlement_lump_sum_pilot")
  );
  return offerType === "lumpSum" && (isVariant || isPilot);
};

export const useDebtIsLumpSum = () => {
  const isVariant = ["lump_sum", "lump_sum_without_bucket"].includes(
    useBackendExperiment("debtSettlement")
  );
  const isPilot = useSelector(
    selectFeatureFlag("debt_settlement_lump_sum_pilot")
  );

  return isVariant || isPilot;
};

type DebtMonetizeVariant =
  | "control"
  | "ten_balance"
  | "twenty_balance"
  | "twenty_savings";

export const useDebtIsMonetize = () => useDebtMonetizeVariant() !== "control";
export const useDebtMonetizeVariant = () =>
  useBackendExperiment("debtMonetizationMultivariant") as DebtMonetizeVariant;

export const useDebtAccountPageGuard = Object.assign(
  (
    token: string,
    predicate: (context: {
      account: web.public_.IDebtSettlementAccount;
      servicingState: AccountServicingState;
    }) => boolean
  ) => {
    const loaded = !!useSelector(selectDebtAccounts());
    const servicingState = useSelector(selectDebtServicingState());
    const account = useSelector(selectDebtAccount(token));

    const allow = useMemo(
      () =>
        account &&
        predicate({
          account,
          servicingState: servicingState.accounts[token],
        }),
      [loaded]
    );

    useEffect(() => {
      if (loaded && !allow) Router.replace("/dashboard/debt-relief");
    }, [loaded]);

    return { isRedirecting: !allow };
  },
  {
    requestOffer: (token: string) =>
      useDebtAccountPageGuard(
        token,
        ({ servicingState, account }) =>
          servicingState.latestEvent ===
            DebtSettlementTimelineEvents.FUNDED_BUCKET ||
          [
            SettlementStatus.OFFER_EXPIRED,
            SettlementStatus.CANCELLED_BY_USER,
          ].includes(account.status)
      ),
    requestedOffer: (token: string) =>
      useDebtAccountPageGuard(token, ({ account }) =>
        // Confirm with julian that status will always be awaiting_request directly after request, make sure this status doesnt change to quickly
        [
          SettlementStatus.AWAITING_REQUEST,
          SettlementStatus.REQUESTED,
        ].includes(account.status)
      ),
    acceptOffer: (token: string) =>
      useDebtAccountPageGuard(
        token,
        ({ servicingState }) =>
          servicingState.stage === DebtSettlementStage.OFFER_ACCEPTABLE
      ),
    acceptedOffer: (token: string) =>
      useDebtAccountPageGuard(
        token,
        ({ servicingState }) =>
          servicingState.latestEvent ===
          DebtSettlementTimelineEvents.ACCEPTED_OFFER
      ),
  }
);

export const fetchDebtSettlementAccounts = Object.assign(
  () =>
    thunk((dispatch) =>
      webRPC.DebtSettlement.getDebtSettlementAccounts({}).then<
        web.public_.IDebtSettlementAccount[]
      >(
        handleProtoStatus({
          async SUCCESS(data) {
            batch(() => {
              dispatch(actions.setAccounts(data.debtSettlementAccounts));
              dispatch(
                updatePockets(
                  Object.fromEntries(
                    data.debtSettlementAccounts
                      .filter(({ pocket }) => pocket)
                      .map(({ pocket }) => [pocket.token, pocket])
                  )
                )
              );
            });

            // TODO: remove this when experiment gets cleaned up, do this because debt accounts are lazy loaded and experiment excludes users without loaded debt accounts
            await dispatch(fetchSiteVars());

            return data.debtSettlementAccounts;
          },
          _DEFAULT: handleFailedStatus(
            "Failed to load debt settlement accounts."
          ),
        })
      )
    ),
  {
    ifNotPresent: Object.assign(
      () =>
        thunk((dispatch, getState) => {
          const { accounts } = getState().debtSettlement;

          return Promise.resolve(
            accounts || dispatch(fetchDebtSettlementAccounts())
          );
        }),
      {
        immediatePopup: () =>
          thunk(async (dispatch, getState) => {
            {
              const { accounts } = getState().debtSettlement;

              if (accounts) return Promise.resolve(accounts);
            }

            const accounts = await dispatch(fetchDebtSettlementAccounts());
            const servicingState = selectDebtServicingState()(getState());

            const takeoverUrl = (() => {
              for (const { token, status, pocket, removedAt } of accounts) {
                if (
                  servicingState.accounts[token].stage ===
                  DebtSettlementStage.OFFER_ACCEPTABLE
                ) {
                  return `/dashboard/debt-relief/offer-ready/${token}/congrats`;
                }
                const showDebtRemovedOverlay = selectDismissable(
                  "DEBT_REMOVED",
                  token
                )(getState());
                if (removedAt && showDebtRemovedOverlay) {
                  handleURL(
                    `kikoff://debt_settlement/removed?debt_account_token=${token}`
                  );
                }
              }
            })();

            if (takeoverUrl) {
              if (isRootWebview)
                nativeDispatch("openWebUrl", {
                  appBarHidden: true,
                  url: takeoverUrl,
                });
              else Router.push(takeoverUrl);
            }

            return Promise.resolve(accounts);
          }),
      }
    ),
  }
);

export const fetchDebtSettlementOffer = (accountToken: string) =>
  thunk((dispatch) =>
    webRPC.DebtSettlement.getSettlementOffers({
      debtSettlementAccountToken: accountToken,
    }).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(fetchDebtSettlementAccounts());

          return data.settlementOffers;
        },
        _DEFAULT: () =>
          handleFailedStatus("Failed to load debt settlement accounts."),
      })
    )
  );

export const getSettlementIntent = (accountToken: string) =>
  thunk((dispatch) =>
    webRPC.DebtSettlement.getSettlementEstimate({
      debtSettlementAccountToken: accountToken,
    }).then<web.public_.ISettlementIntent>(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(fetchDebtSettlementAccounts());
          return data.settlementIntent;
        },
        _DEFAULT: handleFailedStatus("Failed fetching estimates"),
      })
    )
  );

export const setFirstTransferDate = (date: google.protobuf.ITimestamp) =>
  thunk((dispatch) => {
    dispatch(actions.updateFirstTransferDate(date));
  });

export const acceptSettlementOffer = (offerToken: string) =>
  thunk((dispatch) =>
    webRPC.DebtSettlement.acceptSettlementOffer({
      settlementOfferToken: offerToken,
    }).then(
      handleProtoStatus({
        SUCCESS: () => dispatch(fetchDebtSettlementAccounts()),
        _DEFAULT: handleFailedStatus("Failed to accept offer."),
      })
    )
  );

export const cancelSettlementOffer = (
  debtSettlementAccountToken: string,
  cancellationReason?: string
) =>
  thunk((dispatch) =>
    webRPC.DebtSettlement.cancelSettlement({
      debtSettlementAccountToken,
      cancellationReason,
    }).then(
      handleProtoStatus({
        SUCCESS: () => dispatch(fetchDebtSettlementAccounts()),
        _DEFAULT: handleFailedStatus("Failed to cancel offer."),
      })
    )
  );

export const requestSettlementOffer = (debtSettlementAccountToken: string) =>
  thunk((dispatch, getState) =>
    webRPC.DebtSettlement.requestSettlementOffer({
      debtSettlementAccountToken,
    }).then<web.public_.IDebtSettlementAccount>(
      handleProtoStatus({
        SUCCESS: () => dispatch(fetchDebtSettlementAccounts()),
        _DEFAULT: handleFailedStatus("Failed to cancel offer."),
      })
    )
  );

export const acceptSettlementIntent = (
  accountToken: string,
  {
    offerType,
    numberOfPayments,
  }: { offerType: DebtOfferType; numberOfPayments: number }
) =>
  thunk(async (dispatch, getState) => {
    const { token: intentToken } = await dispatch(
      getSettlementIntent(accountToken)
    );

    const debtAccount = selectDebtAccount(accountToken)(getState());

    return webRPC.DebtSettlement.updateSettlementIntent({
      accept: {
        settlementOfferToken: debtAccount[`${offerType}Offer`].token,
        numberOfPayments,
      },
      settlementIntentToken: intentToken,
    }).then(
      handleProtoStatus({
        SUCCESS() {
          track("debt relief: created bucket");
          // pockets aren't always available immediately, ensure pocket exists
          // before continuing
          return (function poll() {
            return dispatch(fetchDebtSettlementAccounts()).then((accounts) => {
              if (!accounts.find(({ token }) => token === accountToken)?.pocket)
                return promiseDelay(100).then(poll);
            });
          })();
        },
        _DEFAULT: handleFailedStatus("Failed to accept settlement estimate."),
      })
    );
  });

export { DebtOfferType };

export const DebtSettlementAccount = {
  accountNumber: {
    format: (account: DebtSettlementAccount) =>
      `••••${account.accountNumber.slice(-4)}`,
  },
  offer: Object.assign(
    (account: DebtSettlementAccount) =>
      account?.[`${DebtSettlementAccount.offer.type(account)}Offer`],
    {
      type: Object.assign(
        (account: DebtSettlementAccount): DebtOfferType => {
          if (!account) return;
          if (account.paymentPlanOffer) {
            if (account.lumpSumOffer) return;
            return "paymentPlan";
          }
          if (account.lumpSumOffer) return "lumpSum";
        },
        {
          selected: Object.assign(
            // Originally selected by user, this is used to check if available
            // offer type is different from original selection
            (account: DebtSettlementAccount) =>
              DebtOfferType.byProtoEnum[account?.selectedOfferType],
            {
              toUrlParam: (account: DebtSettlementAccount) => {
                const selected = DebtSettlementAccount.offer.type.selected(
                  account
                );
                if (!selected) return;
                // Url param will just be the number of payments
                return `${account?.[`${selected}Offer`]?.numberOfPayments}`;
              },
            }
          ),
        }
      ),
      any: (account: DebtSettlementAccount) =>
        account?.paymentPlanOffer || account?.lumpSumOffer,
      didChange(account: DebtSettlementAccount) {
        const selectedType = DebtSettlementAccount.offer.type.selected(account);

        if (!selectedType) return false;

        return selectedType !== DebtSettlementAccount.offer.type(account);
      },
    }
  ),
};

export type SettlementOffer = web.public_.ISettlementOffer;
export const SettlementOffer = {
  balance: (offer: SettlementOffer) => offer.originalBalanceCents,
  savings: (offer: SettlementOffer) =>
    offer.originalBalanceCents - offer.totalCostCents,
  monetizedValues(
    offer: SettlementOffer,
    monetizeVariant: DebtMonetizeVariant
  ) {
    const savings = SettlementOffer.savings(offer);
    const { percent, amount, amountLabel } = monetizeStructure[monetizeVariant];

    const fee =
      monetizeVariant !== "control"
        ? Math.ceil((amount(offer) * (percent / 100)) / 100) * 100
        : 0;

    return {
      fee,
      percent,
      percentOf: amountLabel,
      totalCost: offer.totalCostCents + fee,
      savings: savings - fee,
    };
  },
};

const monetizeStructure = {
  ten_balance: {
    percent: 10,
    amount: SettlementOffer.balance,
    amountLabel: "balance",
  },
  twenty_balance: {
    percent: 20,
    amount: SettlementOffer.balance,
    amountLabel: "balance",
  },
  twenty_savings: {
    percent: 20,
    amount: SettlementOffer.savings,
    amountLabel: "estimated savings",
  },
  control: {
    percent: 0,
    amount: () => 0,
    amountLabel: "",
  },
};
