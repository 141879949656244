import React, { useEffect, useState } from "react";
import { Arrow, useHover, useLayer } from "react-laag";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./Tooltip.module.scss";

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
}

export default function Tooltip({ children, content }: TooltipProps) {
  const [_hovering, hoverProps] = useHover({
    delayEnter: 300,
    delayLeave: 100,
  });

  // TODO(kcirtaptrick): [URGENT] hack to prevent tooltip from appearing in the
  // top left, this needs to be fixed
  const [hovering, setHovering] = useState(false);
  useEffect(() => {
    if (_hovering) {
      setHovering(true);
      requestAnimationFrame(() => {
        setHovering(false);
        requestAnimationFrame(() => {
          setHovering(true);
        });
      });
    } else setHovering(false);
  }, [_hovering]);

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: hovering,
    placement: "top-center",
    auto: true,
    triggerOffset: 8,
  });

  // When children is a text node, we need to wrap it in order to attach props
  const trigger = ["string", "number"].includes(typeof children) ? (
    <span {...triggerProps} {...hoverProps}>
      {children}
    </span>
  ) : (
    React.cloneElement(children as JSX.Element, {
      ...triggerProps,
      ...hoverProps,
    })
  );

  return (
    <>
      {trigger}
      {renderLayer(
        <AnimatePresence>
          {hovering && (
            <motion.div
              className={styles["content-container"]}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              {...layerProps}
            >
              <div className={`text:small ${styles.content}`}>{content}</div>
              <Arrow
                {...arrowProps}
                backgroundColor="black"
                borderWidth={1}
                size={6}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
}

export { useHover };
