import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { msIn } from "@kikoff/utils/src/date";
import { UObject } from "@kikoff/utils/src/object";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoTime,
} from "@kikoff/utils/src/proto";
import { format } from "@kikoff/utils/src/string";

import { AppThunk, RootState } from "@store";
import { sputter } from "@util/analytics";

import { createLoadableSelector, thunk } from "../utils";

import { setCreditLineAccount } from "./credit_line";
import { initDemoFunds } from "./funds";
import { updateLendingState } from "./loans";
import { selectFeatureFlag, updatePageState } from "./page";
import { initDemoShopping } from "./shopping";
import { updateUserState } from "./user";

export type DeliveryStatus =
  | "pending"
  | "fulfillment_failed"
  | "delivery_pending"
  | "success";

const initialState = {
  initialized: false,
  proto: null as web.public_.IGetCreditInformationResponse,
  bureaus: null as web.public_.ITribureauCredit,
  credit: null as web.public_.ICreditV2,
  showIntro: false,
  deliveryStatus: "pending" as DeliveryStatus,
  debtAccounts: null as web.public_.IDebtSettlementAccount[],
  settlementOffers: {} as Record<string, web.public_.ISettlementOffer[]>,
  factorHistories: {} as Record<
    web.public_.GetFactorHistoryRequest.FactorType,
    web.public_.GetFactorHistoryResponse.IValueHistory
  >,
};

export type CreditState = typeof initialState;

const creditSlice = createSlice({
  name: "credit",
  initialState,
  reducers: {
    updateCreditState(state, { payload }: PayloadAction<Partial<CreditState>>) {
      Object.assign(state, payload);
    },
    updateFactorHistory(
      state,
      {
        payload,
      }: PayloadAction<{
        factorType: web.public_.GetFactorHistoryRequest.FactorType;
        valueHistory: web.public_.GetFactorHistoryResponse.IValueHistory;
      }>
    ) {
      state.factorHistories[payload.factorType] = payload.valueHistory;
    },
  },
});

export namespace Bureau {
  const ProtoEnum = web.public_.CreditBureau;
  export const byProtoEnum = {
    [ProtoEnum.EQUIFAX]: "equifax",
    [ProtoEnum.EXPERIAN]: "experian",
    [ProtoEnum.TRANSUNION]: "transunion",
  } as const;
  export const list = Object.values(byProtoEnum) as Bureau[];
}

export type Bureau = UObject.Values<typeof Bureau.byProtoEnum>;

const { actions } = creditSlice;
export const {} = actions;
export default creditSlice.reducer;

export const selectCredit = () => (state: RootState) => state.credit.credit;

export const selectReport = createLoadableSelector(
  (bureau: Bureau) => (state: RootState) => {
    if (bureau === "equifax") {
      const equifax1B = state.credit.credit;
      const equifax3B = state.credit.bureaus?.[bureau];

      return equifax3B &&
        equifax1B.generatedDate &&
        equifax3B.generatedDate &&
        protoTime(equifax3B.generatedDate) > protoTime(equifax1B.generatedDate)
        ? equifax3B
        : equifax1B;
    }
    return state.credit.bureaus?.[bureau];
  },
  {
    loadAction: (_bureau: Bureau) => (dispatch) => dispatch(initCreditV2()),
  }
);

export const selectBureaus = () => (state: RootState) => {
  const { bureaus } = state.credit;
  if (bureaus) {
    return [bureaus.equifax, bureaus.transunion, bureaus.experian];
  }
  return [state.credit.credit];
};

export const selectCreditReportAccount = (
  accountId: string,
  bureau: Bureau
) => (state: RootState) =>
  state.credit.bureaus?.[bureau].accounts.find(({ id }) => accountId === id) ||
  state.credit.credit?.accounts.find(({ id }) => accountId === id);

export const selectTribureauAccount = (accountId: string) => (
  state: RootState
) => {
  const { bureaus } = state.credit;
  const accountMap: { [Key in Bureau]?: web.public_.IAccountV2 } = {};

  if (bureaus)
    for (const [bureau, { accounts }] of Object.entries(bureaus) as Entries<
      typeof bureaus
    >) {
      accountMap[bureau] = accounts.find(({ id }) => id === accountId);
    }

  if (!Object.values(accountMap).some(Boolean))
    accountMap.equifax = state.credit.credit.accounts.find(
      ({ id }) => id === accountId
    );

  return accountMap;
};

export const selectCreditReportGroups = (() => {
  let creditRef = null;
  let bureausRef = null;
  let cachedTribureau = null;
  let cachedOneBureau = null;

  return ({ oneBureau = false } = {}) => (state: RootState) => {
    const isOneBureau = oneBureau || !state.credit.bureaus;
    if (isOneBureau && creditRef === state.credit.credit)
      return cachedOneBureau as never;

    if (!isOneBureau && bureausRef === state.credit.bureaus)
      return cachedTribureau as never;

    if (!state.credit.bureaus && !state.credit.credit)
      return (cachedTribureau = {}) as never;

    const accountsById = Object.entries(
      ((isOneBureau
        ? [state.credit.credit]
        : Object.values(state.credit.bureaus)) as web.public_.ICreditV2[])
        .flatMap(({ accounts }) => accounts)
        .groupBy(({ id }) => id)
    );

    const groups = accountsById.groupBy(([, [{ accountType }]]) => accountType);

    // Ensure other is last entry
    if ("OTHER" in groups) {
      const { OTHER } = groups;
      delete groups.OTHER;
      groups.OTHER = OTHER;
    }

    if (isOneBureau) {
      creditRef = state.credit.credit;
      return (cachedOneBureau = groups);
    }

    bureausRef = state.credit.bureaus;
    return (cachedTribureau = groups);
  };
})();

export const selectCreditFactors = (bureau: Bureau) => (state: RootState) => {
  if (bureau === "equifax") return state.credit.credit?.factors;
  if (state.credit.bureaus) return state.credit.bureaus[bureau].factors;
};

const { FactorType } = web.public_.GetFactorHistoryRequest;

export const selectFactorHistory = createLoadableSelector(
  (factorType: keyof typeof FactorType) => (state: RootState) =>
    state.credit.factorHistories[FactorType[factorType]],
  {
    loadAction: (factorType) => (dispatch, getState) =>
      dispatch(initFactorHistory(factorType)),
  }
);

// Ordered
const factorAttrsByKey = (<
  T extends Record<
    string,
    {
      format: (value: number) => string | number;
      type: web.public_.GetFactorHistoryRequest.FactorType;
      inverse: boolean;
    }
  >
>(
  x: T
) => x)({
  paymentHistory: {
    format: (value) => `${value}%`,
    type: FactorType.PAYMENT_HISTORY,
    inverse: false,
  },
  cardUsage: {
    format: (value) => `${value}%`,
    type: FactorType.CARD_USAGE,
    inverse: true,
  },
  derogatoryMarks: {
    format: (value) => value,
    type: FactorType.DEROGATORY_MARKS,
    inverse: true,
  },
  creditAge: {
    format: (value) => format.v2.duration(value * msIn.month, "%Yyr? %Mmo?"),
    type: FactorType.CREDIT_AGE,
    inverse: false,
  },
  creditMix: {
    format: (value) => value,
    type: FactorType.CREDIT_MIX,
    inverse: false,
  },
  hardInquiries: {
    format: (value) => value,
    type: FactorType.HARD_INQUIRIES,
    inverse: true,
  },
});

export type FactorKey = keyof typeof factorAttrsByKey;
export const factorKeyByType = Object.fromEntries(
  Object.entries(factorAttrsByKey).map(([key, { type }]) => [type, key])
) as Record<web.public_.GetFactorHistoryRequest.FactorType, FactorKey>;

export const factorKeys = Object.keys(factorAttrsByKey) as FactorKey[];

export const CreditFactor = {
  byKey: (factors: web.public_.IFactor[], key: FactorKey) =>
    factors?.find((f) => f[key]),
  key: (factor: web.public_.IFactor) => factorKeys.find((key) => factor[key]),
  formatFor: (factor: web.public_.IFactor, type: "value" | "diff") => {
    const value: number = factor[{ diff: "factorDiff", value: "value" }[type]];

    // return factorAttrsByKey[CreditFactor.key(factor)].format(Math.abs(value)); @no global -
    // @KikOff-Charlie Switch to this if u want to show "-"s globally
    return `${value < 0 ? "-" : ""}${factorAttrsByKey[
      CreditFactor.key(factor)
    ].format(Math.abs(value))}`;
  },
  colorFor(factor: web.public_.IFactor, type: "value" | "diff") {
    const attrs = factorAttrsByKey[CreditFactor.key(factor)];

    return {
      [-1]: "var(--error)",
      1: "var(--success)",
    }[
      Math.sign(factor[{ diff: "factorDiff", value: "value" }[type]]) *
        (attrs.inverse ? -1 : 1)
    ];
  },
};

export const selectCreditFactor = Object.fromEntries(
  factorKeys.map((field) => [
    field,
    (bureau) => (state: RootState) =>
      selectCreditFactors(bureau)(state)?.find((factor) => factor[field]),
  ])
) as {
  [Field in FactorKey]: (
    bureau: Bureau
  ) => (
    state: RootState
  ) => Omit<web.public_.IFactor, Exclude<FactorKey, Field>>;
};

export type Grade = keyof typeof web.public_.Factor.Grade;

export const selectShowCreditPageV2 = () => (state: RootState) => {
  return (
    !!state.creditLine.account ||
    state.user.proto.minimumDepositMadeToSc ||
    state.user.proto.accountType.type ===
      web.public_.AccountType.Type.FREEMIUM_SIGNUP ||
    selectFeatureFlag("credit_disputes")(state)
  );
};

export const selectDebtSettlementOfferBy = <
  Field extends keyof web.public_.ISettlementOffer
>(
  debtAccountToken: string,
  field: Field,
  match: web.public_.ISettlementOffer[Field] | boolean
) => (state: RootState) =>
  state.credit.settlementOffers[debtAccountToken]?.find((offer) =>
    typeof match === "boolean"
      ? !!offer[field] === match
      : offer[field] === match
  );
export const selectDebtSettlementOfferByStatus = (
  debtAccountToken: string,
  status: keyof typeof web.public_.SettlementOffer.SettlementOfferStatus
) => (state: RootState) =>
  state.credit.settlementOffers[debtAccountToken]?.find(
    (offer) =>
      offer.status === web.public_.SettlementOffer.SettlementOfferStatus[status]
  );

export const initDemo = ({
  GetCredit,
  GetInitPage,
  GetSiteVars,
  GetUserInfo,
  ListOrders,
  GetPaymentMethods,
  CreditLineAccount,
  UpdateLendingState,
}): AppThunk => (dispatch) => {
  dispatch(
    updatePageState({
      featureFlags: Object.fromEntries(
        GetSiteVars.featureFlags.map((item) => [item.name, item.pass])
      ),
    })
  );

  dispatch(
    updateUserState({
      proto: GetUserInfo.user,
      authenticated: true,
    })
  );

  dispatch(
    actions.updateCreditState({
      credit: GetCredit.credit,
      showIntro: true,
      initialized: true,
    })
  );

  dispatch(
    initDemoShopping({
      orders: ListOrders.orders,
      userSubscriptions: ListOrders.userSubscriptions,
    })
  );

  dispatch(
    initDemoFunds({
      paymentMethods: GetPaymentMethods.paymentMethods,
    })
  );

  dispatch(setCreditLineAccount(CreditLineAccount));
  dispatch(updateLendingState(UpdateLendingState));
};

export const initCreditV2 = Object.assign(
  () =>
    thunk((dispatch) =>
      webRPC.Credit.getCredit({}).then(
        handleProtoStatus({
          SUCCESS(data) {
            sputter("credit: load data", {
              "# of problem accounts": data.credit.problemAccounts.length,
              "# of score events": data.credit.scoreEvents.length,
              "# of historic scores":
                data.credit.creditScoreHistory.creditScores.length,
            });
            dispatch(
              actions.updateCreditState({
                credit: data.credit,
                showIntro: data.showIntro,
                initialized: true,
                deliveryStatus: "success",
              })
            );
          },
          _DEFAULT: handleFailedStatus("Failed to load credit information."),
        })
      )
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const { credit } = getState().credit;

        return Promise.resolve(credit || dispatch(initCreditV2()));
      }),
  }
);

export const initDebtSettlementOffers = (
  accountId: string
): AppThunk<Promise<void>> => (dispatch, getState) => {
  const settlementOffers = getState().credit.settlementOffers;
  return webRPC.DebtSettlement.getSettlementOffers({
    debtSettlementAccountToken: accountId,
  }).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(
          actions.updateCreditState({
            settlementOffers: {
              ...settlementOffers,
              [accountId]: data.settlementOffers,
            },
          })
        );
      },
      _DEFAULT: () =>
        handleFailedStatus("Failed to load debt settlement accounts."),
    })
  );
};

export const initTribureauCreditV2 = (): AppThunk<Promise<void>> => (
  dispatch
) => {
  return webRPC.Credit.getCreditTribureau({}).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(
          actions.updateCreditState({
            bureaus: data.creditTribureau,
            showIntro: data.showIntro,
            initialized: true,
            deliveryStatus: "success",
          })
        );
      },
      DELIVERY_PENDING: () => {
        dispatch(
          actions.updateCreditState({
            pendingDelivery: true,
            deliveryStatus: "delivery_pending",
          })
        );
      },
      FULFILLMENT_FAILED: () => {
        dispatch(
          actions.updateCreditState({
            deliveryStatus: "fulfillment_failed",
          })
        );
      },
      _DEFAULT: handleFailedStatus("Failed to load credit information."),
    })
  );
};

export const initFactorHistory = (
  factorType: keyof typeof FactorType
): AppThunk<Promise<void>> => (dispatch) => {
  return webRPC.Credit.getFactorHistory({
    factorType: FactorType[factorType],
  }).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(
          actions.updateFactorHistory({
            factorType: FactorType[factorType],
            valueHistory: data.valueHistory,
          })
        );
      },
      _DEFAULT: handleFailedStatus("Failed to load factor history."),
    })
  );
};

export const activateEnrollment = (): AppThunk<
  Promise<web.public_.ActivateEnrollmentResponse.EnrollmentStatus>
> => () =>
  webRPC.Credit.activateEnrollment({}).then(
    handleProtoStatus({
      SUCCESS(data) {
        return data.enrollmentStatus;
      },
      _DEFAULT: handleFailedStatus("Failed to load enrollment status."),
    })
  );

export const creditReportAccountStatusData = (() => {
  const { Value } = web.public_.AccountV2.PaymentInfo;
  const missedPayment = {
    color: "var(--inverse)",
    label: "Missed payment",
  };
  const current = {
    color: "var(--primary)",
    label: "On time",
  };
  return {
    [Value.OTHER]: {
      color: "var(--moderate-weak)",
      label: "Other",
    },
    [Value.PAYS_AS_AGREED]: current,
    [Value.ZERO_BAL_AND_CURR_ACCT]: current,
    [Value.UNAVAILABLE]: {
      color: "none",
      label: null,
    },
    [Value.DAYSLATE_30]: {
      color: "var(--yellow-600)",
      label: "30+ days late",
    },
    [Value.LATE_30_DAYS]: {
      color: "var(--yellow-600)",
      label: "30+ days late",
    },
    [Value.DAYSLATE_60]: {
      color: "var(--red-500)",
      label: "60+ days late",
    },
    [Value.LATE_60_DAYS]: {
      color: "var(--red-500)",
      label: "60+ days late",
    },
    [Value.DAYSLATE_90]: {
      color: "var(--red-700)",
      label: "90+ days late",
    },
    [Value.LATE_90_DAYS]: {
      color: "var(--red-700)",
      label: "90+ days late",
    },
    [Value.DAYSLATE_120]: {
      color: "var(--red-900)",
      label: "120+ days late",
    },
    [Value.OVER_120_DAYS_PAST_DUE]: {
      color: "var(--red-900)",
      label: "120+ days late",
    },
    [Value.CHARGE_OFF]: {
      color: "var(--inverse)",
      label: "Charged off",
    },
    [Value.COLLECTION]: {
      color: "var(--inverse)",
      label: "Collection",
    },
    [Value.REPOSSESSION]: {
      color: "var(--inverse)",
      label: "Repossession",
    },
    [Value.NOT_REPORTED]: {
      color: "var(--moderate-weak)",
      label: "Not reported",
    },
    [Value.COLLECTION_CHARGEOFF]: missedPayment,
  };
})();
