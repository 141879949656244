import { addMonths } from "date-fns";

import { protoDate } from "@kikoff/utils/src/proto";
import { format } from "@kikoff/utils/src/string";

import { web } from "../../../protos";
import createPocket from "../banking/Pocket";
import createPocketTransaction from "../banking/PocketTransaction";
import createTimestamp from "../google/Timestamp";

import createSettlementOffer, {
  offerStatusByDebtAccountStatus,
  settlementProgression,
  SettlementSchedule,
} from "./SettlementOffer";

const { SettlementStatus } = web.public_.DebtSettlementAccount;

export default function createDebtSettlementAccount(
  token: string,
  status: keyof typeof SettlementStatus,
  {
    name = "Debt name",
    offerType = "paymentPlan",
    debtOwner = {
      name: "Portfolio Recovery Associates",
      token: "PRA",
    },
    accountNumber = "******** 1234",
    balanceCents = 500_00,
    originalBalanceCents = balanceCents,
    estimatedSavingsCents = Math.round(
      balanceCents * { paymentPlan: 0.5, lumpSum: 0.55 }[offerType]
    ),
    details = {
      Balance: format.money(balanceCents),
      "Account status": "Derogatory",
      "Collection agency": debtOwner.name,
      "Debt type": "Sold debt",
      "Pay status": "Collection chargeoff",
      "Last reported": format.date(new Date(), "m/d/yyyy"),
    },
    requestedAt = settlementProgression[status] >=
    settlementProgression.REQUESTED
      ? createTimestamp(new Date())
      : null,
    settlementLetterLink = "/terms.pdf",
    pocketState = "unfunded",
    pocket,
    paidCount = 0,
    removedAt,
    highestNumberOfPayments = 18,
  }: Omit<
    web.public_.IDebtSettlementAccount,
    | "status"
    | "token"
    // Tedious to set this up, no need for it yet, but will need to set up
    // properly if we do in the future
    | "paymentPlanOffer"
    | "lumpSumOffer"
  > & {
    pocketState?: "unfunded" | "pending" | "funded" | "partially-funded";
    paidCount?: number;
    highestNumberOfPayments?: number;
    offerType?: DebtOfferType;
  } = {}
) {
  const offerAmountCents = balanceCents - estimatedSavingsCents;

  const noOffer = (["UNPAID", "BLOCKED"] as typeof status[]).includes(status);
  const requestable = ([
    "ESTIMATE",
    "CANCELLED_BY_USER",
    "CANCELLED_BY_OWNER",
    "OFFER_EXPIRED",
  ] as typeof status[]).includes(status);

  const createOffer = (type: DebtOfferType) =>
    // Make both offers available when an offer is requestable to allow users to
    // select their plan
    !noOffer && (offerType === type || requestable)
      ? createSettlementOffer(
          `${token}:${type}Offer`,
          offerStatusByDebtAccountStatus[status],
          {
            debtSettlementAccountToken: token,
            originalBalanceCents: balanceCents,
            totalCostCents: offerAmountCents,
            numberOfPayments: {
              paymentPlan: highestNumberOfPayments,
              lumpSum: 1,
            }[type],
            paymentAmountCents: Math.ceil(
              offerAmountCents / highestNumberOfPayments
            ),
            paidCount,
          }
        )
      : null;

  const paymentPlanOffer = createOffer("paymentPlan");

  const lumpSumOffer = createOffer("lumpSum");

  const schedule = (paymentPlanOffer || lumpSumOffer)?.settlementSchedule;

  const firstPayment = schedule?.[0];
  const nextPayment = SettlementSchedule.nextPayment(schedule);
  const prevPayment = SettlementSchedule.prevPayment(schedule);

  const goalAmountCents = Math.ceil(nextPayment?.paymentCents / 100) * 100 || 0;

  return web.public_.DebtSettlementAccount.create({
    token,
    status: SettlementStatus[status],
    name,
    debtOwner,
    accountNumber,
    balanceCents,
    originalBalanceCents,
    estimatedSavingsCents,
    details,
    requestedAt,
    settlementLetterLink,
    pocket:
      status === "UNPAID"
        ? null
        : Object.assign(
            createPocket(`pocket-for-debt:${token}`, "ACTIVE", {
              description: "Debt Bucket",
              goalAmountCents,
              balanceCents:
                {
                  funded: goalAmountCents,
                  "partially-funded": Math.ceil(goalAmountCents / 10),
                }[pocketState] || 0,
              pendingAmountCents:
                pocketState === "pending" ? goalAmountCents : 0,
              createdAt: createTimestamp(
                addMonths(protoDate(firstPayment.statementStartAt), -1)
              ),
              fundingCompletedAt: (() => {
                if (pocketState === "funded")
                  return createTimestamp(new Date());
                if (firstPayment.debitedAt)
                  return firstPayment.scheduledTransferAt;
              })(),
              lastWithdrawAt: prevPayment?.debitedAt,
              targetDate: nextPayment?.scheduledTransferAt,
              linkedAccounts: [
                {
                  type:
                    web.public_.Pocket.LinkedAccount.Type
                      .DEBT_SETTLEMENT_ACCOUNT,
                  token,
                },
              ],
              type: web.public_.Pocket.Type.DEBT_SETTLEMENT,
              transferSchedules: nextPayment
                ? [
                    {
                      nextTransferAt: nextPayment?.scheduledTransferAt,
                      fixedAmountCents: goalAmountCents,
                    },
                  ]
                : [],
              transactions: [
                ...({
                  pending: [
                    createPocketTransaction(
                      `${token}:transaction-pending`,
                      "PENDING",
                      {
                        amountCents: goalAmountCents,
                        description: "Transfer from User Bank to Debt Bucket",
                        transactedAt: createTimestamp(new Date()),
                      }
                    ),
                  ],
                  funded: prevPayment
                    ? []
                    : [
                        createPocketTransaction(
                          `${token}:transaction-0`,
                          "COMPLETED",
                          {
                            amountCents: nextPayment?.paymentCents,
                            description:
                              "Transfer from User Bank to Debt Bucket",
                            transactedAt: createTimestamp(new Date()),
                          }
                        ),
                      ],
                }[pocketState] || []),
                ...schedule
                  .flatMap(
                    (
                      {
                        scheduledTransferAt,
                        statementEndAt: dueAt,
                        paymentCents,
                        debitedAt,
                      },
                      i
                    ) =>
                      debitedAt
                        ? [
                            createPocketTransaction(
                              `${token}:transaction-credit-${i}`,
                              "COMPLETED",
                              {
                                amountCents: paymentCents,
                                description:
                                  "Transfer from User Bank to Debt Bucket",
                                transactedAt: scheduledTransferAt,
                              }
                            ),
                            createPocketTransaction(
                              `${token}:transaction-debit-${i}`,
                              "COMPLETED",
                              {
                                amountCents: -paymentCents,
                                description: "Transfer to PRA debt collection",
                                transactedAt: dueAt,
                              }
                            ),
                          ]
                        : []
                  )
                  .reverse(),
              ],
            }),
            pocket
          ),
    paymentPlanOffer,
    lumpSumOffer,
    communicationAuthLink:
      "https://kikoff.com/kikoff-communication-authorization-pra.pdf",

    paymentAuthLink: "https://kikoff.com/fake-payment-auth-link.pdf",
    removedAt,
  });
}

export type DebtOfferType = "paymentPlan" | "lumpSum";

export namespace DebtOfferType {
  export const fromUrlParam = (param: string): DebtOfferType =>
    // Support kebab case for backwards compatibility, offer type will
    // now be represented with number of payments
    ({ "payment-plan": "paymentPlan", "lump-sum": "lumpSum" }[param] ||
    Number.parseInt(param, 10) > 1
      ? "paymentPlan"
      : "lumpSum");
  const { OfferType } = web.public_.DebtSettlementAccount;
  export const byProtoEnum = {
    [OfferType.PAYMENT_PLAN]: "paymentPlan",
    [OfferType.LUMP_SUM]: "lumpSum",
  } as Record<web.public_.DebtSettlementAccount.OfferType, DebtOfferType>;

  export const toHumanReadable = Object.assign(
    (type: DebtOfferType) =>
      ({ paymentPlan: "payment plan", lumpSum: "lump sum" }[type]),
    {
      verbose: (type: DebtOfferType) =>
        ({ paymentPlan: "monthly payment plan", lumpSum: "one-time payment" }[
          type
        ]),
    }
  );
}
