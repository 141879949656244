import Router from "next/router";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { setAll } from "@kikoff/utils/src/object";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { getVariant } from "@src/experiments/context";
import { AppThunk } from "@store";

import { RootState } from "../store";

const initialState = {
  currentStep: 0,
  completedSteps: 0,
  stepMap: null as web.public_.OnboardingStatus.IStep[],
  signupType: null as web.public_.OnboardingStatus.SignupType,
  completedAt: null as google.protobuf.ITimestamp | null,
  completionStatus: null as web.public_.OnboardingStatus.CompletionStatus,
  duplicateAccountDetails: null as web.public_.OnboardingStatus.IDuplicateAccountDetails | null,
};

export type OnboardingState = typeof initialState;

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    updateOnboardingState(
      state,
      { payload }: PayloadAction<Partial<OnboardingState>>
    ) {
      Object.assign(state, payload);
    },
    setCurrentStep(
      state,
      { payload }: PayloadAction<OnboardingState["currentStep"]>
    ) {
      state.currentStep = payload;
    },
    setCompletedSteps(
      state,
      { payload }: PayloadAction<OnboardingState["completedSteps"]>
    ) {
      state.completedSteps = payload;
    },
    setOnboardingCompletedAt(
      state,
      { payload }: PayloadAction<OnboardingState["completedAt"]>
    ) {
      state.completedAt = payload;
    },
    setCompletionStatus(
      state,
      { payload }: PayloadAction<OnboardingState["completionStatus"]>
    ) {
      state.completionStatus = payload;
    },
    setStepMap(state, { payload }: PayloadAction<OnboardingState["stepMap"]>) {
      state.stepMap = payload;
    },
    setSignupType(
      state,
      { payload }: PayloadAction<OnboardingState["signupType"]>
    ) {
      state.signupType = payload;
    },
  },
});
const { actions } = onboardingSlice;
export const {
  updateOnboardingState,
  setCompletedSteps,
  setCurrentStep,
  setStepMap,
  setSignupType,
  setOnboardingCompletedAt,
} = actions;
export default onboardingSlice.reducer;

const stepNameDataMap = {
  name: "updateNameRequest",
  phone: "updatePhoneRequest",
  dob: "updateDobRequest",
  address: "updateAddressRequest",
  ssn: "updateSsnRequest",
} as const;

type IStep = web.public_.OnboardingStatus.IStep;
export const getStepData = (step: IStep) => step[stepNameDataMap[step.name]];

export type StepName = keyof typeof stepNameDataMap;

export type DataFromStepName<
  Name extends StepName
> = IStep[typeof stepNameDataMap[Name]];

export const selectStepData = <Name extends StepName>(stepName: Name) => (
  state: RootState
) =>
  state.onboarding.stepMap.find(({ name }) => name === stepName)[
    stepNameDataMap[stepName]
  ] as DataFromStepName<Name>;

export const selectFirstIncompleteOnboardingStepIndex = () => (
  state: RootState
) => {
  const { stepMap } = state.onboarding;
  if (!stepMap) return 0;
  let firstIncomplete = stepMap.findIndex((step) => !step.complete);
  if (firstIncomplete === -1) firstIncomplete = stepMap.length;
  return firstIncomplete;
};

export const selectNextOnboardingRoute = () => (state: RootState) => {
  const userHasNotConfirmedEmail = !state.user.proto?.info.emailConfirmed;

  if (userHasNotConfirmedEmail) {
    return "/onboarding/verify-email";
  }

  return "/onboarding/education";
};

export const selectIsAccessibleOnboardingRoute = (route: string) => (
  state: RootState
) => {
  const rootPath = "/onboarding";
  if (!route.startsWith(rootPath)) return false;

  const subRoute = route.slice(rootPath.length);

  const onboardingV2 = getVariant("onboarding")?.startsWith("v2");

  if (!onboardingV2) return subRoute === "/info";

  if (subRoute === "/email-confirmed") return true;

  const firstIncomplete = selectFirstIncompleteOnboardingStepIndex()(state);

  const { stepMap } = state.onboarding;

  const currentIndex =
    stepMap?.findIndex(({ name }) => `/${name}` === subRoute) ?? -1;
  return currentIndex !== -1 && currentIndex <= firstIncomplete;
};

export const getPersonaVerificationLink = () => {
  return webRPC.Onboarding.getPersonaVerificationLink({}).then(
    handleProtoStatus({
      SUCCESS(data) {
        return data.personaVerificationLink;
      },
      _DEFAULT: handleFailedStatus("Failed to get persona verification link."),
    })
  );
};

interface InitOnboardingOptions {
  callback?(): void;
  delay?: number;
  beforeRouteChange?(): void;
}
export const initOnboarding = ({
  callback,
  delay,
  beforeRouteChange,
}: InitOnboardingOptions = {}): AppThunk<
  Promise<web.public_.IGetOnboardingStatusResponse>
> => (dispatch, getState) => {
  function goto(route: string) {
    if (window.location.pathname === route) return;
    beforeRouteChange?.();
    Router.replace(route);
  }
  return webRPC.Onboarding.getOnboardingStatus({}).then((res) => {
    dispatch(setSignupType(res.status.signupType));
    dispatch(setOnboardingCompletedAt(res.status.completedAt));
    dispatch(actions.setCompletionStatus(res.status.completionStatus));
    const status =
      web.public_.OnboardingStatus.CompletionStatus[
        res.status.completionStatus
      ];

    if (!getState().user.proto?.info.emailConfirmed) {
      goto("/onboarding/verify-email");
      return res;
    }

    if (res.status.completionStatus) {
      setTimeout(() =>
        goto(
          {
            WAITLIST_UNDERAGE: "/onboarding/waitlist/age",
            WAITLIST_STATE: "/onboarding/waitlist/state",
            ...setAll(
              [
                "RISK_CHECKING",
                "RISK_PASSED",
                "RISK_RETRY",
                "PROGRAMS_FETCHING",
                "PROGRAMS_FETCHED",
                "FROZEN_FILE",
                "ADDRESS_ISSUE",
              ],
              "/onboarding/post/identity-verification"
            ),
            RISK_FLAGGED: "/onboarding/flagged",
            DUPLICATE_ACCOUNT: "/onboarding/duplicate",
            ELIGIBLE_FOR_MANUAL_UPLOAD: "/onboarding/manual-review",
          }[status]
        )
      );
      return res;
    }

    const stepMap = res.status.steps;
    let firstIncomplete = stepMap.findIndex((step) => !step.complete);
    if (firstIncomplete === -1) firstIncomplete = stepMap.length;

    dispatch(
      updateOnboardingState({
        stepMap: [...stepMap, { name: "review" }],
        currentStep: firstIncomplete,
        completedSteps: firstIncomplete,
        duplicateAccountDetails: res.status.duplicateAccountDetails,
      })
    );
    callback?.();

    const state = getState();

    if (!selectIsAccessibleOnboardingRoute(window.location.pathname)(state))
      setTimeout(() => {
        goto(selectNextOnboardingRoute()(getState()));
      }, delay);
    return res;
  });
};

export const updateDuplicateAccountDetails = (): AppThunk => (dispatch) => {
  return webRPC.Onboarding.getOnboardingStatus({}).then((res) => {
    dispatch(
      updateOnboardingState({
        duplicateAccountDetails: res.status.duplicateAccountDetails,
      })
    );
  });
};

const { SignupType } = web.public_.OnboardingStatus;

export const routeBySignupType = {
  [SignupType.CREDIT]: "/dashboard/store",
  [SignupType.FREEMIUM]: "/dashboard/credit-score",
  [SignupType.CASH_CARD]: "/dashboard/offer/cash-card-onboarding/address",
};
