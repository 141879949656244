import React, { useState } from "react";
import useMeasure from "react-use-measure";
import { AnimatePresence, motion } from "framer-motion";
import Fade from "@app/../../packages/components/src/v1/animations/Fade";
import { LogoIcon } from "@app/../../packages/components/src/v1/assets/Logo";
import { chunkBy } from "@app/../../packages/utils/src/array";
import { pick } from "@app/../../packages/utils/src/object";
import { combineClasses } from "@app/../../packages/utils/src/string";

import styles from "./Messages.module.scss";

declare namespace Messages {
  interface Props {
    messages: IMessage[];
  }
}

export default Messages;
function Messages({ messages }: Messages.Props) {
  if (messages.length === 0) return null;

  const messageGroups = chunkBy(
    messages,
    (prev, next) => prev.sender !== next.sender
  );

  return (
    <div className={styles.messages}>
      <AnimatePresence>
        {messageGroups.map((group, i) => (
          <div className={styles.group} key={i}>
            {group[0].sender === "bot" && (
              <div className={styles.avatar}>
                <LogoIcon />
              </div>
            )}
            <div className={styles["group-messages"]}>
              {group.map((message, j) => (
                <Message
                  message={message}
                  isLast={message === group.at(-1)}
                  key={j}
                />
              ))}
            </div>
          </div>
        ))}
      </AnimatePresence>
    </div>
  );
}

declare namespace Message {
  interface Props {
    message: IMessage;
    isLast: boolean;
  }
}

function Message({ message, isLast }: Message.Props) {
  const { sender, content, actions } = message;

  const loading = content === MessageContent.TYPING;

  const [ref, { height, width }] = useMeasure();
  const [loadedRect, setLoadedRect] = useState<DOMRect>();
  const [done, setDone] = useState(!loading);

  return (
    <motion.div
      className={`${styles.message} ${
        sender === "user" ? styles.sent : styles.received
      }`}
      initial={{ height: 0, opacity: -0.5 }}
      animate={{
        height: null,
        opacity: 1,
        padding: `2px 0`,
      }}
    >
      {!loading && !loadedRect && !done && (
        <div className={`${styles.bubble} ${styles.measure}`}>
          <div
            ref={(el) => {
              if (el) setLoadedRect(el.getBoundingClientRect());
            }}
          >
            {content}
          </div>
        </div>
      )}
      <div className={combineClasses(styles.bubble, isLast && styles.last)}>
        {done ? (
          content
        ) : (
          <motion.div
            animate={{ width, height }}
            onAnimationComplete={() => {
              if (loadedRect) {
                setLoadedRect(null);
                setDone(true);
              }
            }}
          >
            <div
              ref={ref}
              className={styles.content}
              style={loadedRect ? pick(loadedRect, ["width", "height"]) : {}}
            >
              <AnimatePresence>
                {loading ? (
                  <Fade key="loading">
                    <LoadingIndicator />
                  </Fade>
                ) : (
                  <Fade key="content">{content}</Fade>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </div>
      {typeof actions === "function" ? actions(message) : actions}
    </motion.div>
  );
}

function LoadingIndicator() {
  return (
    <div className={styles["loading-indicator"]}>
      <div />
      <div />
      <div />
    </div>
  );
}

enum MessageContent {
  TYPING,
  START,
}

type Content =
  | {
      content: string | MessageContent;
    }
  | {
      content: React.ReactNode;
      textContent: string;
    };

export type IMessage = {
  sender: "user" | "bot";
  id?: string;
  actions?: React.ReactNode | ((message: IMessage) => React.ReactNode);
} & Content;
