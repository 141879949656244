import { countBy, sumBy } from "lodash-es";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { msIn } from "@kikoff/utils/src/date";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { useBackendExperiment } from "@src/experiments/context";
import { AppThunk, RootState } from "@store";
import analytics from "@util/analytics";

import { createLoadableSelector } from "../utils";

import { fetchUser } from "./user";

const initialState = {
  open: [] as Loan[],
  closed: [] as Loan[],
  offer: null as Loan.Offer[],
};

export type LendingState = typeof initialState;

const lendingSlice = createSlice({
  name: "lending",
  initialState,
  reducers: {
    updateLendingState(
      state,
      { payload }: PayloadAction<Partial<LendingState>>
    ) {
      Object.assign(state, payload);
    },
  },
});

export const { updateLendingState } = lendingSlice.actions;
export default lendingSlice.reducer;

declare namespace selectLoanBy {
  export interface Options {
    includeClosed?: boolean;
  }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const selectLoanBy = <Prop extends keyof Loan>(
  field: Prop,
  match: Loan[Prop],
  { includeClosed = false }: selectLoanBy.Options = {}
) => (state: RootState) =>
  [
    ...(state.loans.open || []),
    ...((includeClosed && state.loans.closed) || []),
  ]
    .sort((a, b) => b.openedAt.seconds - a.openedAt.seconds)
    .find((loan) => loan[field] === match);

export { selectLoanBy };

export const selectCbsLoan = (options: selectLoanBy.Options = {}) => {
  const cblRedesignVariant = useBackendExperiment("cblRedesign");
  const isCblPremium = cblRedesignVariant !== "control";
  return selectLoanBy(
    "loanType",
    isCblPremium
      ? web.public_.Loan.LoanType.PREMIUM
      : web.public_.Loan.LoanType.CREDIT_BUILDER_SAVING,
    options
  );
};

export const selectCbsOffer = createLoadableSelector(
  () => (state: RootState) =>
    state.loans.offer?.find(
      ({ loanType }) =>
        loanType === web.public_.Loan.LoanType.CREDIT_BUILDER_SAVING
    ),
  { loadAction: () => updateEligibleLoans() }
);

export const selectUnsecuredPersonalOffer = createLoadableSelector(
  () => (state: RootState) =>
    state.loans.offer?.find(
      ({ loanType }) =>
        loanType === web.public_.Loan.LoanType.UNSECURED_PERSONAL
    ),
  { loadAction: () => updateEligibleLoans() }
);

export const selectUnsecuredPersonalLoan = (
  options: selectLoanBy.Options = {}
) =>
  selectLoanBy(
    "loanType",
    web.public_.Loan.LoanType.UNSECURED_PERSONAL,
    options
  );
export const selectLoan = (loanToken: string) => (state: RootState) =>
  [...(state.loans.open || []), ...(state.loans.closed || [])].find(
    (loan) => loan.token === loanToken
  );

export const selectLoanOrOffer = (tokenOrIdentifier: string) => (
  state: RootState
) =>
  [
    ...(state.loans.open || []),
    ...(state.loans.closed || []),
    ...(state.loans.offer || []),
  ].find(
    (loan) =>
      ("token" in loan
        ? loan.token
        : (loan as web.public_.ILoanOffer).identifier) === tokenOrIdentifier
  );

export const updateLoans = (
  openLoans: web.public_.ILoan[],
  closedLoans: web.public_.ILoan[]
): AppThunk => (dispatch) => {
  dispatch(updateLendingState({ closed: closedLoans, open: openLoans }));
};

export const updateEligibleLoans = (): AppThunk<
  Promise<web.public_.ILoanOffer[]>
> => (dispatch) => {
  return webRPC.Lending.getEligibleLoans({}).then((data) => {
    dispatch(updateLendingState({ offer: data.loanOffers }));
    return data.loanOffers;
  });
};

export const payLoanInstallment = (
  request: Required<web.public_.IMakePaymentRequest>
): AppThunk<Promise<void>> => () => {
  return webRPC.Lending.makePayment(request).then(
    handleProtoStatus({
      SUCCESS() {
        analytics.convert("Make Loan Payment");
      },
      _DEFAULT: handleFailedStatus("Failed to pay loan installment"),
    })
  );
};

export const closeLoan = ({
  loanToken,
  closureReason,
}): AppThunk<Promise<void>> => (dispatch) =>
  webRPC.Lending.closeLoan({
    loanToken,
    closureReason,
  }).then(
    handleProtoStatus({
      SUCCESS() {
        dispatch(fetchUser.loans());
      },
      _DEFAULT: handleFailedStatus("Failed to close. Please contact support."),
    })
  );

export type Loan = web.public_.ILoan;
export namespace Loan {
  export const totalCost = (loan: Loan) =>
    loan.duration * loan.monthlyPaymentCents;
  export const storedSavingsCents = (loan: Loan) =>
    loan.closedAt
      ? loan.savingsVoucher?.valueCents
      : Math.max(
          sumBy(loan.loanInstallments, ({ paidAmountCents }) =>
            Math.max(paidAmountCents - loan.monthlyFeeCents, 0)
          ),
          0
        );
  export const installments = {
    paidCount: (loan: Loan) => countBy(loan.loanInstallments, "paid"),
    adminFee: (loan: Loan) =>
      loan.loanType === web.public_.Loan.LoanType.PREMIUM &&
      !countBy(loan.loanInstallments, "paid").true
        ? loan.adminFeeCents
        : 0,
  };

  export type Offer = web.public_.ILoanOffer;
  export namespace Offer {
    export const totalCost = (offer: Offer) =>
      offer.duration * offer.monthlyPaymentCents;
    export const totalFees = (offer: Offer) =>
      offer.monthlyFeeCents * offer.duration + offer.adminFeeCents;
  }
}

export namespace PersonalLoan {
  export const amountCentsRange = (() => {
    // temprarily set the lower end of the range to 600, legal min is really 501 but weird to show that
    const low = 600_00;
    const high = 3000_00;
    return Object.assign([low, high] as const, { low, high });
  })();

  export const disbursementComplete = (loan: Loan) =>
    Date.now() > loan.openedAt.seconds * msIn.second + 5 * msIn.day;
}
