import createExperiment from "./createExperiment";

export default createExperiment({
  variants: {
    control: {},
    show: {},
  },
}).weigh({
  control: 100,
  show: 0,
});
