import { createSelector, Select } from "./object";

export function chunkArray<T>(array: T[], size: number): T[][] {
  const res = [];
  for (let i = 0; i < array.length; i += size) {
    res.push(array.slice(i, i + size));
  }
  return res;
}

export function chunkBy<T>(
  array: T[],
  predicate: (prev: T, next: T) => unknown
): T[][] {
  const res = [[array[0]]];
  for (const item of array.slice(1)) {
    if (predicate(res.at(-1).at(-1), item)) res.push([]);
    res.at(-1).push(item);
  }
  return res;
}

export function partition<T>(
  array: T[],
  check: (item: T) => boolean
): [T[], T[]] {
  return array.reduce(
    ([pass, fail], curr) =>
      check(curr) ? [[...pass, curr], fail] : [pass, [...fail, curr]],
    [[], []]
  );
}

export const hasLength = (
  mightHaveLength: any
): mightHaveLength is {
  length: number;
} =>
  typeof mightHaveLength === "object" &&
  "length" in mightHaveLength &&
  typeof mightHaveLength.length === "number";

export const isSlicable = (
  maybeSlicable: any
): maybeSlicable is {
  slice: any[]["slice"];
} =>
  typeof maybeSlicable === "object" &&
  "slice" in maybeSlicable &&
  typeof maybeSlicable.slice === "function";

export const isMappable = (
  maybeMappable: any
): maybeMappable is {
  map: any[]["map"];
} =>
  typeof maybeMappable === "object" &&
  "map" in maybeMappable &&
  typeof maybeMappable.map === "function";

export const dedupeBy = <T>(array: T[], select: Select<T>) => {
  const selector = createSelector(select);
  const set = new Set();
  return array.filter((item) => {
    const res = selector(item as any);
    if (set.has(res)) return;
    set.add(res);
    return true;
  });
};
