import React, {
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import produce from "immer";
import Markdown from "markdown-to-jsx";

import GenericSpinner from "@kikoff/components/src/v1/animations/GenericSpinner";
import ContainerButton from "@kikoff/components/src/v1/buttons/ContainerButton";
import KButton from "@kikoff/components/src/v1/buttons/KButton";
import Card from "@kikoff/components/src/v1/cards/Card";
import Messages, { IMessage } from "@kikoff/components/src/v1/chat/Messages";
import Collapsible from "@kikoff/components/src/v1/containers/Collapsible";
import Tooltip from "@kikoff/components/src/v1/info/Tooltip";
import KLink from "@kikoff/components/src/v1/navigation/KLink";
import Noop from "@kikoff/components/src/v1/utility/Noop";
import useUpdate from "@kikoff/hooks/src/useUpdate";
import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { promiseDelay } from "@kikoff/utils/src/general";
import { handleProtoStatus } from "@kikoff/utils/src/proto";

import { ArrowLink } from "@component/dashboard/credit-score/components/trailing";
import ListTile from "@component/dashboard/credit-score/list_tile";
import BottomSheet from "@component/overlay/bottom_sheet";
import BottomSheetLayout from "@component/overlay/layout/bottom_sheet";
import {
  initCreditV2,
  selectCreditReportAccount,
  selectReport,
} from "@feature/credit";
import { getKikoffCreditAccountMentalModelName } from "@src/constants";
import { handleURL } from "@src/kikoff_url";
import { buildOverlay, useOverlay } from "@src/overlay";

import styles from "./chat.module.scss";

type Context = keyof typeof web.public_.ChatConversation.Context;

interface ChatOverlayProps {
  context: Context;
}

const ChatOverlay = buildOverlay
  .layout(BottomSheetLayout)
  .config({ duplicateBehavior: "replace" })(function O({
  context,
}: ChatOverlayProps) {
  const overlay = useOverlay(ChatOverlay);
  const dispatch = useDispatch();

  const credit = useSelector((state) => state.credit.credit);

  const [{ messages, prompt, actions, isSending }, setChat] = useChat(context);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!credit) dispatch(initCreditV2());
    if (messages.length === 0) {
      setChat.start().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <BottomSheet className={styles["chat-overlay"]}>
      <header>
        <ContainerButton
          className={styles.back}
          onClick={() => {
            overlay.removeSelf();
          }}
        >
          
        </ContainerButton>
        <div className={`${styles.title} text:regular+`}>Kikoff Chatbot</div>
        <div />
      </header>
      <div className={styles["scroll-container"]}>
        <div>
          {loading && <GenericSpinner center size={24} />}
          <Messages messages={messages} />
          <Actions
            actions={actions}
            onClick={(url) => {
              if (url) handleURL(url);
              setChat.actions([]);
            }}
          />
          <Prompt
            prompt={prompt}
            onInput={(content) => {
              setChat.sendMessage(content);
            }}
          />
          <Freeform
            label="Write a message"
            onSend={(content) => {
              setChat.sendMessage(content);
            }}
            isSending={isSending}
          />
        </div>
      </div>
    </BottomSheet>
  );
});

export default ChatOverlay;

interface MessageFeedbackProps {
  onRate(rating: -1 | 1): void;
  sent: boolean;
}

function MessageFeedback({ onRate, sent }: MessageFeedbackProps) {
  return (
    <motion.div className={`${styles.feedback} text:mini color:moderate p-0.5`}>
      {sent ? (
        "Thanks for your feedback"
      ) : (
        <div className="text:+">
          <ContainerButton
            onClick={() => {
              onRate(1);
            }}
            inline
            className="mr-1.5"
          >
            👍 Helpful
          </ContainerButton>
          <ContainerButton
            inline
            onClick={() => {
              onRate(-1);
            }}
          >
            👎 Not helpful
          </ContainerButton>
        </div>
      )}
    </motion.div>
  );
}

interface AccountListProps {
  accountImpacts: web.public_.ChatFactorChange.IAccountImpact[];
}

function AccountList({ accountImpacts }: AccountListProps) {
  const [showAll, setShowAll] = useState(false);

  const renderLinks = (list: typeof accountImpacts) =>
    list.map((impact) => (
      <AccountEntry accountImpact={impact} key={impact.accountId} />
    ));

  return (
    <div className={styles["account-list"]}>
      {renderLinks(accountImpacts.slice(0, 3))}
      {accountImpacts.length > 3 && (
        <>
          <Collapsible collapse={!showAll}>
            {renderLinks(accountImpacts.slice(3))}
          </Collapsible>
          <KButton
            className={styles.expand}
            variant="text-underline"
            size="small"
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            Show {accountImpacts.length - 3} {showAll ? "less" : "more"}
          </KButton>
        </>
      )}
    </div>
  );
}

interface AccountLinkProps {
  accountImpact: web.public_.ChatFactorChange.IAccountImpact;
}

function AccountEntry({
  accountImpact: { accountId, accountName, generatedDate, valence },
}: AccountLinkProps) {
  const account = useSelector(selectCreditReportAccount(accountId, "equifax"));
  const credit = useSelector(selectReport("equifax"));

  const MaybeLink = account ? KLink : (Noop as never);

  return (
    <MaybeLink
      href={`/dashboard/credit-score/equifax/account/${accountId}`}
      variant="container"
    >
      <div className={styles["account-link"]}>
        <ListTile
          title={
            <div className="text:small+">
              <Tooltip
                content={
                  {
                    [-1]: "Negative impact",
                    0: "Neutral impact",
                    1: "Positive impact",
                  }[Math.sign(valence)]
                }
              >
                <span
                  style={{
                    color: {
                      [-1]: "var(--error)",
                      0: "var(--moderate-weak)",
                      1: "var(--success)",
                    }[Math.sign(valence)],
                  }}
                >
                  ●
                </span>
              </Tooltip>{" "}
              {accountName}{" "}
              {credit.generatedDate.seconds !== generatedDate.seconds && (
                <span className="color:moderate-weak">(removed)</span>
              )}
            </div>
          }
          trailing={account && <ArrowLink />}
          noPadding
          separator={false}
        />
      </div>
    </MaybeLink>
  );
}

interface ActionsProps {
  actions: ChatState["actions"];
  onClick(url: string): void;
}

function Actions({ actions, onClick }: ActionsProps) {
  return (
    <div className={styles["actions"]}>
      <AnimatePresence>
        {actions.length > 0 && (
          <motion.div
            initial={{ height: 0, opacity: 0, x: "50%" }}
            animate={{ height: null, opacity: 1, x: 0, margin: "8px 0 24px" }}
            exit={{ height: 0, opacity: 0, x: "50%", margin: 0 }}
            className={styles.container}
          >
            {actions.map(({ label, url }) => (
              <Card
                outline
                className={styles.action}
                as={ContainerButton}
                onClick={() => {
                  onClick(url);
                }}
              >
                <div className="text:small mb-0.5"></div>
                <div className="text:mini+">{label}</div>
              </Card>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface PromptProps {
  prompt: IPrompt;
  onInput(input: IPrompt["options"][number]): void;
}

function Prompt({ prompt, onInput }: PromptProps) {
  return (
    <div className={styles["prompt"]}>
      <AnimatePresence>
        {prompt && (
          <motion.div
            initial={{ height: 0, opacity: -0.5 }}
            animate={{ height: null, opacity: 1 }}
            exit={{ height: 0, opacity: -0.5 }}
          >
            {(() => {
              if (prompt.type === "multiple-choice")
                return (
                  <div className={styles["option-list"]}>
                    {prompt.options.map((option, i) => (
                      <ContainerButton
                        key={i}
                        onClick={() => {
                          onInput(option);
                        }}
                      >
                        {option}
                      </ContainerButton>
                    ))}
                  </div>
                );
            })()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface FreeformProps {
  label: string;
  onSend(content: string): void;
  isSending: boolean;
}

function Freeform({ label, onSend, isSending }: FreeformProps) {
  const [value, setValue] = useState("");

  const trimmed = value.trim();

  return (
    <form
      className={styles["freeform"]}
      onSubmit={(e) => {
        e.preventDefault();
        if (!trimmed) return;
        onSend(trimmed);
        setValue("");
      }}
    >
      <input
        type="text"
        name="content"
        placeholder={label}
        value={value}
        onChange={(e) => {
          setValue(e.currentTarget.value);
        }}
      />
      <button type="submit" disabled={!trimmed || isSending}>
        
      </button>
    </form>
  );
}

interface MultipleChoice {
  type: "multiple-choice";
  options: string[];
}

type IPrompt = MultipleChoice;

enum MessageContent {
  TYPING,
  START,
}

const defaultChatState = {
  messages: [] as IMessage[],
  prompt: null as IPrompt,
  actions: [] as web.public_.SendMessageWithBufferResponse["actions"],
  isSending: false,
};
type ChatState = typeof defaultChatState;

const ChatContext = React.createContext(
  null as useState.Result<Partial<Record<Context, typeof defaultChatState>>>
);

export function ChatProvider({ children }) {
  const [chat, setChat] = useState({}) as React.ContextType<typeof ChatContext>;

  return (
    <ChatContext.Provider value={[chat, setChat]}>
      {children}
    </ChatContext.Provider>
  );
}

const useChat = (context: Context) => {
  const [chatByContext, setChatByContext] = useContext(ChatContext);
  const pendingMessageId = useRef(1);
  const sentRatings = useRef(new Set()).current;
  const update = useUpdate();
  const markRated = (messageId: string) => {
    sentRatings.add(messageId);
    update();
  };

  const typingPredicate = ({ sender, content }) =>
    sender === "bot" && content === MessageContent.TYPING;

  if (!(context in chatByContext)) chatByContext[context] = defaultChatState;

  const chat = chatByContext[context];
  const setChat = Object.assign(
    (action: SetStateAction<typeof defaultChatState>) => {
      setChatByContext((prev) => ({
        ...prev,
        [context]:
          typeof action === "function" ? action(prev[context]) : action,
      }));
    },
    {
      start() {
        return webRPC.Chat.fetchMessages({
          conversation: {
            context: web.public_.ChatConversation.Context[context],
          },
        }).then(
          handleProtoStatus({
            SUCCESS(data) {
              const { Sender } = web.public_.ChatMessage;
              if (data.messages.length > 0)
                setChat((prev) => ({
                  ...prev,
                  messages: data.messages.map(({ content, sender, uuid }) => ({
                    sender: { [Sender.KIKOFF]: "bot", [Sender.USER]: "user" }[
                      sender
                    ],
                    content: <Markdown>{content}</Markdown>,
                    textContent: content,
                    id: uuid,
                  })),
                }));
              else {
                const initialOptions = {
                  CREDIT_COACH: [
                    "What are the five credit factors?",
                    "How can I dispute incorrect information on my credit report?",
                    "What are some ways to reduce my debt?",
                  ],
                  SUPPORT: [
                    `How do I make a payment for my ${getKikoffCreditAccountMentalModelName()}?`,
                    "How can I use my line of credit?",
                    "How do I turn on autopay?",
                  ],
                }[context];
                // if (initialOptions)
                //   setChat.prompt({
                //     options: initialOptions,
                //     type: "multiple-choice",
                //   });
              }
            },
            _DEFAULT() {
              throw new Error("Failed to fetch message history.");
            },
          })
        );
      },

      startTyping() {
        setChat((prev) => {
          if (prev.messages.some(typingPredicate)) return prev;
          return {
            ...prev,
            messages: [
              ...prev.messages,
              {
                sender: "bot",
                content: MessageContent.TYPING,
              },
            ],
          };
        });
      },
      pushMessage(message: IMessage) {
        setChat((prev) =>
          produce(prev, (draft) => {
            if (message.sender === "bot") {
              const typingMessage = draft.messages.find(typingPredicate);
              if (typingMessage) {
                Object.assign(typingMessage, message);
                return;
              }
            }

            draft.messages.push(message);
          })
        );
      },
      async sendMessage(content: string | MessageContent.START) {
        setChat((prev) => ({ ...prev, isSending: true }));

        const pendingId =
          content !== MessageContent.START &&
          `pending:${pendingMessageId.current++}`;
        if (content !== MessageContent.START) {
          setChat.pushMessage({ sender: "user", content, id: pendingId });
          setChat.prompt(null);
        }

        const response = (async (): Promise<
          Partial<Omit<ChatState, "messages">> & {
            messages?: OmitFromUnion<IMessage, "sender">[];
          }
        > => {
          const data: web.public_.SendMessageResponse = await webRPC.Chat.sendMessage(
            {
              conversation: {
                context: web.public_.ChatConversation.Context[context],
              },
              // `content` can be MessageContent.START
              message: typeof content === "string" ? content : null,
            }
          )
            .then(
              handleProtoStatus({
                _DEFAULT(data) {
                  return data;
                },
              })
            )
            .catch(() => false)
            .finally(() => {
              setChat((prev) => ({ ...prev, isSending: false }));
            });

          if (!data)
            return {
              messages: [
                {
                  content:
                    "Uh oh, something went wrong. Please try again or come back later.",
                },
              ],
            };

          if (pendingId)
            setChat((prev) =>
              produce(prev, (draft) => {
                draft.messages.find(({ id }) => id === pendingId).id =
                  data.messageUuid;
              })
            );

          return {
            messages: [
              {
                content: <Markdown>{data.responseContent}</Markdown>,
                actions: ({ id }) => (
                  <MessageFeedback
                    sent={sentRatings.has(id)}
                    onRate={(rating) => setChat.rateMessage(id, rating)}
                  />
                ),
                textContent: data.responseContent,
                id: data.responseUuid,
              },
            ],
          };
        })();

        await promiseDelay(500);
        setChat.startTyping();
        await promiseDelay(1000);

        const {
          prompt,
          messages: [first, ...rest],
        } = await response;

        setChat.pushMessage({
          sender: "bot",
          ...first,
        });

        for (const message of rest) {
          setChat.startTyping();
          await promiseDelay(2000);
          setChat.pushMessage({
            sender: "bot",
            ...message,
          });
        }

        await promiseDelay(700);

        setChat.prompt(prompt);
      },
      rateMessage(messageId: string, rating: 1 | -1) {
        markRated(messageId);

        const { Rating } = web.public_.RateMessageRequest;

        webRPC.Chat.rateMessage({
          messageUuid: messageId,
          rating: { 1: Rating.GOOD, [-1]: Rating.BAD }[rating],
          v2: true,
        });
      },
      actions(actions: ChatState["actions"]) {
        setChat((prev) => ({ ...prev, actions }));
      },
      prompt(prompt: IPrompt) {
        setChat((prev) => ({ ...prev, prompt }));
      },
    }
  );

  return [chat, setChat] as const;
};

function messageTextContent(message: IMessage) {
  return ("textContent" in message
    ? message.textContent
    : { [MessageContent.TYPING]: "..." }[message.content] ||
      message.content) as string;
}
