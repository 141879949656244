import { useMemo } from "react";
import { capitalize, orderBy, sum } from "lodash-es";
import { Tuple } from "record-tuple";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { dedupeBy } from "@kikoff/utils/src/array";
import { invertResult, memo } from "@kikoff/utils/src/function";
import { serverNow } from "@kikoff/utils/src/number";
import { transformEntries, UObject } from "@kikoff/utils/src/object";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";
import { conjunctionList, format } from "@kikoff/utils/src/string";
import Table from "@kikoff/utils/src/table";

import { RootState } from "@store";
import { nativeDispatch } from "@util/mobile";

import { createLoadableSelector, thunk } from "../utils";

import { Bureau } from "./credit";
import {
  fetchPreviewPremiumUpgrade,
  selectIsPremium,
  updateOrders,
} from "./shopping";
import { fetchUser } from "./user";

export { disputeReasonData } from "@kikoff/proto/src/dev/factories/disputes/CreditDisputeItem";

const initialState = {
  maxItems: 3,
  priceCents: 0,
  sendNewDisputeEligible: false,
  newDisputeItemsAvailable: false,
  drawerToken: null,
  nextAllowedAt: null as google.protobuf.ITimestamp,
  show3b: null as boolean,
  betaOpt: null as "in" | "out",

  disputableItemByAccountId: {} as Record<string, CreditDisputeItem>,
  itemByItemId: {} as Record<CreditDisputeItem.ItemId, CreditDisputeItem>,
  itemIdByBureauByTradelineId: {} as ItemIdByBureau.ByTradelineId,
  tradelineIdByAccountId: {} as Record<
    CreditDisputeItem.AccountId,
    CreditDisputeItem.ItemId
  >,

  // Lists
  disputableTradelineIds: null as CreditDisputeItem.TradelineId[],
  disputableTradelineIds3b: null as CreditDisputeItem.TradelineId[],
  disputedTradelineIds: null as CreditDisputeItem.TradelineId[],

  // Selection
  checkedByTradelineId: {} as Record<CreditDisputeItem.TradelineId, boolean>,
  checkedByTradelineId3b: {} as Record<CreditDisputeItem.TradelineId, boolean>,
  reasonByTradelineId: {} as Record<
    CreditDisputeItem.TradelineId,
    web.public_.CreditDisputeItem.Reason
  >,

  disputeLettersLoaded: false,
  disputeByLetterToken: {} as CreditDispute.ByLetterToken,
  letterTokenByBureauBySubmissionToken: {} as CreditDispute.LetterToken.ByBureau.BySubmissionToken,
  pendingSubmissionToken: null as CreditDispute.SubmissionToken,
  sentSubmissionTokens: [] as CreditDispute.SubmissionToken[],
  newResponseSubmissionToken: null as CreditDispute.SubmissionToken,
  // Manual mail is only available with 1B
  readyToMailLetterToken: null as CreditDispute.LetterToken,
  hasUnviewedDispute: false,

  // Reverse index
  submissionTokenByTradelineId: {} as Record<
    CreditDisputeItem.TradelineId,
    CreditDispute.SubmissionToken
  >,

  pdfLinkByLetterToken: {} as Record<CreditDispute.LetterToken, string>,
};

export type CreditDisputesState = typeof initialState;

export type DisputeMethod = "efax" | "mail" | "premium";

const creditDisputesSlice = createSlice({
  name: "creditDisputes",
  initialState,
  reducers: {
    setDisputableItems(
      state,
      { payload }: PayloadAction<{ items: CreditDisputeItem[]; is3b: boolean }>
    ) {
      const { is3b, items } = payload;

      state[`disputableTradelineIds${is3b ? "3b" : ""}`] = [
        ...new Set(items.map(({ tradelineId }) => tradelineId)),
      ];
      Object.assign(
        state.itemByItemId,
        Object.fromEntries(items.map((item) => [item.itemId, item]))
      );
      Object.assign(
        state.itemIdByBureauByTradelineId,
        ItemIdByBureau.ByTradelineId.fromItemList(items)
      );
      Object.assign(
        state.tradelineIdByAccountId,
        Object.fromEntries(
          items.map(({ tradelineId, accountId }) => [accountId, tradelineId])
        )
      );
    },
    setCheckedDisputableItems(
      state,
      {
        payload: { is3b, checkedItems },
      }: PayloadAction<{
        is3b: boolean;
        checkedItems: CreditDisputesState["checkedByTradelineId"];
      }>
    ) {
      state[`checkedByTradelineId${is3b ? "3b" : ""}`] = checkedItems;
    },
    updateCheckedDisputableItems(
      state,
      { payload }: PayloadAction<CreditDisputesState["checkedByTradelineId"]>
    ) {
      Object.assign(
        state[`checkedByTradelineId${state.betaOpt === "in" ? "3b" : ""}`],
        payload
      );
    },
    updateDisputableItemReasons(
      state,
      { payload }: PayloadAction<CreditDisputesState["reasonByTradelineId"]>
    ) {
      Object.assign(state.reasonByTradelineId, payload);
    },
    setDisputes(state, { payload }: PayloadAction<CreditDispute[]>) {
      state.disputeLettersLoaded = true;

      const disputes = orderBy(
        payload,
        ({ sentAt }) => sentAt?.seconds,
        "desc"
      );
      state.disputeByLetterToken = Object.fromEntries(
        disputes.map((dispute) => [dispute.letterToken, dispute])
      );
      state.letterTokenByBureauBySubmissionToken = CreditDispute.LetterToken.ByBureau.BySubmissionToken.fromDisputeList(
        disputes
      );

      state.pendingSubmissionToken =
        disputes.find(CreditDispute.isPending)?.submissionToken || null;
      state.newResponseSubmissionToken =
        disputes.find(CreditDispute.hasNewResponse)?.submissionToken || null;
      state.readyToMailLetterToken =
        disputes.find(CreditDispute.isReadyToMail)?.letterToken || null;
      state.sentSubmissionTokens = [
        ...new Set(
          disputes
            .filter(invertResult(CreditDispute.isPending))
            .map(({ submissionToken }) => submissionToken)
        ),
      ];
      state.hasUnviewedDispute = disputes.some(({ viewed }) => !viewed);

      const disputedItems = disputes.flatMap(
        ({ disputedItems }) => disputedItems
      );
      state.disputedTradelineIds = [
        ...new Set(disputedItems.map(({ tradelineId }) => tradelineId)),
      ];
      Object.assign(
        state.itemIdByBureauByTradelineId,
        ItemIdByBureau.ByTradelineId.fromItemList(disputedItems)
      );
      Object.assign(
        state.itemByItemId,
        Object.fromEntries(disputedItems.map((item) => [item.itemId, item]))
      );
    },
    setMaxItems(
      state,
      { payload }: PayloadAction<CreditDisputesState["maxItems"]>
    ) {
      state.maxItems = payload;
    },
    setPriceCents(
      state,
      { payload }: PayloadAction<CreditDisputesState["priceCents"]>
    ) {
      state.priceCents = payload;
    },
    setSendNewDisputeEligible(
      state,
      { payload }: PayloadAction<CreditDisputesState["sendNewDisputeEligible"]>
    ) {
      state.sendNewDisputeEligible = payload;
    },
    setNewDisputeItemsAvailable(
      state,
      {
        payload,
      }: PayloadAction<CreditDisputesState["newDisputeItemsAvailable"]>
    ) {
      state.newDisputeItemsAvailable = payload;
    },
    setDrawerToken(
      state,
      { payload }: PayloadAction<CreditDisputesState["drawerToken"]>
    ) {
      state.drawerToken = payload;
    },
    setNextAllowedAt(
      state,
      { payload }: PayloadAction<CreditDisputesState["nextAllowedAt"]>
    ) {
      state.nextAllowedAt = payload;
    },
    updatePdfLinks(
      state,
      { payload }: PayloadAction<CreditDisputesState["pdfLinkByLetterToken"]>
    ) {
      Object.assign(state.pdfLinkByLetterToken, payload);
    },
    setShow3b(
      state,
      { payload }: PayloadAction<CreditDisputesState["show3b"]>
    ) {
      state.show3b = payload;
    },
    setBetaOpt(
      state,
      { payload }: PayloadAction<CreditDisputesState["betaOpt"]>
    ) {
      state.betaOpt = payload;
    },
  },
});

const { actions } = creditDisputesSlice;
export const {
  setCheckedDisputableItems,
  updateCheckedDisputableItems,
  updateDisputableItemReasons,
  setBetaOpt,
} = actions;

export default creditDisputesSlice.reducer;

export const groupLabels: Record<
  keyof typeof web.public_.CreditDisputeItem.Category,
  string
> = {
  CHARGE_OFF: "Charge-offs",
  COLLECTION: "Collections",
  INQUIRY: "Inquiries",
  LATE_PAYMENT: "Late payments",
};

export const itemLabels: Record<
  keyof typeof web.public_.CreditDisputeItem.Category,
  string
> = {
  CHARGE_OFF: "Charge-off",
  COLLECTION: "Collection",
  INQUIRY: "Inquiry",
  LATE_PAYMENT: "Late payment",
};

export const disputeStatusLabels: Record<
  keyof typeof web.public_.Dispute.Status,
  string
> = {
  PENDING: "Pending",
  SENT: "Sent",
  REVIEWED: "Completed",
  IN_REVIEW: "In review",
  READY_TO_MAIL: "Ready to mail",
  MAILED_BY_USER: "Mailed",
};

export const SurveyOption =
  web.public_.SaveDisputeSurveyResponseRequest.SurveyOption;

export const surveyLabels: Record<keyof typeof SurveyOption, string> = {
  NONE: "",
  ALL_ITEMS_REMOVED: "All items were removed",
  ONE_ITEM_REMOVED: "At least one item was removed",
  NO_ITEM_REMOVED: "All items were verified as accurate and none were removed",
  FRIVOLOUS_LETTER: "Bureau marked letter as frivolous",
  MORE_INFO_REQUEST: "Bureau asked for more information",
  OTHER: "Other",
};

export const selectShow3bDisputes = () => (state: RootState) =>
  state.creditDisputes.show3b;

export const selectDisputesBetaOpt = () => (state: RootState) =>
  state.creditDisputes.betaOpt;

export const selectDispute3bUnlocked = createLoadableSelector(
  () => (state) => selectShow3bDisputes()(state) && selectIsPremium()(state),
  {
    dependsOn: [selectIsPremium],
  }
);

export const selectHasDisputableItems = createLoadableSelector(
  () => (state) =>
    (state.creditDisputes.disputableTradelineIds?.length || 0) +
      (state.creditDisputes.disputableTradelineIds3b?.length || 0) >
    0,
  {
    loadAction: () => fetchCreditDisputableItems(),
    selectLoaded: () => (state) => state.creditDisputes.disputableTradelineIds,
  }
);

export const selectDisputableItemCount = Object.assign(
  createLoadableSelector(
    () => (state) =>
      state.creditDisputes[
        `disputableTradelineIds${
          state.creditDisputes.betaOpt === "in" ? "3b" : ""
        }`
      ]?.length as number,
    { loadAction: () => fetchCreditDisputableItems() }
  ),
  {
    only3b: () => (state: RootState) =>
      state.creditDisputes.disputableTradelineIds3b?.length,
    only1b: () => (state: RootState) =>
      state.creditDisputes.disputableTradelineIds?.length,
  }
);

export const selectDisputeItemIdByBureau = createLoadableSelector(
  (tradelineId: CreditDisputeItem.TradelineId) => (state: RootState) =>
    state.creditDisputes.itemIdByBureauByTradelineId[tradelineId],
  { dependsOn: [selectDisputableItemCount] }
);

export const selectDisputeItemByBureau = (
  tradelineId: CreditDisputeItem.TradelineId
) => (state: RootState) =>
  transformEntries(
    selectDisputeItemIdByBureau(tradelineId)(state),
    ([bureau, itemId]) => [bureau, state.creditDisputes.itemByItemId[itemId]]
  );

export const selectDefaultDisputableItems = createLoadableSelector(
  ({ is3b }: { is3b?: boolean } = {}) => (state) =>
    (
      state.creditDisputes[
        `disputableTradelineIds${
          (is3b == null ? state.creditDisputes.betaOpt === "in" : is3b)
            ? "3b"
            : ""
        }`
      ] || []
    ).map(
      (tradelineId) =>
        state.creditDisputes.itemByItemId[
          ItemIdByBureau.defaultItemId(
            selectDisputeItemIdByBureau(tradelineId)(state)
          )
        ]
    ),
  { dependsOn: [selectDisputeItemIdByBureau] }
);

export const selectDisputeItemGroups = memo(
  (items: CreditDisputeItem[] | "1b" | "3b") =>
    memo.disableIf(typeof items !== "string")(
      (state: RootState) => {
        const deduped = Array.isArray(items)
          ? dedupeBy(items, "tradelineId")
          : selectDefaultDisputableItems({
              is3b: { "1b": false, "3b": true }[items] ?? null,
            })(state);

        return Object.entries(
          deduped.groupBy(
            (item) =>
              groupLabels[
                web.public_.CreditDisputeItem.Category[item.category]
              ] as string
          )
        );
      },
      {
        by: ([state]) =>
          selectDefaultDisputableItems({
            is3b: { "1b": false, "3b": true }[items as any] ?? null,
          })(state),
      }
    ),
  {
    by: ([items]) => [
      items.length === 0
        ? // Memoize all empty arrays together
          Tuple()
        : items,
    ],
  }
);

export const selectInReviewDisputeItemGroups = () => (state: RootState) =>
  selectDisputeItemGroups(
    Object.values(
      state.creditDisputes.disputeByLetterToken
    ).flatMap(({ disputedItems, sentAt, reviewedAt }) =>
      sentAt && !reviewedAt ? disputedItems : []
    )
  )(state);

export const selectMaxDisputeItems = () => (state: RootState) =>
  state.creditDisputes.maxItems;

export const selectCheckedCount = () => (state: RootState) =>
  sum([
    0,
    ...Object.values(
      state.creditDisputes[
        `checkedByTradelineId${
          state.creditDisputes.betaOpt === "in" ? "3b" : ""
        }`
      ]
    ),
  ]);

export const selectDisputeItem = Object.assign(
  (itemId: CreditDisputeItem.ItemId) => (state: RootState) =>
    state.creditDisputes.itemByItemId[itemId],
  {
    default: (tribureauId: string) => (state: RootState) =>
      state.creditDisputes.itemByItemId[
        ItemIdByBureau.defaultItemId(
          selectDisputeItemIdByBureau(tribureauId)(state)
        )
      ],
  }
);

export const selectDisputableItemState = (tradelineId: string) => (
  state: RootState
) => ({
  checked: !!(
    state.creditDisputes.checkedByTradelineId[tradelineId] ||
    state.creditDisputes.checkedByTradelineId3b[tradelineId]
  ),
  reason: state.creditDisputes.reasonByTradelineId[tradelineId],
});

export const selectLatestDispute = () => (state: RootState) =>
  Object.values(state.creditDisputes.disputeByLetterToken).find(
    ({ sentAt }) => sentAt
  );

export const selectDisputesLoaded = () => (state: RootState) =>
  state.creditDisputes.disputeLettersLoaded;

export const selectDispute = createLoadableSelector(
  (letterToken: CreditDispute.LetterToken) => (state: RootState) =>
    state.creditDisputes.disputeByLetterToken[letterToken],
  {
    loadAction: () => fetchCreditDisputes(),
    selectLoaded: selectDisputesLoaded,
  }
);

export const selectDisputeSubmission = Object.assign(
  createLoadableSelector(
    (submissionToken: CreditDispute.SubmissionToken) => (state) =>
      transformEntries(
        state.creditDisputes.letterTokenByBureauBySubmissionToken[
          submissionToken
        ] || ({} as never),
        ([bureau, letterToken]) => [
          bureau,
          state.creditDisputes.disputeByLetterToken[letterToken],
        ]
      ),
    {
      dependsOn: [selectDispute],
    }
  ),
  {
    pending: Object.assign(
      () => (state: RootState) =>
        selectDisputeSubmission(selectDisputeSubmission.pending.token()(state))(
          state
        ),
      {
        token: () => (state: RootState) =>
          state.creditDisputes.pendingSubmissionToken,
      }
    ),
    is3b: (submissionToken: CreditDispute.SubmissionToken) => (
      state: RootState
    ) => {
      const submission =
        state.creditDisputes.letterTokenByBureauBySubmissionToken[
          submissionToken
        ];
      if (!submission) return false;
      return Bureau.list.filter((bureau) => submission[bureau]).length > 1;
    },
  }
);

export const selectReadyToMailDispute = createLoadableSelector(
  () => (state) =>
    state.creditDisputes.disputeByLetterToken[
      state.creditDisputes.readyToMailLetterToken
    ],
  {
    dependsOn: [selectDispute],
  }
);

export const selectDefaultSentDisputes = () => (state: RootState) =>
  state.creditDisputes.sentSubmissionTokens.map(
    (token) =>
      state.creditDisputes.disputeByLetterToken[
        CreditDispute.LetterToken.ByBureau.defaultLetterToken(
          state.creditDisputes.letterTokenByBureauBySubmissionToken[token]
        )
      ]
  );

export const selectHasUnviewedDisputes = () => (state: RootState) =>
  state.creditDisputes.hasUnviewedDispute;

export const selectHasSentDisputes = Object.assign(
  () => (state: RootState) =>
    state.creditDisputes.sentSubmissionTokens.length > 0,
  {
    retain: () => (state: RootState) => {
      const loaded = selectDisputesLoaded()(state);
      const has = selectHasSentDisputes()(state);
      return useMemo(() => has, [loaded]);
    },
  }
);

export const selectNewDisputeAllowedAt = () => (state: RootState) =>
  (state.creditDisputes.nextAllowedAt?.seconds || 0) * 1000;

export const selectNewDisputeAllowed = () => (state: RootState) =>
  +selectNewDisputeAllowedAt()(state) <= serverNow();

export const selectDisputeLetterPdfLink = createLoadableSelector(
  (letterToken: CreditDispute.LetterToken) => (state) =>
    state.creditDisputes.pdfLinkByLetterToken[letterToken],
  {
    loadAction: (letterToken) => fetchDisputeLetterPdfLink(letterToken),
  }
);

export const selectTradelineId = {
  byAccountId: (accountId: CreditDisputeItem.AccountId) => (state: RootState) =>
    state.creditDisputes.tradelineIdByAccountId[accountId],
};

export const selectNewDisputeResponse = Object.assign(
  () => (state: RootState) => {
    const token = selectNewDisputeResponse.submissionToken()(state);
    if (!token) return;
    return selectDisputeSubmission(token)(state);
  },
  {
    submissionToken: () => (state: RootState) =>
      state.creditDisputes.newResponseSubmissionToken,
  }
);

export const selectPremiumDisputesBenefits = () => (state: RootState) =>
  state.creditDisputes.show3b == null
    ? []
    : state.creditDisputes.show3b
    ? [
        <>
          Find more potential errors with access to data from{" "}
          <span className="text:regular+">two more credit bureaus</span>
        </>,
        <>
          Get credit report from{" "}
          <span className="text:regular+">all 3 bureaus every month</span>
        </>,
        <>
          A higher credit line (
          <span className="text:regular+">
            {format.money(
              state.shopping.previewPremiumUpgrade?.product?.creditLineAgreement
                .defaultCreditLimitCents
            )}
          </span>
          ) to lower your credit utilization
        </>,
        "More tools to build your credit",
      ]
    : [
        "Instant 1-click submission for FREE",
        `Select up to ${CreditDisputes.maxItems.premium} items/month and have your mailing fee waived`,
        "Keep track of the status within the Kikoff app",
        `A higher credit line (${format.money(
          state.shopping.previewPremiumUpgrade?.product?.creditLineAgreement
            .defaultCreditLimitCents
        )}) to lower your credit utilization`,
      ];

export const fetchCreditDisputableItems = Object.assign(
  () =>
    thunk((dispatch, getState) =>
      Promise.all([
        dispatch(
          fetchCreditDisputableItems.dataOnly({ forceSingleBureau: true })
        ),
        dispatch(fetchCreditDisputes()),
        dispatch(selectIsPremium.loadAction.ifMissing()).then((isPremium) =>
          !isPremium
            ? ([isPremium, null] as never)
            : dispatch(fetchCreditDisputableItems.dataOnly()).then(
                (data3b) => [isPremium, data3b] as const
              )
        ),
      ] as const).then(([data1b, disputes, [isPremium, data3b]]) => {
        const pendingDisputes = disputes.filter(CreditDispute.isPending);
        const pendingIs3b = pendingDisputes.length === 3;

        dispatch(
          setBetaOpt(
            isPremium && selectShow3bDisputes()(getState())
              ? selectDisputesBetaOpt()(getState()) ||
                  // Default to "out" with 1b pending dispute
                  (pendingDisputes.length === 1 ||
                  // Default to "out" if there are no 3b dispute items
                  data3b.disputableItems.length === 0
                    ? "out"
                    : "in")
              : null
          )
        );
        for (const is3b of [false, true] as const) {
          if (is3b && !isPremium) continue;
          const data = is3b ? data3b : data1b;
          const pendingDispute =
            pendingIs3b === is3b ? pendingDisputes[0] : null;

          dispatch(
            actions.updateDisputableItemReasons(
              Object.assign(
                Object.fromEntries(
                  data.disputableItems.map(
                    ({ tradelineId, possibleReasons }) => [
                      tradelineId,
                      possibleReasons[0],
                    ]
                  )
                ),
                Object.fromEntries(
                  pendingDispute?.disputedItems.map(
                    ({ tradelineId, selectedReason }) => [
                      tradelineId,
                      selectedReason,
                    ]
                  ) || []
                )
              )
            )
          );

          if (pendingDispute) {
            dispatch(
              setCheckedDisputableItems({
                is3b,
                checkedItems: Object.fromEntries(
                  pendingDispute.disputedItems
                    // TODO: Remember why we need to filter here
                    .filter(({ tradelineId }) => tradelineId)
                    .map(({ tradelineId }) => [tradelineId, true])
                ),
              })
            );
          }
        }
      })
    ),
  {
    dataOnly: ({ forceSingleBureau = false } = {}) =>
      thunk((dispatch) =>
        Promise.all([
          webRPC.CreditDisputes.getDisputeOptions({
            forceSingleBureau,
          }),
          dispatch(selectIsPremium.loadAction.ifMissing()),
        ]).then<web.public_.GetDisputeOptionsResponse>(([res, isPremium]) =>
          handleProtoStatus({
            SUCCESS(data) {
              dispatch(
                actions.setDisputableItems({
                  items: data.disputableItems,
                  is3b: isPremium && !forceSingleBureau,
                })
              );
              dispatch(actions.setShow3b(data.showTribureauFlow));
              dispatch(actions.setMaxItems(data.maxItemCount));
              dispatch(actions.setPriceCents(data.priceCents));
              dispatch(
                actions.setNewDisputeItemsAvailable(
                  data.newDisputeItemsAvailable
                )
              );
              dispatch(
                actions.setSendNewDisputeEligible(data.sendNewDisputeEligible)
              );
              dispatch(actions.setDrawerToken(data.drawerToken));
              return data;
            },
            _DEFAULT: handleFailedStatus("Failed to get dispute options."),
          })(res)
        )
      ),

    ifNotPresent: () =>
      thunk((dispatch, getState) =>
        Promise.resolve(
          (getState().creditDisputes.disputableTradelineIds as never) ||
            dispatch(fetchCreditDisputableItems())
        ).then(() => {
          // TODO: Add support for return value if needed
        })
      ),
  }
);

export const saveDisputeCustomReason = (
  tradelineId: string,
  customReason: string
) =>
  thunk((dispatch, getState) =>
    webRPC.CreditDisputes.saveCustomDisputeReason({
      customReasons: transformEntries(
        selectDisputeItemIdByBureau(tradelineId)(getState()),
        ([, itemId]) => [itemId, customReason]
      ),
    }).then(
      handleProtoStatus({
        SUCCESS() {
          return dispatch(
            fetchCreditDisputableItems.dataOnly({
              forceSingleBureau: getState().creditDisputes.disputableTradelineIds.includes(
                tradelineId
              ),
            })
          );
        },
        _DEFAULT: handleFailedStatus("Failed to save custom reason."),
      })
    )
  );

export const saveDisputeSurveyResponse = (
  letterToken: string,
  {
    responseRecieved,
    option,
    customResponse,
  }:
    | {
        responseRecieved: true;
        option: keyof typeof SurveyOption;
        customResponse: string;
      }
    | {
        responseRecieved: false;
        option?: "NONE";
        customResponse?: null;
      }
) => {
  return webRPC.CreditDisputes.saveDisputeSurveyResponse({
    letterToken,
    bureauResponseReceived: responseRecieved,
    responseOption: SurveyOption[option],
    customResponse,
  }).then(
    handleProtoStatus({
      SUCCESS() {},
      _DEFAULT: handleFailedStatus("Failed to save survey response."),
    })
  );
};

export const startCreditDispute = () =>
  thunk((dispatch, getState) => {
    const state = getState().creditDisputes;

    return webRPC.CreditDisputes.startDispute({
      forceSingleBureau: state.betaOpt === "out",
      reasons: Object.fromEntries(
        Object.entries(state.reasonByTradelineId)
          .filter(
            ([id]) =>
              state[
                `checkedByTradelineId${state.betaOpt === "in" ? "3b" : ""}`
              ][id]
          )
          .flatMap(([tradelineId, reason]) =>
            Object.values(
              state.itemIdByBureauByTradelineId[tradelineId]
            ).map((itemId) => [itemId, reason])
          )
      ),
    }).then(
      handleProtoStatus({
        SUCCESS({ disputes }) {
          dispatch(
            actions.setDisputes([
              ...Object.values(state.disputeByLetterToken).filter(
                invertResult(CreditDispute.isPending)
              ),
              ...disputes,
            ])
          );

          dispatch(fetchUser.todos());
        },
        _DEFAULT: handleFailedStatus("Failed to generate letters."),
      })
    );
  });

export const submitCreditDispute = (() => {
  const submit = (payload: web.public_.ISubmitDisputeRequest) =>
    thunk((dispatch, getState) =>
      webRPC.CreditDisputes.submitDispute({
        forceSingleBureau: selectDisputesBetaOpt()(getState()) === "out",
        submissionToken: selectDisputeSubmission.pending.token()(getState()),
        ...payload,
      }).then(
        handleProtoStatus({
          SUCCESS() {
            dispatch(fetchCreditDisputes());
            dispatch(fetchCreditDisputableItems());
            if (payload.upgradeToPremium) {
              dispatch(updateOrders());
              dispatch(fetchPreviewPremiumUpgrade());
              dispatch(fetchUser.creditLine());
            }

            if (!nativeDispatch("invalidate")) dispatch(fetchUser.todos());
          },
          _DEFAULT: handleFailedStatus("Failed to submit dispute."),
        })
      )
    );
  return {
    mail: () => submit({ mailedByUser: true }),
    eFax: (paymentMethodToken: string) => submit({ paymentMethodToken }),
    premiumUpgrade: () => submit({ upgradeToPremium: true }),
  };
})();

export const fetchCreditDisputes = Object.assign(
  () =>
    thunk((dispatch) =>
      webRPC.CreditDisputes.listDisputes({}).then<web.public_.IDispute[]>(
        handleProtoStatus({
          SUCCESS(data) {
            dispatch(actions.setDisputes(data.disputes));
            dispatch(actions.setNextAllowedAt(data.nextDisputableDate));

            return data.disputes;
          },
          _DEFAULT: handleFailedStatus("Failed to fetch credit disputes."),
        })
      )
    ),
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        const {
          disputeByLetterToken,
          disputeLettersLoaded,
        } = getState().creditDisputes;
        return Promise.resolve(
          disputeLettersLoaded
            ? Object.values(disputeByLetterToken)
            : dispatch(fetchCreditDisputes())
        );
      }),
  }
);

export const fetchDisputeLetterPdfLink = (letterToken: string) =>
  thunk((dispatch) =>
    webRPC.CreditDisputes.downloadDisputePdf({ letterToken }).then(
      handleProtoStatus({
        SUCCESS({ pdfLink }) {
          dispatch(actions.updatePdfLinks({ [letterToken]: pdfLink }));
        },
        _DEFAULT: handleFailedStatus("Failed to download dispute letter pdf."),
      })
    )
  );

export const markDisputeAsMailed = (submissionToken: string) =>
  thunk((dispatch, getState) =>
    Promise.all(
      Object.values(selectDisputeSubmission(submissionToken)(getState())).map(
        ({ letterToken }) =>
          webRPC.CreditDisputes.markAsMailed({ letterToken }).then(
            handleProtoStatus({
              SUCCESS() {
                // Continue
              },
              _DEFAULT: handleFailedStatus("Failed to mark as mailed."),
            })
          )
      )
    ).then(() => dispatch(fetchCreditDisputes()))
  );

export namespace CreditDisputes {
  export const maxItems = {
    basic: 3,
    premium: 5,
  };
  export const estimateOffsetDays = 50;
  export const estimateOffsetDaysRange = (() => {
    const low = 45;
    const high = 60;
    return { low, high, text: `${low} to ${high} days` };
  })();
}

export type CreditDisputeItem = web.public_.ICreditDisputeItem;
export namespace CreditDisputeItem {
  export type ItemId = string & {};
  export type TradelineId = string & {};
  export type AccountId = string & {};
  export const isDisputed = (item?: web.public_.ICreditDisputeItem) =>
    item?.selectedReason !== web.public_.CreditDisputeItem.Reason.UNKNOWN;
  export const isRemaining = ({ status }: CreditDisputeItem) =>
    status === web.public_.CreditDisputeItem.Status.READDED ||
    status === web.public_.CreditDisputeItem.Status.VERIFIED;
  export const isRemoved = ({ status }: CreditDisputeItem) =>
    status === web.public_.CreditDisputeItem.Status.DELETED;
}

export type ItemIdByBureau = Record<Bureau, CreditDisputeItem.ItemId>;
export namespace ItemIdByBureau {
  export const defaultItemId = (itemIdByBureau: ItemIdByBureau) =>
    UObject.firstValue(itemIdByBureau);

  export type ByTradelineId = Record<
    CreditDisputeItem.TradelineId,
    ItemIdByBureau
  >;

  export namespace ByTradelineId {
    export const fromItemList = (items: CreditDisputeItem[]) =>
      Table.createIndex(
        items,
        [
          "tradelineId",
          ({ bureau }) => Bureau.byProtoEnum[bureau] as Bureau,
        ] as const,
        "itemId"
      );
  }
}

export type CreditDispute = web.public_.IDispute;
export namespace CreditDispute {
  export type LetterToken = string & {};
  export type SubmissionToken = string & {};

  export type ByLetterToken = Record<LetterToken, CreditDispute>;

  export const isPending = ({ status }: CreditDispute) =>
    status === web.public_.Dispute.Status.PENDING ||
    status === web.public_.Dispute.Status.READY_TO_MAIL;
  export const isReadyToMail = ({ status }: CreditDispute) =>
    status === web.public_.Dispute.Status.READY_TO_MAIL;
  export const hasNewResponse = ({ viewed, reviewedAt }: CreditDispute) =>
    !viewed && reviewedAt;

  export namespace ByLetterToken {
    export const bureauConjunctionList = (
      submission: ByLetterToken,
      filter = (dispute: CreditDispute) => true as unknown
    ) =>
      conjunctionList(
        Object.values(submission)
          .filter(filter)
          .map(({ bureau }) => capitalize(Bureau.byProtoEnum[bureau])),
        "and"
      );
  }

  export namespace LetterToken {
    export type ByBureau = Record<Bureau, LetterToken>;

    export namespace ByBureau {
      export const defaultLetterToken = (letterTokenByBureau: ByBureau) =>
        UObject.firstValue(letterTokenByBureau);

      export type BySubmissionToken = Record<SubmissionToken, ByBureau>;
      export namespace BySubmissionToken {
        export const fromDisputeList = (
          disputes: CreditDispute[]
        ): BySubmissionToken =>
          Table.createIndex(
            disputes,
            [
              "submissionToken",
              ({ bureau }) => Bureau.byProtoEnum[bureau] as Bureau,
            ] as const,
            "letterToken"
          );
      }
    }
  }
}
