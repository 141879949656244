import { combineReducers } from "@reduxjs/toolkit";

import banking from "@feature/banking";
import chat from "@feature/chat";
import credit from "@feature/credit";
import creditDisputes from "@feature/credit_disputes";
import creditLine from "@feature/credit_line";
import debtSettlement from "@feature/debt_settlement";
import funds from "@feature/funds";
import loans from "@feature/loans";
import onboarding from "@feature/onboarding";
import page from "@feature/page";
import rentReporting from "@feature/rent_reporting";
import shopping from "@feature/shopping";
import subscription from "@feature/subscription";
import user from "@feature/user";
import viewProtos from "@feature/view_protos";

export default combineReducers({
  banking,
  chat,
  creditLine,
  credit,
  creditDisputes,
  debtSettlement,
  funds,
  loans,
  onboarding,
  page,
  rentReporting,
  shopping,
  subscription,
  user,
  viewProtos,
});
