import { useEffect } from "react";
import { useSelector } from "react-redux";
import { maxBy } from "lodash-es";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { google, web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { promiseDelay } from "@kikoff/utils/src/general";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { AppThunk, RootState } from "@store";
import analytics from "@util/analytics";

import { createLoadableSelector, thunk } from "../utils";

import { fetchUser } from "./user";

const initialState = {
  products: [] as web.public_.IProduct[],
  orders: null as web.public_.IOrder[],
  bag: {
    items: [] as web.public_.IOrderItem[],
    autoRenew: null as web.public_.IAutoRenew,
    firstPaymentDate: null as google.protobuf.ITimestamp,
  },
  checkoutPreview: null as web.public_.ICheckoutPreviewResponse,
  checkoutResponse: null as web.public_.ICheckoutResponse,
  previewPremiumUpgrade: null as web.public_.IPreviewPremiumUpgradeResponse,
  previewPremiumDowngrade: null as web.public_.IPreviewChangeSubscriptionPlanResponse,
  upgradeToPremiumResponse: null as web.public_.IUpgradeToPremiumResponse,
  disputesPremiumUpgradePreview: null as web.public_.IPreviewPremiumUpgradeResponse,
  subscriptions: null as web.public_.IUserSubscription[],
  subscriptionInvoices: null as web.public_.IUserSubscriptionInvoice[],
};

export type ShoppingState = typeof initialState;

const shoppingSlice = createSlice({
  name: "shopping",
  initialState,
  reducers: {
    setCatalog(state, { payload }: PayloadAction<ShoppingState["products"]>) {
      state.products = payload;
    },
    updateBag(
      state,
      { payload }: PayloadAction<RequireAtLeastOne<ShoppingState["bag"]>>
    ) {
      Object.assign(state.bag, payload);
    },
    setCheckoutPreview(
      state,
      { payload }: PayloadAction<ShoppingState["checkoutPreview"]>
    ) {
      state.checkoutPreview = payload;
    },
    setCheckoutResponse(
      state,
      { payload }: PayloadAction<ShoppingState["checkoutResponse"]>
    ) {
      state.checkoutResponse = payload;
    },
    setPreviewPremiumUpgrade(
      state,
      { payload }: PayloadAction<ShoppingState["previewPremiumUpgrade"]>
    ) {
      state.previewPremiumUpgrade = payload;
    },
    setPreviewPremiumDowngrade(
      state,
      { payload }: PayloadAction<ShoppingState["previewPremiumDowngrade"]>
    ) {
      state.previewPremiumDowngrade = payload;
    },
    setDisputesPremiumUpgradePreview(
      state,
      { payload }: PayloadAction<ShoppingState["disputesPremiumUpgradePreview"]>
    ) {
      state.disputesPremiumUpgradePreview = payload;
    },
    setOrders(state, { payload }: PayloadAction<ShoppingState["orders"]>) {
      state.orders = payload;
    },
    setSubscriptions(
      state,
      { payload }: PayloadAction<ShoppingState["subscriptions"]>
    ) {
      state.subscriptions = payload;
    },
    setSubscriptionInvoices(
      state,
      { payload }: PayloadAction<ShoppingState["subscriptionInvoices"]>
    ) {
      state.subscriptionInvoices = payload;
    },
  },
});

const { actions } = shoppingSlice;
export const { updateBag, setCheckoutPreview } = actions;
export default shoppingSlice.reducer;

export const getProductFromCheckoutPreview = (
  checkoutPreview: web.public_.ICheckoutPreviewResponse
) => checkoutPreview?.order.orderItems[0]?.product;

export const selectOrderBy = <Prop extends keyof web.public_.IOrder>(
  field: Prop,
  match: web.public_.IOrder[Prop]
) => (state: RootState) =>
  state.shopping.orders.find((order) => order[field] === match);

export const selectProductBy = <Prop extends keyof web.public_.IProduct>(
  field: Prop,
  match: web.public_.IProduct[Prop]
) => (state: RootState) =>
  state.shopping.products.find((product) => product[field] === match);

export const selectProductForPlan = (
  plan: keyof typeof web.public_.SubscriptionPlan
) => (state: RootState) =>
  state.shopping.products.find(
    (product) =>
      product.plan === web.public_.SubscriptionPlan[plan] &&
      product.subscriptionDurationMonths === 12
  );

export const selectActiveSubscription = () => (state: RootState) =>
  state.shopping.subscriptions?.find(
    ({ status }) => status === web.public_.UserSubscription.Status.ACTIVE
  );

export const selectIsPremium = createLoadableSelector(
  () => (state: RootState) => {
    const subscriptions = state.shopping.subscriptions;
    const subscription = selectActiveSubscription()(state);

    // Return nullish if subscriptions aren't loaded
    return (
      subscriptions &&
      subscription?.plan === web.public_.SubscriptionPlan.PREMIUM
    );
  },
  {
    loadAction: () => updateOrders(),
    selectLoaded: () => (state) => state.shopping.subscriptions,
  }
);

export const selectMostRecentSubscription = () => (state: RootState) =>
  maxBy(state.shopping.subscriptions, ({ expiresAt }) => expiresAt.seconds);

export const selectPremiumUpgradePreview = Object.assign(
  createLoadableSelector(
    () => (state: RootState) => state.shopping.previewPremiumUpgrade,
    {
      loadAction: () => fetchPreviewPremiumUpgrade(),
    }
  ),
  {
    forDisputes: createLoadableSelector(
      () => (state: RootState) => state.shopping.disputesPremiumUpgradePreview,
      {
        loadAction: () =>
          fetchPreviewPremiumUpgrade(
            web.public_.PreviewPremiumUpgradeRequest.Context.DISPUTES
          ),
      }
    ),
  }
);

export const selectCreditServiceProduct = createLoadableSelector(
  () => (state) =>
    state.shopping.products.find(
      ({ type, subscriptionDurationMonths }) =>
        type === web.public_.Product.Type.SUBSCRIPTION &&
        subscriptionDurationMonths === 12
    ),
  { loadAction: () => updateCatalog(true) }
);

export const selectCheckoutPreview = createLoadableSelector(
  (productToken: string) => (state) => state.shopping.checkoutPreview,
  {
    loadAction: (productToken) => fetchCheckoutPreview(productToken),
    selectLoaded: (productToken) => (state) =>
      productToken &&
      state.shopping.checkoutPreview?.order.orderItems[0].product.token ===
        productToken,
  }
);

export const selectCreditServiceCheckoutPreview = createLoadableSelector(
  () => (state) => {
    const productToken = selectCreditServiceProduct()(state)?.token;
    return (
      productToken &&
      state.shopping.checkoutPreview?.order.orderItems[0].product?.token ===
        productToken &&
      state.shopping.checkoutPreview
    );
  },
  {
    loadAction: () => fetchCreditServiceCheckoutPreview(),
  }
);

export const updateCatalog = Object.assign(
  (storeRequest: boolean = false) => (dispatch, getState) => {
    if (getState().user.authenticated) {
      // Pass in store request context for catalog update requests from the store. This will filter out
      // subscription products for users who already have active subscriptions.
      const context = storeRequest
        ? web.public_.ListCatalogRequest.ListCatalogueContext.KIKOFF_STORE
        : null;
      return webRPC.Shopping.listCatalog({
        context,
      }).then(
        handleProtoStatus({
          SUCCESS(data) {
            dispatch(actions.setCatalog(data.products));
            return data;
          },
          _DEFAULT: handleFailedStatus("Failed to fetch catalog"),
        })
      );
    }

    // TODO: (ixti) Make listCatalog support non-authenticated users
    return webRPC.Shopping.listSubscriptions({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.setCatalog(data.products));

          return data;
        },
        _DEFAULT: handleFailedStatus("Failed to fetch catalog"),
      })
    );
  },
  {
    ifNotPresent: () =>
      thunk((dispatch, getState) => {
        if (getState().shopping.products.length > 0)
          return Promise.resolve({ products: getState().shopping.products });
        return dispatch(updateCatalog());
      }),
  }
);
interface shopCheckoutProps {
  autoPay?: web.public_.AutoPay.ISettings;
  isRenewal?: boolean;
}

export const shopCheckout = (
  { autoPay, isRenewal }: shopCheckoutProps = {
    autoPay: null,
    isRenewal: false,
  }
): AppThunk<Promise<void>> => (dispatch, getState) => {
  const shoppingBag = getState().shopping.bag;

  return webRPC.Shopping.checkout({
    autoRenew: shoppingBag.autoRenew,
    productToken: shoppingBag.items[0].product.token,
    firstPaymentDate: shoppingBag.firstPaymentDate,
    autoPay,
    isRenewal,
  }).then(
    handleProtoStatus({
      async SUCCESS(data) {
        dispatch(actions.setCheckoutResponse(data));
        // Refreshed user sometimes doesn't have credit line, give server time to update
        await promiseDelay(500);
        await Promise.all([
          // Need to wallet in addition to credit line
          dispatch(fetchUser.products()),
          dispatch(updateOrders()),
        ]);
        analytics.convert("Credit Account Open");
      },
      _DEFAULT: handleFailedStatus("Failed to checkout"),
    })
  );
};

export const initDemoShopping = (data): AppThunk => (dispatch) => {
  dispatch(actions.setOrders(data.orders));
  dispatch(actions.setSubscriptions(data.userSubscriptions));
};

export const updateOrders = (): AppThunk<
  Promise<web.public_.ListOrdersResponse>
> => (dispatch) =>
  webRPC.Shopping.listOrders({}).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(actions.setOrders(data.orders));
        dispatch(actions.setSubscriptions(data.userSubscriptions));
        dispatch(
          actions.setSubscriptionInvoices(data.userSubscriptionInvoices)
        );

        return data;
      },
      _DEFAULT: handleFailedStatus("Failed to fetch orders"),
    })
  );
export const fetchCheckoutPreview = (
  productToken: string,
  { isRenewal = false } = {}
): AppThunk<Promise<web.public_.CheckoutPreviewResponse>> => async (dispatch) =>
  webRPC.Shopping.checkoutPreview({ productToken, isRenewal }).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(actions.setCheckoutPreview(data));
        return data;
      },
      _DEFAULT: handleFailedStatus("Failed to fetch checkout preview"),
    })
  );

export const fetchCreditServiceCheckoutPreview = () =>
  thunk((dispatch, getState) =>
    dispatch(updateCatalog.ifNotPresent()).then(async () => {
      const product = selectCreditServiceProduct()(getState());

      const preview = await dispatch(fetchCheckoutPreview(product.token));

      dispatch(
        updateBag({
          items: [{ product, quantity: 1 }],
          autoRenew: {
            status: preview.autoRenew?.status,
          },
        })
      );
    })
  );

export const fetchPreviewPremiumUpgrade = (
  context?: web.public_.PreviewPremiumUpgradeRequest.Context
): AppThunk<Promise<web.public_.PreviewPremiumUpgradeResponse>> => async (
  dispatch
) =>
  webRPC.Shopping.previewPremiumUpgrade({
    context,
  }).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(
          (context === web.public_.PreviewPremiumUpgradeRequest.Context.DISPUTES
            ? actions.setDisputesPremiumUpgradePreview
            : actions.setPreviewPremiumUpgrade)(data)
        );
        return data;
      },
      _DEFAULT: handleFailedStatus("Failed to fetch checkout preview"),
    })
  );

export const upgradeToPremium = () =>
  thunk(async (dispatch) =>
    webRPC.Shopping.upgradeToPremium(
      {}
    ).then<web.public_.UpgradeToPremiumResponse>(
      handleProtoStatus({
        async SUCCESS(data) {
          await Promise.all([
            dispatch(updateOrders()),
            dispatch(fetchPreviewPremiumUpgrade()),
            dispatch(fetchUser.creditLine()),
          ]);

          return data;
        },
        _DEFAULT: handleFailedStatus("Failed to update subscription"),
      })
    )
  );

export const downgradeToBasic = (): AppThunk<
  Promise<web.public_.ChangeSubscriptionPlanResponse>
> => async (dispatch) =>
  webRPC.Shopping.changeSubscriptionPlan({
    plan: web.public_.ChangeSubscriptionPlanRequest.Plan.BASIC,
  }).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(fetchUser.creditLine());
        dispatch(updateOrders());
        return data;
      },
      _DEFAULT: handleFailedStatus("Failed to update subscription"),
    })
  );

export const fetchPreviewPremiumDowngrade = (
  plan: web.public_.ChangeSubscriptionPlanRequest.Plan
): AppThunk<Promise<web.public_.PreviewPremiumUpgradeResponse>> => async (
  dispatch
) =>
  webRPC.Shopping.previewChangeSubscriptionPlan({ plan }).then(
    handleProtoStatus({
      SUCCESS(data) {
        dispatch(actions.setPreviewPremiumDowngrade(data));
        return data;
      },
      _DEFAULT: handleFailedStatus("Failed to fetch checkout preview"),
    })
  );

export const useCreditUpsellGate = (reject: () => void) => {
  const preventUpsell = useSelector(
    (state) => state.page.mobileFlavor === "theseus"
  );

  useEffect(() => {
    if (preventUpsell) reject();
  });

  return { preventRender: preventUpsell };
};

export const usePremiumUpgradeGate = (reject: () => void) => {
  const [preview, loading] = useSelector(selectPremiumUpgradePreview.load());

  useEffect(() => {
    if (preview && !preview.allowUpgrade) reject();
  }, [preview]);

  return { preventRender: !preview?.allowUpgrade, loading, preview };
};
