import { addMonths } from "date-fns";
import { range, sumBy } from "lodash-es";

import { MaybeWeakMap } from "@kikoff/utils/src/object";

import { web } from "../../../protos";
import createTimestamp from "../google/Timestamp";

import createSettlementPayment from "./SettlementPayment";

export default function createSettlementOffer(
  token: string,
  status: keyof typeof web.public_.SettlementOffer.SettlementOfferStatus,
  {
    acceptedAt = settlementProgression[
      debtAccountStatusByOfferStatus[status]
    ] >= settlementProgression.OFFER_ACCEPTED
      ? createTimestamp(new Date())
      : null,
    debtSettlementAccountToken = "",
    expiresAt = settlementProgression[debtAccountStatusByOfferStatus[status]] >=
    settlementProgression.OFFER_AVAILABLE
      ? createTimestamp(addMonths(new Date(), 1))
      : null,
    originalBalanceCents = 500,
    totalCostCents = 250_00,
    numberOfPayments = 16,
    paidCount = 0,
    paymentAmountCents = 16_00,
    settlementSchedule = range(0, numberOfPayments).map((n) =>
      createSettlementPayment(n < paidCount ? "PAID" : "UNPAID", {
        paymentCents: paymentAmountCents,
        statementEndAt: createTimestamp(
          addMonths(new Date(), n - paidCount + 1)
        ),
      })
    ),
  }: Omit<web.public_.ISettlementOffer, "token" | "status"> & {
    paidCount?: number;
  } = {}
) {
  return web.public_.SettlementOffer.create({
    token,
    status: web.public_.SettlementOffer.SettlementOfferStatus[status],
    acceptedAt,
    debtSettlementAccountToken,
    expiresAt,
    numberOfPayments,
    originalBalanceCents,
    paymentAmountCents,
    totalCostCents,
    settlementSchedule,
    highestNumberOfPayments: numberOfPayments,
    // Deprecated
    highestPaymentAmountCents: 0,
    lowestNumberOfPayments: 0,
    lowestPaymentAmountCents: 0,
  });
}

export const settlementProgression = (() => {
  const map: Record<
    keyof typeof web.public_.DebtSettlementAccount.SettlementStatus,
    number
  > = {
    NEEDS_REVIEW: 0,
    UNKNOWN: 0,
    UNPAID: 0,
    BLOCKED: 0,
    ESTIMATE: 1,
    AWAITING_REQUEST: 2,
    REQUESTED: 3,
    OFFER_NOT_AVAILABLE: 3,
    OFFER_AVAILABLE: 4,
    DECLINED: 4,
    OFFER_EXPIRED: 4,
    OFFER_ACCEPTED: 5,
    OFFER_ACCEPTANCE_SUBMITTED: 6,
    OFFER_REJECTED_BY_OWNER: 6,
    OFFER_CONFIRMED: 7,
    PAYABLE: 8,
    CANCELLED_BY_USER: 8,
    CANCELLED_BY_OWNER: 8,
    PAID: 9,
  };

  return Object.assign(
    map,
    Object.fromEntries(
      Object.entries(map).map(([key, value]) => [
        web.public_.DebtSettlementAccount.SettlementStatus[key],
        value,
      ])
    )
  ) as typeof map &
    Record<web.public_.DebtSettlementAccount.SettlementStatus, number>;
})();

export const SettlementSchedule = (() => {
  const cache = {
    paidAmount: new MaybeWeakMap<any, number>(),
  };

  return {
    nextPayment: (schedule: web.public_.ISettlementPayment[]) =>
      schedule?.find(({ debitedAt }) => !debitedAt),
    prevPayment: (schedule: web.public_.ISettlementPayment[]) =>
      schedule?.findLast(({ debitedAt }) => debitedAt),
    paidAmount(schedule: web.public_.ISettlementPayment[]) {
      if (!schedule) return 0;
      if (!cache.paidAmount.has(schedule))
        cache.paidAmount.set(
          schedule,
          sumBy(
            schedule.filter(({ debitedAt }) => debitedAt),
            "paymentCents"
          )
        );

      return cache.paidAmount.get(schedule);
    },
  };
})();

export const offerStatusByDebtAccountStatus: Record<
  keyof typeof web.public_.DebtSettlementAccount.SettlementStatus,
  keyof typeof web.public_.SettlementOffer.SettlementOfferStatus
> = {
  UNKNOWN: "UNKNOWN",
  ESTIMATE: "ESTIMATE",
  REQUESTED: "REQUESTED",
  NEEDS_REVIEW: "UNKNOWN",
  OFFER_AVAILABLE: "AVAILABLE",
  PAYABLE: "PAYABLE",
  // TODO: Confirm with julian
  PAID: "PAYABLE",
  DECLINED: "DECLINED",
  UNPAID: "UNKNOWN",
  BLOCKED: "UNKNOWN",
  OFFER_ACCEPTED: "ACCEPTED",
  AWAITING_REQUEST: "AWAITING_REQUEST",
  OFFER_NOT_AVAILABLE: "NOT_AVAILABLE",
  OFFER_ACCEPTANCE_SUBMITTED: "ACCEPTANCE_SUBMITTED",
  OFFER_CONFIRMED: "CONFIRMED",
  OFFER_REJECTED_BY_OWNER: "REJECTED_BY_OWNER",
  CANCELLED_BY_OWNER: "CANCELLED_BY_OWNER",
  CANCELLED_BY_USER: "CANCELLED_BY_USER",
  OFFER_EXPIRED: "EXPIRED",
};

const debtAccountStatusByOfferStatus = Object.assign(
  Object.fromEntries(
    Object.entries(offerStatusByDebtAccountStatus).map(([key, value]) => [
      value,
      key,
    ])
  ),
  {
    UNKNOWN: "UNKNOWN",
  }
) as Record<
  keyof typeof web.public_.SettlementOffer.SettlementOfferStatus,
  keyof typeof web.public_.DebtSettlementAccount.SettlementStatus
>;
