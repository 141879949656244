import Cookie from "js-cookie";

// FIXME: duplicated getLocaleCookie below because unavail.
// import { getLocaleCookie } from "@util/l10n";
import { google, web } from "@kikoff/proto/src/protos";

import { msIn } from "../date";
import { serverNow } from "../number";
import { protoTime } from "../proto";

export function getLocaleCookie() {
  return Cookie.get("locale") || "en";
}

export const dateConstants = {
  days: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
};

export const dateConstantsEs = {
  days: [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
  ],
  months: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
};

const ordinality = (n: number) =>
  `${n}${
    (Math.floor(n / 10) !== 1 && ["st", "nd", "rd"][(n % 10) - 1]) || "th"
  }`;

const timeZoneOffsets = {
  UTC: 0,
  PST: -8,
};

type TimeZone = keyof typeof timeZoneOffsets;

const replacers = (() => {
  const locale = getLocaleCookie();

  const makeReplacer = (transform: (input: Date) => any) => (
    replacer: (input: number) => string
  ) => (input: Date & { zone?: TimeZone }) => {
    const offset = (() => {
      // getTimezoneOffset is in minutes
      if (!input.zone) return -input.getTimezoneOffset() * msIn.minute;

      const offsetHours = timeZoneOffsets[input.zone];
      if (offsetHours == null)
        throw new Error(
          `Invalid timezone "${
            input.zone
          }". Supported timzones include ${Object.keys(timeZoneOffsets).join(
            ", "
          )}`
        );
      return offsetHours * msIn.hour;
    })();
    return replacer(transform(new Date(+input + offset)));
  };

  const Z = (input) => {
    if (!input.zone)
      throw new Error(
        `Unable to format time zone for ${input}, must include .zone property on date`
      );

    return input.zone;
  };

  const date = (() => {
    const d = makeReplacer((input) => input.getUTCDate());
    const w = makeReplacer((input) => input.getUTCDay());
    const m = makeReplacer((input) => input.getUTCMonth());
    const y = makeReplacer((input) => input.getUTCFullYear());

    const { days, months } = locale === "en" ? dateConstants : dateConstantsEs;
    return {
      d: d((n) => `${n}`),
      dd: d((n) => `${n}`.padStart(2, "0")),
      ddd: d(ordinality),
      W: w((n) =>
        days[n].slice(0, 1) === "T" ? days[n].slice(0, 2) : days[n].slice(0, 1)
      ),
      Ww: w((n) => days[n].slice(0, 2)),
      Www: w((n) => days[n].slice(0, 3)),
      Wwww: w((n) => days[n]),
      m: m((n) => `${n + 1}`),
      mm: m((n) => `${n + 1}`.padStart(2, "0")),
      Mmm: m((n) => months[n].slice(0, 3)),
      Mmmm: m((n) => months[n]),
      yy: y((n) => `${n}`.slice(-2)),
      yyyy: y((n) => `${n}`),
      Z,
    };
  })();

  const time = (() => {
    const h = makeReplacer((input) => input.getUTCHours());
    const m = makeReplacer((input) => input.getUTCMinutes());
    const s = makeReplacer((input) => input.getUTCSeconds());
    const ms = makeReplacer((input) => input.getUTCMilliseconds());

    return {
      h: h((n) => `${n}`),
      hh: h((n) => `${n}`.padStart(2, "0")),
      "12h": h((n) => `${n % 12 || 12}`),
      "12hh": h((n) => `${n % 12 || 12}`.padStart(2, "0")),
      m: m((n) => `${n}`),
      mm: m((n) => `${n}`.padStart(2, "0")),
      s: s((n) => `${n}`),
      ss: s((n) => `${n}`.padStart(2, "0")),
      ms: ms((n) => `${n}`.slice(0, 1)),
      mss: ms((n) => `${n}`.slice(0, 2).padEnd(2, "0")),
      msss: ms((n) => `${n}`.slice(0, 3).padEnd(3, "0")),
      apm: h((n) => (n < 12 ? "am" : "pm")),
      Z,
    };
  })();

  // Time doesn't have any capitalization
  for (const replacerMap of [date, time]) {
    for (const [str, replacer] of Object.entries(replacerMap)) {
      if (!replacerMap[str.toLowerCase()])
        replacerMap[str.toLowerCase()] = (input) =>
          `${replacer(input)}`.toLowerCase();
      if (!replacerMap[str.toUpperCase()])
        replacerMap[str.toUpperCase()] = (input) =>
          `${replacer(input)}`.toUpperCase();
    }
  }

  return { date, time };
})();

const prepareDate = (
  dateLike: string | number | Date | google.protobuf.ITimestamp
) => {
  if (!dateLike) return;
  if (!(dateLike instanceof Date))
    dateLike = new Date( // eslint-disable-line no-param-reassign
      typeof dateLike === "object" ? protoTime(dateLike) : dateLike
    );
  if (Number.isNaN(dateLike.getTime())) return;
  return dateLike;
};

const money = (() => {
  const modifiers = {
    cents: { includeCents: true },
    noSign: { noSign: true },
    withPlus: { withPlus: true },
  };

  const base = (
    _cents: number | string,
    { includeCents = false, noSign = false, withPlus = false } = {}
  ) => {
    const cents = +_cents;
    if (Number.isNaN(cents)) return "$-";
    const str = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      ...(cents % 100 === 0 &&
        !includeCents && {
          minimumFractionDigits: 0,
        }),
    })
      .format(cents / 100)
      .slice(noSign ? 1 : 0);

    return withPlus && cents > 0 ? `+${str}` : str;
  };

  const withOptions = (options: {
    includeCents?: boolean;
    noSign?: boolean;
    withPlus?: boolean;
  }) =>
    new Proxy(
      (cents, innerOptions) => base(cents, { ...options, ...innerOptions }),
      {
        get(_target, prop) {
          if (!(prop in modifiers))
            throw new Error(
              `Invalid modifier "${String(prop)}" on format.money`
            );

          return withOptions({ ...options, ...modifiers[prop] });
        },
      }
    );

  type Res = typeof base & { [key in keyof typeof modifiers]: Res };

  return withOptions({}) as Res;
})();

const format = {
  ordinality,
  trim: (str: string) => str?.trim() || "",
  /**
   * Function that formats a phone number string: +#* (###) ###-####
   *
   * @method phone
   * @memberof format
   * @param {String} str - Phone number to be formatted
   * @returns {String} Formatted phone number
   */
  phone(str: string, { includeStar = true } = {}) {
    if (!str) return "";

    str = str.replace(includeStar ? /[^\d|*]/g : /\D/g, ""); // eslint-disable-line no-param-reassign
    return (
      (str.length > 10 ? `+${str.slice(0, -10)} ` : "") +
      (str.length > 0
        ? ((s) =>
            `(${s.slice(0, 3)}${
              s.length > 3
                ? `) ${s.slice(3, 6)}${
                    s.length > 6 ? `-${s.slice(6, 10)}` : ""
                  }`
                : ""
            }`)(str.slice(-10))
        : "")
    );
  },
  ssn: (ssn) =>
    ssn ? `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}` : "",
  number: new Intl.NumberFormat().format,
  money,
  zip(str: string) {
    if (!str) return null;
    str = str.replace(/\D/g, ""); // eslint-disable-line no-param-reassign
    return str.length > 5 ? `${str.slice(0, 5)}-${str.slice(5, 9)}` : str;
  },
  state(str: string) {
    return str.slice(0, 2).replace(/\d/g, "").toUpperCase();
  },
  date: (() => {
    const withConfig = ({
      formatStr: _formatStr = "yyyymmdd",
      zone = null as TimeZone,
      defaultStr = "-",
    } = {}) => (
      date: Parameters<typeof prepareDate>[0],
      formatStr = _formatStr
    ) => {
      date = prepareDate(date);
      if (date) (date as any).zone ??= zone;

      if (!date) return defaultStr;
      return formatStr.replace(
        /d+|w+|m+|y+|z/gi,
        (str) => replacers.date[str]?.(date) || str
      );
    };

    return Object.assign(withConfig(), {
      withConfig,
    });
  })(),
  time(date: Parameters<typeof prepareDate>[0], formatStr) {
    date = prepareDate(date);
    if (!date) return "-";
    return formatStr.replace(
      /ms+|(12)?h+|m+|s+|apm|z/gi,
      (str) => replacers.time[str]?.(date) || str
    );
  },
  duration: Object.assign(
    (
      input: { days: number } | { fromNow: number },
      {
        // former default behavior
        round = "up",
      }: { round?: "up" | "down" } = {}
    ) => {
      const days =
        "fromNow" in input
          ? Math[{ up: "ceil", down: "floor" }[round]](
              (input.fromNow - serverNow()) / 1000 / 3600 / 24
            )
          : input.days;

      const months = days < 30 ? 0 : Math.round(days / 30);
      const years = months < 12 ? 0 : Math.round(days / 365);
      return {
        [`${days === 0}`]: "today",
        [`${days === 1}`]: "tomorrow",
        [`${days === -1}`]: "yesterday",
        [`${days > 1}`]: `in ${days} days`,
        [`${days < -1}`]: `${-days} days ago`,
        [`${months === 1}`]: "in a month",
        [`${months === -1}`]: "a month ago",
        [`${months > 1}`]: `in ${months} months`,
        [`${months < -1}`]: `${-months} months ago`,
        [`${years === 1}`]: "in a year",
        [`${years === -1}`]: "a year ago",
        [`${years > 1}`]: `in ${years} years`,
        [`${years < -1}`]: `${-years} years ago`,
      }.true;
    },
    {
      fromNow: (input: number, options) =>
        format.duration({ fromNow: input }, options),
    }
  ),
  v2: {
    duration(
      ms: number,
      formatString: string,
      { pluralize = false }: { pluralize?: boolean } = {}
    ) {
      let res = formatString;

      const tokensOrderedByPrecision = {
        Y: msIn.year,
        M: msIn.month,
        W: msIn.week,
        D: msIn.day,
        h: msIn.hour,
        m: msIn.minute,
        s: msIn.second,
      };

      for (const [token, msInToken] of Object.entries(
        tokensOrderedByPrecision
      )) {
        const i = res.indexOf(`%${token}`);

        if (i === -1) continue;

        const amount = Math.floor(ms / msInToken);
        const nextPrefix = res.slice(i + 1).match(/\?|%/)?.[0];
        if (amount === 0 && nextPrefix === "?") continue;

        ms -= amount * msInToken;
        res = res.replace(`%${token}`, `${amount}`);
        res = `${res.slice(0, i)}${res.slice(i).replace(/\?|%/, (s) => {
          let parsedS = s === "?" ? "" : s;
          if (pluralize && amount > 1) {
            parsedS += "s";
          }
          return parsedS;
        })}`;
      }
      return res
        .replace(/%.*?\?/g, "")
        .replace(/ +/g, " ")
        .trim();
    },
  },
  address: Object.assign(
    (info: web.public_.IPersonalInfo) => {
      return `${info.addressLine_1}${
        info.addressLine_2 ? `, ${info.addressLine_2}` : ""
      }, ${info.city}, ${info.state}, ${info.zip}`;
    },
    {
      twoLine: {
        first: (info: web.public_.IPersonalInfo) =>
          [info.addressLine_1, info.addressLine_2].filter(Boolean).join(", "),
        second: (info: web.public_.IPersonalInfo) =>
          `${info.city}, ${info.state} ${info.zip}`,
      },
    }
  ),
};

export default format;
