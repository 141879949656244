import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import type { AppThunk, RootState } from "@store";

import useErrorConsumer from "@kikoff/hooks/src/useErrorConsumer";

export const thunk = <T>(t: AppThunk<T>) =>
  ((...args) => t(...args)) as AppThunk<T>;

type Selector<Args extends any[] = any[], Res extends any = any> = (
  ...args: Args
) => (state: RootState) => Res;

declare namespace createLoadableSelector {
  type Options<Args extends any[]> = {
    selectLoaded?: Selector<Args, any>;
  } & RequireAtLeastOne<{
    loadAction: (...args: Args) => AppThunk<Promise<any>>;
    dependsOn: readonly (Result | [loadableSelector: Result, ...args: any[]])[];
  }>;
  type Result<Args extends any[] = any[], Res extends any = any> = Selector<
    Args,
    Res
  > & {
    load: Selector<Args, [selected: Res, loading: boolean]> & {
      after: (
        condition: unknown
      ) => Selector<Args, [selected: Res, loading: boolean]>;
    };
    loadAction: ((...args: Args) => AppThunk<Promise<Res>>) & {
      ifMissing: (...args: Args) => AppThunk<Promise<Res>>;
    };
  };
}

const noAfter = Symbol("noAfter");

const createLoadableSelector = <Args extends any[], Res extends any>(
  selector: Selector<Args, Res>,
  { selectLoaded, dependsOn, loadAction }: createLoadableSelector.Options<Args>
): createLoadableSelector.Result<Args, Res> => {
  const withConfig = ({ after = noAfter as unknown }) => (...args: Args) => (
    state
  ) => {
    const data = selector(...args)(state);

    const dispatch = useDispatch();
    const error = useErrorConsumer();

    const getSelect = (selector: createLoadableSelector.Result) =>
      after === noAfter ? selector.load : selector.load.after(after);

    const dependenciesLoading =
      dependsOn &&
      dependsOn
        // Must map then some to ensure all hooks are always called
        .map((dependency) => {
          const [, loading] = (() => {
            if (!Array.isArray(dependency))
              return getSelect(dependency)()(state);
            const [selector, ...args] = dependency;
            return getSelect(selector)(...args)(state);
          })();
          return loading;
        })
        .some(Boolean);

    const [_loading, setLoading] = useState(
      (selectLoaded ? selectLoaded(...args)(state) : data) == null
    );

    const loading = !!(dependenciesLoading || _loading);

    const shouldLoad = loadAction && !!after && _loading;

    useEffect(() => {
      if (shouldLoad) {
        Promise.resolve(dispatch(loadAction(...args)))
          .catch((e) => {
            error.throw(e);
          })
          .finally(() => setLoading(false));
      }
    }, [shouldLoad]);

    // Memoize for useSelector
    return useMemo(() => [data, loading], [data, loading]);
  };

  return Object.assign(selector, {
    load: Object.assign(withConfig({}), {
      after: (condition) => withConfig({ after: condition || false }),
    }),
    // TODO: support dependencies
    loadAction: !loadAction
      ? null
      : Object.assign(
          (...args: any) =>
            thunk((dispatch, getState) =>
              dispatch(loadAction(...args)).then(() =>
                selector(...args)(getState())
              )
            ),
          {
            ifMissing: (...args: any) =>
              thunk((dispatch, getState) =>
                Promise.resolve(
                  !selectLoaded(...args)(getState()) &&
                    dispatch(loadAction(...args))
                ).then(() => selector(...args)(getState()))
              ),
          }
        ),
  }) as any;
};

export { createLoadableSelector };
