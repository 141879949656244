import { RecordTuple } from "record-tuple";

export const invertResult = <T extends (...args: any) => boolean>(f: T) =>
  ((...args) => !f(...args)) as T;

export const memo = (() => {
  type Args = any[];
  type Result = any;

  type Options = Parameters<typeof create>[0];

  const create = ({ deep = false, disabledIf = false }) => {
    const createRef = deep ? RecordTuple.deep : RecordTuple;

    return <Fn extends (...args: any) => any>(
      fn: Fn,
      {
        // `by` cannot be a withOptions option, depends on Fn
        by = (args: Parameters<Fn>) => args as Args,
      } = {}
    ) => {
      const cache = new Map<Args, Result>();

      if (disabledIf) return fn;

      return ((...args) => {
        const ref = createRef(by(args));

        if (!cache.has(ref)) cache.set(ref, fn(...args));

        return cache.get(ref);
      }) as Fn;
    };
  };

  const withOptions = (options: Options = {}) =>
    new Proxy(create(options), {
      get(_, prop) {
        return prop === "deep"
          ? withOptions({ deep: true })
          : (arg) => withOptions({ [prop]: arg });
      },
    });

  type Res = ReturnType<typeof create> & {
    deep: Res;
    disableIf(cond: unknown): Res;
  };

  return withOptions() as Res;
})();

namespace UFunction {
  export type Any = (...args: any) => any;
}
export default UFunction;
