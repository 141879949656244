import { web } from "@kikoff/proto/src/protos";
import { combineClasses, format } from "@kikoff/utils/src/string";

import CreditChange from "./credit_change";

import styles from "./trailing.module.scss";

interface ScoreImpactProps {
  event: web.public_.IScoreEvent;
}

export default function ScoreImpact({
  event: { scoreImpact, date },
}: ScoreImpactProps) {
  return (
    <div className={styles["trailing"]}>
      <div className={styles["inner"]}>
        {scoreImpact > 0 && (
          <div className={styles["title-wrapper"]}>
            <CreditChange diff={scoreImpact} /> pts
          </div>
        )}
        <div
          className={combineClasses(
            styles["subtitle"],
            "text:small",
            "color:moderate"
          )}
        >
          {format.date(date, "Mmm d")}
        </div>
      </div>
    </div>
  );
}

interface ArrowLinkProps {
  direction?: "right" | "left" | "up" | "down";
  children?: React.ReactNode;
}

export function ArrowLink({ direction = "right", children }: ArrowLinkProps) {
  return (
    <div className={`${styles["arrow-link"]} color:strong`}>
      {children}
      <span
        className={`${styles.arrow} color:weak-on-dugout`}
        style={{
          transform: `rotate(${
            90 * ["right", "down", "left", "up"].indexOf(direction)
          }deg)`,
        }}
      >
        
      </span>
    </div>
  );
}
