import React from "react";

import { createPropsProvider } from "@kikoff/client-utils/src/react";
import { cls } from "@kikoff/utils/src/string";

import styles from "./Card.module.scss";

declare namespace Card {
  type Props<
    As extends ElementCreatable = "div"
  > = React.ComponentPropsWithoutRef<As> & {
    raised?: boolean;
    as?: As;
    spacing?: React.CSSProperties["margin"];
    outline?: boolean | React.CSSProperties["color"];
    dugout?: boolean;
    className?: string;
    fallback?: boolean | React.ReactNode;
    ErrorBoundary?: ExtrinsicElement;
    children?: React.ReactNode;
  } & {
    ref?: React.ForwardedRef<
      As extends keyof HTMLElementTagNameMap
        ? HTMLElementTagNameMap[As]
        : HTMLElement
    >;
  };
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Card = Object.assign(
  React.forwardRef(
    <As extends ElementCreatable = "div">(
      _props: Card.Props<As>,
      ref: React.ForwardedRef<
        As extends keyof HTMLElementTagNameMap
          ? HTMLElementTagNameMap[As]
          : HTMLElement
      >
    ) => {
      const {
        raised = false,
        children,
        className,
        spacing,
        ErrorBoundary = () => {
          throw new Error("Card fallback set without ErrorBoundary");
        },
        outline,
        style,
        fallback,
        // LINK: apps/main/src/components/buttons/primary.tsx#element-type
        as: Component = "div" as As,
        dugout,
        ...props
      } = Card.PropsProvider.useMerge(_props);

      return (
        // @ts-expect-error
        <Component
          ref={ref}
          className={cls(
            styles.card,
            dugout && "bg:dugout color-base:dugout",
            className
          )}
          style={{
            margin: spacing,
            boxShadow: [
              !dugout &&
                outline &&
                `0 0 0 1px ${outline === true ? "var(--outline)" : outline}`,
              raised && "0 0 8px 0 #0002",
            ]
              .filter(Boolean)
              .join(", "),
            ...style,
          }}
          {...props}
        >
          {fallback ? (
            <ErrorBoundary
              fallback={
                <span className="color:error">
                  {fallback === true
                    ? "An error has occurred, unable to display content."
                    : fallback}
                </span>
              }
            >
              {children}
            </ErrorBoundary>
          ) : (
            children
          )}
        </Component>
      );
    }
  ),
  {
    PropsProvider: createPropsProvider<Card.Props>("Card"),
    withErrorBoundary,
  }
);

function withErrorBoundary<T extends ExtrinsicElement>(
  Component: T,
  fallback = "An error has occurred, unable to display content."
) {
  return (props: React.ComponentProps<T>) => (
    <withErrorBoundary.Wrapper fallback={fallback}>
      <Component {...props} />
    </withErrorBoundary.Wrapper>
  );
}

declare namespace withErrorBoundary.Wrapper {
  interface Props {
    fallback: React.ReactNode;
    children?: React.ReactNode;
  }
}
withErrorBoundary.Wrapper = ({ fallback, children }) => {
  const { ErrorBoundary } = Card.PropsProvider.useContext();

  return (
    <ErrorBoundary
      fallback={
        <Card>
          <span className="color:error">{fallback}</span>
        </Card>
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export default Card;
