import Router from "next/router";

import { isClient } from "@kikoff/utils/src/general";

import { sputter } from "./analytics";

declare global {
  interface Window {
    NativeDispatch?: {
      postMessage(body: string): void;
    };
    NativePageIsRoot?: boolean;
    NativeAppFlavor?: string;
  }
}

/**
 * Prefer isWebview page state if possible with:
 * const { isWebview } = useSelector((state) => state.page);
 *
 * If isWebview affects the initial render, using this constant will make SSRed
 * html and client hydration html differ
 */
export const isWebview = isClient && !!window.NativeDispatch;
export const isRootWebview =
  // Legacy bug set window.NativePageIsRoot to null if isRoot is true, need to
  // check for null until fix reaches saturation, set to
  // `window.NativePageIsRoot === true` after 10/25/2023
  isClient && [true, null].includes(window.NativePageIsRoot);
export const mobileFlavor =
  isClient && !!window.NativeAppFlavor ? window.NativeAppFlavor : "enterprise";

/**
 * Prefer appName page state if possible with:
 *
 * import { selectAppName } from "@feature/page";
 * ...
 * const appName = useSelector(selectAppName())
 */
export const appFlavorNames = {
  enterprise: "Kikoff",
  yukikaze: "Kikoff",
  theseus: "Oasis",
};
export const appName = appFlavorNames[mobileFlavor] || "Kikoff";

interface NativeEvents {
  close: null;
  openPdf: string;
  requestReview: null;
  trackEvent: {
    name: string;
    properties: Record<string, string>;
    userProperties: Record<string, string>;
  };
  logout: null;
  openWebUrl: {
    url: string;
    appBarTitle?: string;
    appBarHidden?: boolean;
  };
  openExternalUrl: string;
  updateBackgroundColor: string;
  invalidate: null;
  notificationPermission: {
    type: string;
  };
}

let closeCalled = false;

export function nativeDispatch<EventName extends keyof NativeEvents>(
  event: EventName,
  ...[payload]: NativeEvents[EventName] extends null
    ? [undefined?]
    : [payload: NativeEvents[EventName]]
) {
  if (!isWebview) return false;

  if (event === "close") {
    if (isRootWebview) {
      Router.back();
      return;
    }
    if (closeCalled) return;
    closeCalled = true;
  }

  if (event === "openWebUrl") {
    const urlPayload = payload as NativeEvents["openWebUrl"];

    if (urlPayload.url.startsWith("/")) {
      urlPayload.url = `${window.location.origin}${urlPayload.url}`;
    }
  }

  window.NativeDispatch?.postMessage(
    JSON.stringify({ eventName: event, payload })
  );
  if (event !== "trackEvent")
    sputter(`native dispatch: ${event}`, {
      payload: JSON.stringify(payload),
    });

  return true;
}

export const nativeDispatchEvent = <
  EventName extends keyof NativeEvents,
  DOMEvent extends React.SyntheticEvent<any, Event>
>(
  event: EventName,
  {
    payload,
    defaultHandler,
    dispatchHandler,
  }: {
    payload?: NativeEvents[EventName];
    defaultHandler?: React.EventHandler<DOMEvent>;
    dispatchHandler?: React.EventHandler<DOMEvent>;
  } = {}
) => (e: DOMEvent) => {
  if (isWebview) {
    e.preventDefault();

    window.NativeDispatch.postMessage(
      JSON.stringify({ eventName: event, payload })
    );

    dispatchHandler?.(e);
  }
  defaultHandler?.(e);
};

export const KIKOFF_APP_LINKS = {
  ios: "https://apps.apple.com/us/app/kikoff-build-credit-quickly/id1525159784",
  android:
    "https://play.google.com/store/apps/details?id=com.kikoff&hl=en_US&gl=US&pli=1",
};
