import Cookie from "js-cookie";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { captureException } from "@sentry/browser";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { promiseDelay } from "@kikoff/utils/src/general";
import {
  handleFailedStatus,
  handleProtoStatus,
  protoTime,
} from "@kikoff/utils/src/proto";

import { updateUserState } from "@feature/user";
import { getAllVariants, setVariantCookies } from "@src/experiments/context";
import { AppThunk, RootState } from "@store";
import { appFlavorNames, mobileFlavor } from "@util/mobile";

import { initUser } from "./user";

declare global {
  interface Window {
    serverTimeDiff: number;
  }
}

const initialState = {
  featureFlags: {} as Record<string, boolean>,
  dismissed: {} as Record<
    keyof typeof web.public_.Dismissable.Name,
    Record<string, boolean>
  >,
  consumableState: {} as Record<string, any>,
  redirectTarget: null as string,
  impersonating: false,
  isWebview: false,
  isRootWebview: false,
  mobileFlavor,
  emphasizeLending: false,
  experiments: {
    refreshKey: 0,
  },
};

export type PageState = typeof initialState;

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    updatePageState(state, { payload }: PayloadAction<Partial<PageState>>) {
      Object.assign(state, payload);
    },
    dismiss(
      state,
      {
        payload: [name, id],
      }: PayloadAction<
        [name: keyof typeof web.public_.Dismissable.Name, id?: string]
      >
    ) {
      state.dismissed[name] ??= {};
      if (id) state.dismissed[name][id] = true;
    },
    setFeatureFlags(
      state,
      { payload }: PayloadAction<PageState["featureFlags"]>
    ) {
      Object.assign(state.featureFlags, payload);
    },
    setRedirectTarget(
      state,
      { payload }: PayloadAction<PageState["redirectTarget"]>
    ) {
      state.redirectTarget = payload;
    },
    provideConsumable: {
      prepare: (key: string, data?: any) => ({
        payload: data,
        meta: { key },
      }),
      reducer(
        state,
        { payload, meta: { key } }: PayloadAction<any, string, { key: string }>
      ) {
        state.consumableState[key] = payload;
      },
    },
    deleteConsumable(state, { payload }: PayloadAction<string>) {
      delete state.consumableState[payload];
    },
  },
});

const { actions } = pageSlice;
export const {
  updatePageState,
  setFeatureFlags,
  setRedirectTarget,
  provideConsumable,
} = actions;
export default pageSlice.reducer;

export const selectFeatureFlag = (name: string) => (state: RootState) =>
  state.page.featureFlags[name];

export const selectDismissable = (
  name: keyof typeof web.public_.Dismissable.Name,
  id?: string
) => ({ page: { dismissed } }: RootState) =>
  !(id ? dismissed[name]?.[id] : dismissed[name]);

export const selectAppName = () => (state) =>
  appFlavorNames[state.page.mobileFlavor];

export const initPage = (
  state?: Partial<PageState>
): AppThunk<Promise<any>> => (dispatch) => {
  if (state) dispatch(updatePageState(state));

  const start = Date.now();
  return Promise.all([
    webRPC.Account.getInitPage({
      scopes: [web.public_.User.Scope.FIRST_PARTY_INFO],
    }),
    dispatch(fetchSiteVars()),
  ]).then(([{ mfaRequired, impersonating, userFields, user, serverTime }]) => {
    dispatch(updateUserState({ mfaRequired }));

    dispatch(
      updatePageState({
        impersonating,
      })
    );

    dispatch(initUser({ user, fields: userFields }));

    window.serverTimeDiff = protoTime(serverTime) - Date.now() / 2 - start / 2;
  });
};

export const consumeState = (key: string): AppThunk<any> => (
  dispatch,
  getState
) => {
  const state = getState().page;
  if (!(key in state.consumableState)) return null;
  setTimeout(() => {
    dispatch(actions.deleteConsumable(key));
  });
  return state.consumableState[key];
};

const pendingDismissals: Set<Promise<void>> = new Set();

export const dismiss = (
  name: keyof typeof web.public_.Dismissable.Name,
  id?: string
): AppThunk<Promise<void>> => (dispatch, getState) => {
  // if (!selectDismissable(name)(getState())) return Promise.resolve();

  dispatch(actions.dismiss([name, id]));

  const res = webRPC.Account.dismiss({
    dismissable: { name: web.public_.Dismissable.Name[name], id },
  })
    .then(
      handleProtoStatus({
        SUCCESS() {
          if (selectDismissable(name)(getState()))
            dispatch(actions.dismiss([name, id]));
        },
        _DEFAULT: handleFailedStatus(`Failed to dismiss ${name}.`),
      })
    )
    .finally(() => {
      pendingDismissals.delete(res);
    });

  pendingDismissals.add(res);

  return res;
};

export const fetchSiteVars = (): AppThunk => async (dispatch, getState) => {
  // Wait for dismissals to avoid fetching before update
  await Promise.race([
    Promise.allSettled(pendingDismissals),
    promiseDelay(2000),
  ]);

  const experiments = Object.entries(getAllVariants()).map(
    ([name, variant]) => ({
      name,
      variant,
    })
  );

  if (Cookie.get("kikoff-viewed-disputes-landing-page") === "true") {
    experiments.push({ name: "disputesLandingPage", variant: "show" });
  }

  return webRPC.Account.getSiteVars({
    experiments,
  })
    .then((data) => {
      const queryParams = new URLSearchParams(window.location.search);
      const prefix = "experiment-";
      const experimentParams = [...queryParams.entries()].flatMap(
        ([key, value]) => {
          if (!key.startsWith(prefix)) return [];
          return [[key.slice(prefix.length), value]];
        }
      );
      setVariantCookies([
        ...(data.experiments.map(({ name, variant }) => [
          name,
          variant,
        ]) as any),
        ...experimentParams,
      ]);

      dispatch(
        updatePageState({
          dismissed: (() => {
            const res = {};
            for (const { name, id } of data.dismissed) {
              const key = web.public_.Dismissable.Name[name];
              res[key] ??= {};
              if (id) res[key][id] = true;
            }
            return res;
          })(),
          featureFlags: Object.fromEntries(
            data.featureFlags.map((item) => [item.name, item.pass])
          ),
          experiments: {
            refreshKey: getState().page.experiments.refreshKey + 1,
          },
        })
      );
    })
    .catch((e) => {
      captureException(e);
    });
};

export const triggerBrazeEvent = (
  name: keyof typeof web.public_.BrazeEvent.Name
) =>
  webRPC.Account.triggerBrazeEvent({
    brazeEvent: { name: web.public_.BrazeEvent.Name[name] },
  }).then(
    handleProtoStatus({
      SUCCESS() {},
      _DEFAULT: handleFailedStatus(`Failed to trigger braze event ${name}.`),
    })
  );

export const resetState = () => ({ type: "RESET" });
