import React, {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ErrorBoundary } from "react-error-boundary";

import useArray from "@kikoff/hooks/src/useArray";
import useUpdate from "@kikoff/hooks/src/useUpdate";

import {
  LayoutComponent,
  OverlayComponent,
  OverlayConfig,
  OverlayEntry,
  OverlaysSchema,
  PropsFromLazy,
} from "../types";

import createOverlayComponentContext from "./createOverlayComponentContext";
import createOverlaysContext from "./createOverlaysContext";

declare namespace overlayApi {
  export interface Options<DefaultLayoutType extends LayoutComponent> {
    DefaultLayout?: DefaultLayoutType;
  }
  export namespace create {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    export interface Options<
      Overlays extends OverlaysSchema,
      Context extends { push?: any; render?: any }
    > {
      hooks?: {
        push?<Key extends keyof Overlays>(
          key: Key,
          props?: PropsFromLazy<Overlays[Key]>,
          context?: Context["push"]
        ): void;
      };
      defaultConfig?: OverlayConfig<Context["render"]>;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const overlayApi = <
  Overlays extends OverlaysSchema,
  DefaultLayoutType extends LayoutComponent = LayoutComponent
>(
  overlays: Overlays,
  { DefaultLayout }: overlayApi.Options<DefaultLayoutType> = {}
) => ({
  create<Contexts extends { push?: any; render?: any } = unknown>({
    hooks = {},
    defaultConfig = {},
  }: overlayApi.create.Options<Overlays, Contexts>) {
    const OverlaysContext = createOverlaysContext<Overlays, Contexts>();
    const OverlayComponentContext = createOverlayComponentContext<
      Overlays,
      DefaultLayoutType,
      Contexts["push"]
    >();
    const OverlayBackdropContext = React.createContext({
      show: false,
      removeCurrent() {},
    });

    const useOverlaysController = () => {
      const { push, removeAll } = useContext(OverlaysContext);
      return { push, removeAll };
    };

    const useOverlaysBase = () => {
      const { render, stack } = useContext(OverlaysContext);
      return { render, stack };
    };

    const useOverlayBackdrop = () => useContext(OverlayBackdropContext);

    const useOverlay = <ComponentType extends OverlayComponent>(
      Component: ComponentType
    ) => {
      const {
        removeSelf,
        updateOwnProps,
        pushSelf,
        withLayoutProps,
        withBackdropProps,
      } = useContext(OverlayComponentContext)<ComponentType>(Component);
      return {
        removeSelf,
        updateOwnProps,
        pushSelf,
        withLayoutProps,
        withBackdropProps,
      };
    };

    const useOverlayExternal = () => {
      const { removeSelf, index } = useContext(OverlayComponentContext)<
        ExtrinsicElement & {
          returnValueSchema: null;
        }
      >(null);
      return { removeSelf, index };
    };

    const useOverlayLayout = () => {
      const { current } = useContext(OverlaysContext);
      const {
        getRelative,
        id,
        index,
        isCurrent,
        isPresent,
        safeToRemove,
        removeSelf,
        withBackdropProps,
      } = useContext(OverlayComponentContext)(null as never);
      return {
        current,
        getRelative,
        id,
        index,
        isCurrent,
        isPresent,
        safeToRemove,
        removeSelf,
        withBackdropProps,
      };
    };

    let idCounter = 0;

    type Entry = OverlayEntry<Overlays>;

    interface OverlayProviderProps {
      getComponent?(
        key: keyof Overlays,
        props: PropsFromLazy<Overlays[keyof Overlays]>
      ): OverlayComponent;
      entries?: Entry[];
      onChange?(
        entries?: OverlayProviderProps["entries"],
        pushContext?: Contexts["push"]
      ): void;
      children:
        | React.ReactNode
        | ((context: { renderOverlays(): React.ReactNode }) => React.ReactNode);
    }

    const getComponentConfig = (
      Component: OverlayComponent
    ): OverlayConfig => ({
      duplicateBehavior: "allow",
      validateRenderContext: () => true,
      ...defaultConfig,
      ...Component.config,
    });

    function OverlayProvider({
      getComponent,
      entries,
      onChange,
      children,
    }: OverlayProviderProps) {
      const [_stack, _setStack] = useState<Entry[]>([]);
      const [stack, setStack] = useArray.wrap([
        _stack,
        (action: SetStateAction<typeof _stack>) => {
          _setStack((prev) => {
            const value = typeof action === "function" ? action(prev) : action;

            onChange?.(value);

            return value;
          });
        },
      ]);

      useEffect(() => {
        _setStack(entries);
      }, [entries?.length > 0]);

      // See where this is referenced to know what it's for
      const stackRef = useRef(stack);
      stackRef.current = stack;

      const limitCache = useRef(new Set()).current;

      interface InternalOverlayState {
        Component: OverlayComponent;
        isPresent: boolean;
        onRemove(returnValue: any): void;
        layout: {
          props: React.ComponentProps<OverlayComponent["Layout"]>;
          dependencies: any[];
        };
        backdrop: {
          props: React.ComponentProps<OverlayComponent["Layout"]["Backdrop"]>;
          dependencies: any[];
        };
      }
      const stateById = useRef<Record<number, InternalOverlayState>>({})
        .current;
      const update = useUpdate();

      const current = (() => {
        let res = { id: -1, index: -1 };

        stack.forEach(([, id], i) => {
          if (stateById[id]?.isPresent) res = { id, index: i };
        });

        return res;
      })();

      useEffect(() => {
        const idSet = new Set();
        const maxId = stack.reduce((acc, [, id]) => {
          idSet.add(id);
          return Math.max(acc, id);
        }, -1);
        idCounter = maxId + 1;

        for (const id in stateById) {
          if (!idSet.has(+id)) delete stateById[id];
        }
      }, [stack]);

      async function initStateFromEntry(
        [key, id, props]: Entry,
        {
          onComponentLoaded = () => {},
          onRemove = (returnValue: any) => {},
        } = {}
      ) {
        const Component =
          (await getComponent?.(key, props)) ||
          (await overlays[key]?.())?.default;

        if (!Component) {
          setStack([]);
          if (process.env.NODE_ENV === "development")
            throw new Error(
              `Component for overlay key "${
                key as string
              }" not found, all overlays will be removed`
            );
        }

        const { duplicateBehavior, limit } = getComponentConfig(Component);

        if (limit === "once-per-session" && limitCache.has(key)) {
          remove(id, null);
          return;
        }

        limitCache.add(key);

        stateById[id] = {
          isPresent: true,
          Component,
          onRemove,
          layout: {
            props: {},
            dependencies: null,
          },
          backdrop: {
            props: {},
            dependencies: null,
          },
        };

        if (duplicateBehavior !== "allow") {
          for (const [_key, _id] of stack) {
            // Only handle previous overlays
            if (_id === id) break;
            if (_key !== key) continue;
            if (duplicateBehavior === "remove") return remove(id, null);
            if (duplicateBehavior === "replace") {
              remove(_id, null);
              break;
            }
          }
        }

        onComponentLoaded();
        update();
      }

      const findById = (id: number) => ([, _id]: Entry) => _id === id;

      const remove = (id, returnValue: any) => {
        const state = stateById[id];
        if (!state?.isPresent) return;

        state.isPresent = false;
        state.onRemove(returnValue);
      };

      function getLayout(Component: OverlayComponent): LayoutComponent {
        const { Layout = DefaultLayout } = Component || {};
        return Layout || React.Fragment;
      }

      const renderOverlays = useCallback(
        (renderContext?: Contexts["render"]) => {
          // Find and dedupe backdrops, only one instance of each needed
          const backdrops = (() => {
            const map = new Map<ExtrinsicElement, Record<string, any>>();

            stack.forEach(([, id], i) => {
              if (!stateById[id]) return;

              const { Component, backdrop } = stateById[id];
              const { validateRenderContext } = getComponentConfig(Component);
              if (!validateRenderContext(renderContext)) return;

              const { Backdrop } = getLayout(Component);
              if (!Backdrop) return;

              if (!map.has(Backdrop) || i <= current.index) {
                map.set(Backdrop, backdrop.props);
              }
            });

            return [...map];
          })();

          return (
            <ErrorBoundary
              fallback={<></>}
              resetKeys={[stack.length === 0]}
              onError={(e) => {
                console.error(e);
                setStack([]);
              }}
            >
              {[
                ...backdrops.map(([Backdrop, props], i) => (
                  <OverlayBackdropContext.Provider
                    key={`backdrop-${i}`}
                    value={{
                      show:
                        current.id !== -1 &&
                        getLayout(stateById[current.id]?.Component).Backdrop ===
                          Backdrop,
                      removeCurrent() {
                        const [, overlayId] =
                          stack.findLast(([, id]) => stateById[id].isPresent) ||
                          [];
                        remove(overlayId, null);
                        update();
                      },
                    }}
                  >
                    <Backdrop {...props} />
                  </OverlayBackdropContext.Provider>
                )),
                ...stack.map(([key, id, props], index) => {
                  if (!stateById[id]) {
                    initStateFromEntry([key, id, props]);
                    return null;
                  }

                  const state = stateById[id];
                  const { Component, isPresent } = state;
                  const { validateRenderContext } = getComponentConfig(
                    Component
                  );
                  if (!validateRenderContext(renderContext)) return null;

                  const Layout = getLayout(Component);

                  const handleLayer = (layer: "layout" | "backdrop") => (
                    layerProps,
                    dependencies = []
                  ) => {
                    function makeUpdate() {
                      state[layer] = { props: layerProps, dependencies };
                      requestAnimationFrame(update);
                    }
                    if (
                      dependencies.length !== state[layer].dependencies?.length
                    )
                      makeUpdate();

                    for (let i = 0; i < dependencies.length; i++) {
                      if (dependencies[i] !== state[layer].dependencies[i])
                        return makeUpdate();
                    }
                  };

                  return (
                    <OverlayComponentContext.Provider
                      key={`overlay-${id}`}
                      value={() => ({
                        removeSelf(returnValue = null) {
                          remove(id, returnValue);
                          update();
                        },
                        updateOwnProps(newProps) {
                          setStack.find(findById(id), [
                            key,
                            id,
                            { ...props, ...newProps },
                          ]);
                        },
                        pushSelf(_props, context) {
                          return overlaysController.push(key, _props, context);
                        },
                        withLayoutProps: handleLayer("layout"),
                        withBackdropProps: handleLayer("backdrop"),
                        isPresent,
                        safeToRemove() {
                          if (isPresent)
                            throw new Error(
                              '"safeToRemove" called while component is still present'
                            );

                          setStack((currentStack) =>
                            currentStack.filter(([, _id]) => _id !== id)
                          );
                          delete stateById[id];
                        },
                        getRelative(delta) {
                          const i = stack.findIndex(findById(id)) + delta;
                          const entry = stack[i];

                          if (!entry) return null;

                          return {
                            key: entry[0],
                            id: entry[1],
                            props: entry[2],
                            ...stateById[id],
                          };
                        },
                        id,
                        index,
                        isCurrent: current.id === id,
                      })}
                    >
                      <Layout {...state.layout.props}>
                        <Component {...props} key={id} />
                      </Layout>
                    </OverlayComponentContext.Provider>
                  );
                }),
              ]}
            </ErrorBoundary>
          );
        },
        [stack, update]
      );

      const overlaysController = useMemo<
        React.ContextType<typeof OverlaysContext>
      >(
        () => ({
          async push(key, props, context) {
            hooks.push?.(key, props, context);

            const entry = [key, idCounter++, props] as const;

            return new Promise((resolve) => {
              initStateFromEntry(entry, {
                onComponentLoaded() {
                  // `stack` is stale here, must use ref for up to date state instead
                  if (onChange) {
                    const nextStack = [...stackRef.current, entry];
                    onChange(nextStack, context);
                    _setStack(nextStack);
                  } else setStack.push(entry);
                },
                onRemove: resolve,
              });
            });
          },
          removeAll() {
            for (const id of Object.keys(stateById)) {
              remove(id, null);
            }
            update();
          },
          current,
          render: renderOverlays,
          stack,
        }),
        [stack, update]
      );

      return (
        <OverlaysContext.Provider value={overlaysController}>
          {typeof children === "function"
            ? children({
                renderOverlays,
              })
            : children}
        </OverlaysContext.Provider>
      );
    }

    return {
      OverlayProvider,
      OverlaysContext,
      useOverlaysController,
      useOverlaysBase,
      useOverlay,
      useOverlayLayout,
      useOverlayBackdrop,
      useOverlayExternal,
    };
  },
});

export default overlayApi;
